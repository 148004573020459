import "./index.css";
import * as Actions from "./action";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, COLORS } from "@turtlemint/mint-ui";
import { Card, Descriptions, Divider, message, Popconfirm, Row, Table } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { isMobileView } from "../../../common/commonService";
import BankDetails from "../bank-details";
import { propsToJS } from "__utils/immutable-to-js";
import partnerDetailsSelector from "./partner-details.selector";
import TransactionList from "../transactions/transaction-list";
import { useHistory } from "react-router";
import { getDateTime } from "__utils/date-format";
const isMobile = isMobileView();

const PartnerDetails: React.FC = (props: any) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [showTransaction, setshowTransaction] = useState<boolean>(false);
	const [showBankDetails, setShowBankDetails] = useState<boolean>(false);
	const { allTransactionsState, initiateQSBPartnerSuccess, initiateQSBPartnerRepeat, initiateQSBPartnerUnauthorised, initiateQSBPartnerError } = propsToJS(
		useSelector(partnerDetailsSelector)
	);
	const { bankDetails } = propsToJS(useSelector(partnerDetailsSelector));
	const [hasQSBInitiated, setHasQSBInitiated] = useState<boolean>(false);

	useEffect(() => {
		if(hasQSBInitiated) {
			if(initiateQSBPartnerSuccess) {
				message.success("Refund process initiated for this partner successfully");
			} else if (initiateQSBPartnerUnauthorised) {
				message.error("Unauthorised to initiate Refund Process");
			} else if (initiateQSBPartnerRepeat) {
				message.error("Refund Process is already initiated for this partner");
			} else {
				message.error("Failed to initiate Refund");
			}
			setHasQSBInitiated(false);
		}
	},[initiateQSBPartnerSuccess, initiateQSBPartnerUnauthorised, initiateQSBPartnerRepeat, initiateQSBPartnerError]);

	useEffect(() => {
		console.log(props.location.state?.partnerDetail?.externalId);
		dispatch({
			type: Actions.GET_PARTNER_TRANSACTIONS,
			payload: {
				data: {
					limit: 10,
					offset: 0
				},
				externalId: props.location.state?.partnerDetail.externalId
				// externalId :'6228387bfc640149cf74452c'
			}
		});
	}, []);

	const Details = styled.div<{ isMobile?: boolean }>`
		background-color: white;
		height: calc(${document.body.clientHeight + "px"} - 0px);
		${({ isMobile }) =>
			isMobile
				? css`
						margin-top: 60px;
				  `
				: css`
						margin-top: 30px;
						padding: 24px;
						padding-bottom: 0px;
						padding-top: 24px;
						margin-right: 10rem;
						margin-left: 10rem;
				  `};
	`;
	const Header = styled.div<{ isMobile?: boolean }>`
		margin: 16px;
		${({ isMobile }) =>
			isMobile &&
			css`
				position: relative;
				height: 56px;
				top: 0px;
				width: 100%;
				padding: 16px;
				background: #ffffff;
				box-shadow: 0px 1px 4px rgb(0 0 0 / 24%);
				margin: 0px;
			`};
	`;

	const handleRefund = () => {
		setHasQSBInitiated(true);
		dispatch({
			type: Actions.POST_QSB_INITIATE_PARTNER,
			payload: {
				partnerId: props.location.state?.partnerDetail.id
			}
		});
	}
	
	const showBankDetailsHandle = () => {
		if(!showBankDetails){
			dispatch({
				type: Actions.GET_BANK_ACCOUNT_DETAILS,
				payload: {
					externalID :props.location.state?.partnerDetail?.externalId
				}
			});
		}
		setShowBankDetails(!showBankDetails);
	}

	return (
		<Details>
			<Header isMobile={isMobile}>
				<div className="transaction-desk-header-wrap">
					<div onClick={() => history.push("/admin/partners")}>
						<ArrowLeftOutlined
							style={{
								fontSize: "20px",
								color: `${COLORS.BLACK}`,
								cursor: "pointer"
							}}
						></ArrowLeftOutlined>
						<span className="header-lable"> Show All DP's </span>
					</div>
				</div>
			</Header>
			<Divider
				style={{
					marginBottom: "0.1rem ",
					marginTop: "2rem",
					border: "1px solid #D2D5D6"
				}}
			/>
			<Row
				type="flex"
				justify="space-between"
				align="middle"
				style={{ margin: "10px 0" }}
				className="header-background"
			>
				<div>DP Id : {props.location.state?.partnerDetail?.id}</div>
				<div>Name : {props.location.state?.partnerDetail?.name}</div>
				<div>Outstanding Amount : {props?.location.state?.walletBalance}</div>

				<Popconfirm
					placement="bottomLeft"
					title="Do you want to initiate refund for this Partner ?"
					onConfirm={() => handleRefund()}
					okText="Yes"
					cancelText="No"
				>
					<Button
						btnStyle="primary"
						title="Initiate Refund"
						btnType="solid"
						size="small"
						disabled={initiateQSBPartnerSuccess}
					/>
				</Popconfirm>
			</Row>
			<Card title={`DP Wallet Details For DP : ${props.location.state?.partnerDetail?.id}`} className="home">
				<Descriptions bordered={true} column={1}>
					<Descriptions.Item label="Wallet Activation">{getDateTime(props.location.state?.activatedAt)}</Descriptions.Item>
					<Descriptions.Item label="Registered Mobile">{props.location.state?.partnerDetail?.mobileNo}</Descriptions.Item>
					<Descriptions.Item label="Registered Email">{props.location.state?.partnerDetail?.emailId}</Descriptions.Item>
				</Descriptions>
			</Card>
			<Row
				type="flex"
				justify="space-between"
				style={{ margin: "10px 0" }}
			>
				<Button
					btnStyle="primary"
					title={
						showBankDetails
							? "Hide Bank Details"
							: "Show Bank Details"
					}
					btnType="solid"
					size="small"
					onClick={showBankDetailsHandle}
				/>
				<Button
					btnStyle="primary"
					title={
						showTransaction
							? "Hide Last 10 Transactions"
							: "Show Last 10 Transactions"
					}
					btnType="solid"
					size="small"
					onClick={() => setshowTransaction(!showTransaction)}
				/>
			</Row>
			<Divider
				style={{
					marginBottom: "0.1rem ",
					marginTop: "2rem",
					border: "1px solid #D2D5D6"
				}}
			/>
			{showBankDetails && <BankDetails bankDetails={bankDetails}/>}
			{showTransaction && (
				<div className="transaction-view">
					{
						<TransactionList
							showPagination={true}
							data={allTransactionsState}
							style={{ width: "100%" }}
						></TransactionList>
					}
				</div>
			)}
		</Details>
	);
};

export default PartnerDetails;
