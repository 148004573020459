/* eslint-disable @typescript-eslint/no-explicit-any */
import { ajax } from "rxjs/ajax";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { getUserInformation } from "components/admin-login-info";

const userInfo = getUserInformation();
console.log("new req");

const defaultHeaders: any = {
	"Content-Type": "application/json"
};

const getToken = () => {
	const userInfo = getUserInformation();
	return userInfo?.token
}

export const authHeader = () => {
	return {
		"Authorization": getToken()
	}
}

interface IData {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: string | number | boolean | any;
}

/* This can be externalized in environment file. */
export const baseUrl: string =
	process.env.NODE_ENV === "development"
		? `${process.env.REACT_APP_API_URL}` + "/api"
		: window.location.origin + "/api";
// const baseUrl: string = window.location.origin + "/api";
//const baseUrl: string = `${process.env.REACT_APP_API_URL}`+'/api';
export const get = (
	url: string,
	headers?: any,
	option?: any,
	queryParams?: IData
) => {
	if (queryParams) {
		let queryParamString = "";

		for (const property in queryParams) {
			queryParamString += `&${property}=${queryParams[property]}`;
		}

		url += "?" + queryParamString;
	}

	return getAjaxCall(url, headers, option);
};

export const post = (url: string, body: any, headers?: any, option?: any) =>
	postAjaxCall(url, body, headers, option);

export const put = (url: string, body: any, headers?: any, option?: any) =>
	putAjaxCall(url, body, headers, option);

export const patch = (url: string, body: any, headers?: any, option?: any) =>
	patchAjaxCall(url, body, headers, option);

// export const deleteRequest = (url: string, headers?: any, option?: any) =>
// getAjaxCall("delete", url, headers, option);

// Common method for ajax call to handle errors & loaders
const getAjaxCall = (url: string, headers: any = {}, option?: any) => {
	if(url.includes("/admin/") && !url.includes("/login")) {
		const userInfo = getUserInformation();
		console.log("user", userInfo);
		headers = Object.assign({}, authHeader(), headers);
		console.log("headers1", headers);
	}
	const loader = document.getElementById("appLoader");
	loader && option && option.showLoader && (loader.style.display = "block");
	return ajax
		.get((!url.includes("http") ? baseUrl : "") + url, {
			...defaultHeaders,
			...headers
		})
		.pipe(
			map((response: any) => {
				loader && (loader.style.display = "none");
				return response;
			}),
			catchError((error: any) => {
				loader && (loader.style.display = "none");
				console.log("error: ", error);
				// TODO :: redirect to 404 page if set in option.redirection404
				return throwError(error);
			})
		);
};

const postAjaxCall = (
	url: string,
	body: any,
	headers: any = {},
	option?: any
) => {
	const loader = document.getElementById("appLoader");
	console.log("url", url);
	loader && option && option.showLoader && (loader.style.display = "block");
	if(url.includes("/admin/") && !url.includes("/login") && !url.includes("/logout")) {
		console.log("headers11", headers);
		headers = Object.assign({}, authHeader(), headers);
	}
	console.log("headers12", headers);

	return ajax({
		url: baseUrl + url,
		method: "POST",
		body,
		headers: {
			...defaultHeaders,
			...headers
		},
		responseType: "json"
	}).pipe(
		map((response: any) => {
			loader && (loader.style.display = "none");
			return response;
		}),
		catchError((error: any) => {
			loader && (loader.style.display = "none");
			console.log("error: ", error);
			// TODO :: redirect to 404 page if set in option.redirection404
			return throwError(error);
		})
	);
};

const putAjaxCall = (url: string, body: any, headers?: any, option?: any) => {
	const loader = document.getElementById("appLoader");
	loader && option && option.showLoader && (loader.style.display = "block");
	if(url.includes("/admin/") && !url.includes("/login")) {
		headers = Object.assign({}, authHeader(), headers);
	}
	console.log("headers", headers);
	return ajax
		.put(baseUrl + url, body, { ...defaultHeaders, ...headers })
		.pipe(
			map((response: any) => {
				loader && (loader.style.display = "none");
				return response;
			}),
			catchError((error: any) => {
				loader && (loader.style.display = "none");
				console.log("error: ", error);
				// TODO :: redirect to 404 page if set in option.redirection404
				return throwError(error);
			})
		);
};

const patchAjaxCall = (url: string, body: any, headers?: any, option?: any) => {
	const loader = document.getElementById("appLoader");
	console.log("process", process.env.REACT_APP_API_URL);
	loader && option && option.showLoader && (loader.style.display = "block");
	if(url.includes("/admin/") && !url.includes("/login")) {
		headers = Object.assign({}, authHeader(), headers);
	}
	console.log("headers", headers);
	return ajax
		.patch(baseUrl + url, body, { ...defaultHeaders, ...headers })
		.pipe(
			map((response: any) => {
				loader && (loader.style.display = "none");
				return response;
			}),
			catchError((error: any) => {
				loader && (loader.style.display = "none");
				console.log("error: ", error);
				return throwError(error);
			})
		);
};
