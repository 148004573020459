import _ from "lodash";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const _isObject = (value: any) => {
	return (
		typeof value === "object" &&
		value instanceof Object &&
		!(value instanceof Array)
	);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const _isArray = (value: any) => {
	return (
		typeof value === "object" &&
		value instanceof Object &&
		value instanceof Array
	);
};

export const isNotANum = (value: string | number | null | undefined) => {
	if (value === undefined || value === null || value === "") return true;

	return isNaN(value as number);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cloneArrWithoutRef = (arr: any[]): any[] => {
	return _.cloneDeep(arr);
};

export const displayHumanNumber = (
	number: number,
	decimalPlaces: number = 2
): string | undefined | number => {
	if (isNaN(number)) {
		return;
	}
	if (number < 1000) {
		return number;
	} else if (number < 100000) {
		return (
			(+number / 1000).toFixed(decimalPlaces).replace(/\.0+$/, "") + " k"
		);

	} else if (number < 10000000) {
		return (
			(+number / 100000).toFixed(decimalPlaces).replace(/\.0+$/, "") +
			" Lakhs"
		);

	} else if (number >= 10000000) {
		return (
			(+number / 10000000).toFixed(decimalPlaces).replace(/\.0+$/, "") +
			" Crs"
		);

	}

	return number;
};

// Transition UTIL's
export const jumpTo = (url: string) => {
	window.location.href = url;
};

export const jumpBack = () => {
	window.location.href = document.referrer;
};

export const openWindow = (
	url?: string | undefined,
	target?: string | undefined,
	features?: string | undefined,
	replace?: boolean | undefined
) => {
	window.open(url, target, features, replace);
};
