import { goToPartnerPage } from "__utils/dealer";

// set value in cookie
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setCookie = (key: string, value: any, domain?: string) => {
	deleteCookie(key);
	const expires = new Date();
	expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000);
	document.cookie =
		key +
		"=" +
		(typeof value !== "string" ? JSON.stringify(value) : value) +
		";expires=" +
		expires.toUTCString() +
		"; path=/" +
		(domain ? "Domain=" + domain : "");
};

// get value from cookie by name
export const getCookie = (name: string) => {
	const cookieArr = document.cookie.split(";");

	// Loop through the array elements
	for (let i = 0; i < cookieArr.length; i++) {
		const cookiePair = cookieArr[i].split("=");

		/* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
		if (name === cookiePair[0].trim()) {
			// Decode the cookie value and return
			return decodeURIComponent(cookiePair[1]);
		}
	}
	// Return null if not found
	return null;
};

// delete value from cookie by name
export const deleteCookie = (name: string) => {
	document.cookie = name + `=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const isMobileView = () => {
	return window.outerWidth < 767;
};

export const isAdminView = function() {
	return !!(
		document.referrer.includes("admin") || getCookie("view") == "admin"
	);
};

const hasProInUrl = () => window.location.host.split(".")[0] === "pro";

const isPartner = () => getCookie("category") === "partner";

export const isDealerFlow = () => {
	return isPartner() && hasProInUrl() && !isAdminView();
};

export const fromMintPro = () => !!document.referrer.includes("mintpro.");
export const fromLifeResults = () =>
	!!document.referrer.includes("life-insurance/results");

// If mintpro referrer url does not exist then set else don't touch
export const setReferrerInCookie = () => {
	setCookie("life-profile-mintpro-refurl", document.referrer);
};

export const handleProfileBack = (page: string) => {
	// const profileBackJumpTo = getCookie("life-profile-mintpro-refurl");

	// if (profileBackJumpTo) {
	// 	jumpTo(profileBackJumpTo);
	// 	return;
	// }

	// // Is referrer URL does not exist then jump to Default-URL
	// jumpTo("/sell");
	let mintproPage = page || "/sell"
	goToPartnerPage(mintproPage);
};

export const filterINR = (item: string) => {
	let x = String(item);

	let afterPoint = "";
	if (x.indexOf(".") > 0) afterPoint = x.substring(x.indexOf("."), x.length);
	x = Math.floor(parseInt(x)).toString();
	const y = Math.floor(parseInt(x));
	if (isNaN(y)) {
		return "";
	}
	x = String(x);
	let lastThree = x.substring(x.length - 3);
	const otherNumbers = x.substring(0, x.length - 3);
	if (otherNumbers !== "" && otherNumbers !== "-")
		lastThree = "," + lastThree;
	const res =
		otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
		lastThree +
		afterPoint;
	return res;
};

export const decryptCustomerID = (encryptedId : string) => {
    try {
      return atob(encryptedId);
    } catch (e) {
      return null;
    }
};

const parseUtmParamsForLead = (leadUtm : any) => {
	if (leadUtm != null && typeof leadUtm != 'undefined') {
		var gclid = leadUtm.gclid;
		var fbclid = leadUtm.fbclid;
		if (typeof leadUtm.utmSource != 'undefined' && leadUtm.utmSource.length > 0) {
		  //do nothing, send the params as it is
		} else if (typeof gclid != 'undefined' && gclid.length > 0) {
		  leadUtm.utmSource = 'google';
		  leadUtm.utmMedium = 'cpc';
		} else if (typeof fbclid != 'undefined' && fbclid.length > 0) {
		  leadUtm.utmSource = 'facebook';
		  leadUtm.utmMedium = 'cpc';
		} else if (typeof leadUtm.referer != 'undefined' && leadUtm.referer.length > 0) {
		  leadUtm.gclid = undefined;
		  if (leadUtm.referer.indexOf('google') != -1 || leadUtm.referer.indexOf('yahoo') != -1 || leadUtm.referer.indexOf('bing') != -1 || leadUtm.referer.indexOf('facebook') != -1) {
			var source = 'google';
			if (leadUtm.referer.indexOf('yahoo') != -1) {
			  source = 'yahoo';
			} else if (leadUtm.referer.indexOf('bing') != -1) {
			  source = 'bing';
			} else if (leadUtm.referer.indexOf('facebook') != -1) {
			  source = 'facebook';
			}
			leadUtm.utmSource = source;
			leadUtm.utmMedium = 'organic';
		  } else {
			if (leadUtm.referer.indexOf(window.location.host) != -1) {
			  leadUtm.utmSource = '(direct)';
			  leadUtm.utmMedium = '(none)';
			} else {
			  leadUtm.utmSource = leadUtm.referer;
			  leadUtm.utmMedium = 'referral';
			}
		  }
		} else {
		  leadUtm.utmSource = '(direct)';
		  leadUtm.utmMedium = '(none)';
		}
	  } else {
		leadUtm.utmSource = '(direct)';
		leadUtm.utmMedium = '(none)';
	  }

	  leadUtm.referer = undefined;
	  return leadUtm;
}

export const getUtmParamsSetCookie = () => {
	const queryParams = new URLSearchParams(window.location.search)
	const cookieUtm = getUtmParams();
	var paramsObj = {
		utmSource : queryParams.get("utm_source") || cookieUtm.utmSource,
		utmMedium : queryParams.get("utm_medium") || cookieUtm.utmMedium,
		utmCampaign : queryParams.get("utm_campaign") || cookieUtm.utmCampaign,
		utmAdgroup : queryParams.get("utm_adgroup") || cookieUtm.utmAdgroup,
		utmTerm : queryParams.get("utm_term") || cookieUtm.utmTerm,
		utmNetwork : queryParams.get("utm_network") || cookieUtm.utmNetwork,
		utmMatchtype : queryParams.get("utm_matchtype") || cookieUtm.utmMatchtype,
		utmDevice : queryParams.get("utm_device") || cookieUtm.utmDevice,
		utmPlacement : queryParams.get("utm_placement") || cookieUtm.utmPlacement,
		utmContent : queryParams.get("utm_content") || cookieUtm.utmContent,
		utmAdposition : queryParams.get("utm_adposition") || cookieUtm.utmAdposition,
		utmLocation : queryParams.get("utm_location") || cookieUtm.utmLocation,
		utmSitelink : queryParams.get("utm_sitelink") || cookieUtm.utmSitelink,
		utmAudience : queryParams.get("utm_audience") || cookieUtm.utmAudience,
		utmPromotion : queryParams.get("utm_promotion") || cookieUtm.utmPromotion,
		utmPrice : queryParams.get("utm_Price") || cookieUtm.utmPrice,
		gclid : queryParams.get("gclid") || cookieUtm.gclid,
		fbclid : queryParams.get("fbclid") || cookieUtm.fbclid,
		referer : window.document.referrer,
		utmUrl : queryParams.get("utm_url") || cookieUtm.utmUrl || window.location.href
	};

	let utmParam = parseUtmParamsForLead(paramsObj);

	setCookie("leadUtm", JSON.stringify(utmParam));
	// window.console.log("leadUtm",JSON.parse(getCookie("leadUtm")||""));
	return utmParam;
}



export const getUtmParams = () => {
	return JSON.parse(getCookie("leadUtm")||"{}");
}