/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from "reselect";
// selector
const getCreditOrderCreateData = (state: any) =>
	state.getIn(["creditMintpayReducer", "creditState"]);
const getCreditOrderCreateError = (state: any) =>
	state.getIn(["creditMintpayReducer", "creditStateFailure"]);

const getCreditOrderStatus = (state: any) =>
	state.getIn(["creditMintpayReducer", "creditOrderStatus"]);
const getCreditOrderStatusError = (state: any) =>
	state.getIn(["creditMintpayReducer", "creditOrderStatusFailure"]);





const creditMintpaySelector = createSelector(
	[
        getCreditOrderCreateData, 
        getCreditOrderCreateError,
        getCreditOrderStatus,
        getCreditOrderStatusError
    ],
	(
        creditState, 
        creditStateFailure,
        creditOrderStatus,
        creditOrderStatusFailure
        
    ) => ({
		creditState, 
        creditStateFailure,
        creditOrderStatus,
        creditOrderStatusFailure
	})
);

export default creditMintpaySelector;