import React, { useEffect } from "react";
import "./app.css";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Mintpay from "views/mintpay";
import styled from "styled-components";
import AppHeader from "components/header";
import { useSelector, useDispatch } from "react-redux";
import { getCookie, isMobileView } from "common/commonService";
import { getBrokerConfigSelector } from "common/broker-config.selector";

import { propsToJS } from "__utils/immutable-to-js";
import { initDefaultThings } from "__utils/dealer";
import { getSessionStorage } from "storage";
import MainStorage from "storage/storage";
import _ from "lodash";
import { createSelector } from "reselect";
import { gtmPixel } from "__utils/mixpanel";
import { goToPartnerPage } from "__utils/dealer";



const BROKER_CONFIG: MainStorage = getSessionStorage("brokerConfig");


export const ThemeRootContext: React.Context<any> = React.createContext({});

const App: React.FC = () => {
	const dispatch = useDispatch();
	const partnerSelectorFunc = (state: any) => state.get("partnerDetailReducer");
	const [brokerTheme, updateBrokerTheme] = React.useState({});

	const brokerConfigState = useSelector(getBrokerConfigSelector);
	const brokerConfig = propsToJS(brokerConfigState);
	const adminRouteCheck = window.location.pathname;
	const history = useHistory();
	console.log("top url", adminRouteCheck);

	useEffect(() => {
		if (adminRouteCheck.includes("/admin/")) {
			console.log("admin url", adminRouteCheck);
		} else {
			console.log("other url", adminRouteCheck);
			const isPartnerSession = getCookie('dealerUserName') ||getCookie("pospUserName");
			if (!isPartnerSession) {
				goToPartnerPage("/dashboard");
			}
		}
	}, []);
	
	useEffect(() => {

		const tenantName = brokerConfig.brokerConf.tenant || getCookie("tenant")!;
		// Setting/clearout cookies and session - all such things takes place here
		initDefaultThings();
		gtmPixel("GTM-PCZGBP")
	}, [brokerConfig]);
	return (
		<AppWrapper theme={brokerTheme}>
			<AppHeader />
			<SectionMain>
				<Switch>
					
					<Route
						path="/insurance/mintpay"
						component={Mintpay}
					/>
					<Redirect from="/**/" to="/404"></Redirect>
				</Switch>
			</SectionMain>
		</AppWrapper>
	);
};

const AppWrapper = styled.div<{ theme: any }>`
	display: flex;
	background: ${({ theme }) => theme.PAGE_BG_COLOR};
	overflow: hidden;
	height: 100%;
	font-family: "robotoregular", Helvetica, Arial, sans-serif !important;
`;

const SectionMain = styled.section`
	width: 100%;
	margin-top: 0px;
	padding: 0px;
	overflow: scroll;
`;
export default App;
