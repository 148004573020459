import { ofType, Epic } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { get, patch, post, put } from "__utils/ajax-wrapper";
import { API_CONSTANTS } from "__utils/api-constants";
import { FluxStandardAction } from "__utils/type";
import { getCookie } from "common/commonService";
import {
	FETCH_SEARCH_DP_BY_ID,
	FETCH_SEARCH_DP_BY_ID_SUCCESS,
	FETCH_SEARCH_DP_BY_ID_ERROR
} from "../../../constants/actions";
import {
	GET_QSB_REPORTS,
	GET_QSB_REPORTS_SUCCESS,
	GET_QSB_REPORTS_ERROR,
	GET_WALLET_STATUS,
	GET_WALLET_STATUS_ERROR,
	GET_WALLET_STATUS_SUCCESS,
	POST_QSB_INITIATE,
	POST_QSB_INITIATE_REPEAT,
	POST_QSB_INITIATE_ERROR,
	POST_QSB_INITIATE_SUCCESS,
	POST_QSB_INITIATE_UNAUTHORISED,
	SET_WALLET_STATUS,
	SET_WALLET_STATUS_ERROR,
	SET_WALLET_STATUS_SUCCESS,
	SET_WALLET_STATUS_UNAUTHORISED
} from "./action";

export const getSearchDPByID: Epic<FluxStandardAction> = action$ => {
	return action$.pipe(
		ofType(FETCH_SEARCH_DP_BY_ID),
		mergeMap(action => {

			const searchDpID = action.payload.searchDpID;

			return get(
				`${API_CONSTANTS.SEARCH_BY_DP_ID}/${searchDpID}`,
				{},
				{ showLoader: false }
			).pipe(
				map(response => {
					if (response.status === 200) {
						return {
							type: FETCH_SEARCH_DP_BY_ID_SUCCESS,
							payload: response.response.responseData
						};
					} else {
						return {
							type: FETCH_SEARCH_DP_BY_ID_ERROR,
							error: response.response
						};
					}
				}),
				catchError(error =>
					of({
						type: FETCH_SEARCH_DP_BY_ID_ERROR,
						error: error.response
					})
				)
			);
		})
	);
};

export const getQSBReportsEpic: Epic<FluxStandardAction> = action$ => {
	return action$.pipe(
		ofType(GET_QSB_REPORTS),
		mergeMap(action => {
			return get(`${API_CONSTANTS.GET_QSB_REPORT}`, null, {
				showLoader: true
			}).pipe(
				map(response => {
					if (response.status === 200) {
						return {
							type: GET_QSB_REPORTS_SUCCESS,
							payload: response.response.responseData
						};
					} else {
						return {
							type: GET_QSB_REPORTS_ERROR,
							error: response.response
						};
					}
				}),
				catchError(error =>
					of({
						type: GET_QSB_REPORTS_ERROR,
						error: error.response
					})
				)
			);
		})
	);
};

export const initiateQSBEpic: Epic<FluxStandardAction> = action$ => {
	return action$.pipe(
		ofType(POST_QSB_INITIATE),
		mergeMap(action => {
			return get(
				`${API_CONSTANTS.INITIATE_QSB}`,
				{},
				{
					showLoader: true
				}
			).pipe(
				map(response => {
					if (response.status === 200) {
						return {
							type: POST_QSB_INITIATE_SUCCESS,
							payload: response.response
						};
					} else if (response.status === 409) {
						return {
							type: POST_QSB_INITIATE_REPEAT,
							payload: response.response
						};
					} else if (
						response.status === 403 ||
						response.status === 401
					) {
						return {
							type: POST_QSB_INITIATE_UNAUTHORISED,
							payload: response.response
						};
					} else {
						return {
							type: POST_QSB_INITIATE_ERROR,
							error: response.response
						};
					}
				}),
				catchError(error => {
					if (error.status === 409) {
						return of({
							type: POST_QSB_INITIATE_REPEAT,
							error: error.response
						});
					} else if (error.status === 403 || error.status === 401) {
						return of({
							type: POST_QSB_INITIATE_UNAUTHORISED,
							error: error.response
						});
					} else {
						return of({
							type: POST_QSB_INITIATE_ERROR,
							error: error.response
						});
					}
				})
			);
		})
	);
};

export const getWalletStatusEpic: Epic<FluxStandardAction> = action$ => {
	return action$.pipe(
		ofType(GET_WALLET_STATUS),
		mergeMap(action => {
			return get(
				`${API_CONSTANTS.PAYMENT_SERVICE_CONFIG}/mintpay`,
				null,
				null
			).pipe(
				map(response => {
					if (response.status === 200) {
						return {
							type: GET_WALLET_STATUS_SUCCESS,
							payload: !response.response.responseData
						};
					} else {
						return {
							type: GET_WALLET_STATUS_ERROR,
							error: response.response
						};
					}
				}),
				catchError(error =>
					of({
						type: GET_WALLET_STATUS_ERROR,
						error: error.response
					})
				)
			);
		})
	);
};

export const setWalletStatusEpic: Epic<FluxStandardAction> = action$ => {
	return action$.pipe(
		ofType(SET_WALLET_STATUS),
		mergeMap(action => {
			return patch(
				`${API_CONSTANTS.PAYMENT_SERVICE_CONFIG}`,
				{
					featureName: action.payload.featureName,
					isActive: action.payload.isActive
				},
				null
			).pipe(
				map(response => {
					if (response.status === 200) {
						return {
							type: SET_WALLET_STATUS_SUCCESS,
							payload: !response.response.responseData.isActive
						};
					} else if (
						response.status === 403 ||
						response.status === 401
					) {
						return {
							type: SET_WALLET_STATUS_UNAUTHORISED,
							payload: response.response
						};
					} else {
						return {
							type: SET_WALLET_STATUS_ERROR,
							error: response.response
						};
					}
				}),
				catchError(error => {
					if (error.status === 403 || error.status === 401) {
						return of({
							type: SET_WALLET_STATUS_UNAUTHORISED,
							error: error.response
						});
					} else {
						return of({
							type: SET_WALLET_STATUS_ERROR,
							error: error.response
						});
					}
				})
			);
		})
	);
};
