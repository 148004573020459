import {
	FETCH_SEARCH_DP_BY_ID,
	FETCH_SEARCH_DP_BY_ID_ERROR,
	FETCH_SEARCH_DP_BY_ID_SUCCESS,
	RESET_SEARCHED_LIST
} from "../../../constants/actions";

import { fromJS, merge } from "immutable";
import { FluxStandardAction } from "__utils/type";
import {
	GET_QSB_REPORTS_ERROR,
	GET_QSB_REPORTS_SUCCESS,
	GET_WALLET_STATUS_ERROR,
	GET_WALLET_STATUS_SUCCESS,
	POST_QSB_INITIATE_ERROR,
	POST_QSB_INITIATE_REPEAT,
	POST_QSB_INITIATE_SUCCESS,
	POST_QSB_INITIATE_UNAUTHORISED,
	SET_WALLET_STATUS_ERROR,
	SET_WALLET_STATUS_SUCCESS,
	SET_WALLET_STATUS_UNAUTHORISED
} from "./action";

export interface dpState {
	isFetching: boolean;
	partnerIDList: any[];
	partnerIDListStateFailure: string;
	qsbObjects: any;
	qsbInitiationSuccess: boolean;
	qsbInitiationRepeated: boolean;
	qsbUnauthorisedInitiation: boolean;
	qsbInitiationError: boolean;
	walletStatusChangeSuccess: boolean;
	walletDisableUnauthorised: boolean;
	walletDisableStatus: boolean;
	walletStatusChangeError: boolean;
}
const rawState: dpState = {
	isFetching: false,
	partnerIDList: [],
	partnerIDListStateFailure: "",
	qsbObjects: null,
	qsbInitiationSuccess: false,
	qsbInitiationRepeated: false,
	qsbUnauthorisedInitiation: false,
	qsbInitiationError: false,
	walletStatusChangeSuccess: false,
	walletDisableUnauthorised: false,
	walletDisableStatus: false,
	walletStatusChangeError: false
};
const intialState = fromJS(rawState);
export const searchDPReducer = (
	state = intialState,
	action: FluxStandardAction
) => {
	switch (action.type) {
		case FETCH_SEARCH_DP_BY_ID:
			return merge(state, { isFetching: true });
		case FETCH_SEARCH_DP_BY_ID_SUCCESS:
			return merge(
				state,
				{ partnerIDList: [action.payload] },
				{ isFetching: false }
			);
		case RESET_SEARCHED_LIST:
			return merge(state, { partnerIDList: [] }, { isFetching: false });
		case FETCH_SEARCH_DP_BY_ID_ERROR:
			return merge(
				state,
				fromJS({ partnerIDListStateFailure: action.error }),
				{ isFetching: false }
			);
		case GET_QSB_REPORTS_SUCCESS:
			return merge(
				state,
				{ qsbObjects: action.payload },
				{ isFetching: false }
			);
		case GET_WALLET_STATUS_SUCCESS:
			return merge(state, { walletDisableStatus: action.payload });
		case GET_WALLET_STATUS_ERROR:
			return merge(state, { walletDisableStatus: false });
		case GET_QSB_REPORTS_ERROR:
			return merge(state, { qsbObjects: null }, { isFetching: false });
		case POST_QSB_INITIATE_SUCCESS:
			return merge(
				state,
				{ qsbInitiationSuccess: true },
				{ isFetching: false }
			);
		case POST_QSB_INITIATE_UNAUTHORISED:
			return merge(state, {
				qsbUnauthorisedInitiation: true,
				qsbInitiationSuccess: false
			});
		case POST_QSB_INITIATE_REPEAT:
			return merge(state, { qsbInitiationRepeated: true });
		case POST_QSB_INITIATE_ERROR:
			return merge(state, { qsbInitiationError: true });
		case SET_WALLET_STATUS_SUCCESS:
			return merge(state, {
				walletStatusChangeSuccess: true,
				walletDisableStatus: action.payload
			});
		case SET_WALLET_STATUS_UNAUTHORISED:
			return merge(state, { walletDisableUnauthorised: true });
		case SET_WALLET_STATUS_ERROR:
			return merge(state, { walletStatusChangeError: true });
		default:
			return state;
	}
};
