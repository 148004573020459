import React, { useState, useEffect } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import styled from "styled-components";
import MintpayAddMoney from "./credit";
import MintpayOTP from "./otp";
import MintpayPIN from "./pin";
import MintpayTransactions from "./transactions";
import MintpayWallet from "./wallet";
import MintpayTransaction from "./transactions/transaction";
import MintpayMaintenance from "./maintenance"
import MintpayIntro from "./intro";
import SuccessfulOperation from "./successful-opreration";
import CreditMoneyStatus from "./credit/credit-status";
import AdminLoginPage from "./admin-login";
import PartnerDetails from "./partner-details";
import BankDetails from "./bank-details";
import SearchPartner from "./search-partner";
import QSBReportDetails from "./qsb-report-details";
import { getUserInformation } from "components/admin-login-info";
import { propsToJS } from "__utils/immutable-to-js";
import { useSelector } from "react-redux";
import LoginSelector from "./admin-login/admin-selector";
import { useDeepCompare } from "hooks/use-deep-memo";

const Mintpay: React.FC = () => {
	const [loggedIn, setLoggedIn] = useState<boolean>(false);
	const { registerToken } = propsToJS(useSelector(LoginSelector));
	const userInfo = getUserInformation();
	useEffect(() => {
		console.log("dy url", userInfo);
	}, [useDeepCompare(registerToken)]);
	console.log("registered ??", userInfo);
	userInfo && console.log("mintpay", loggedIn);

	return (
		<BrowserRouter basename="/insurance/mintpay">
			<Wrapper>
				<Switch>
					<Route exact path="/wallet" component={MintpayWallet} />
					<Route exact path="/otp" component={MintpayOTP} />
					<Route exact path="/pin" component={MintpayPIN} />
					<Route
						exact
						path="/add-money"
						component={MintpayAddMoney}
					/>
					<Route
						exact
						path="/transactions"
						component={MintpayTransactions}
					/>
					<Route
						exact
						path="/transaction"
						component={MintpayTransaction}
					/>
					<Route
						exact
						path="/introduction"
						component={MintpayIntro}
					/>
					<Route
						exact
						path="/maintenance"
						component={MintpayMaintenance}
					/>
					<Route
						exact
						path="/status-page/:stage/:status"
						component={SuccessfulOperation}
					/>
					<Route
						exact
						path="/credit-status/:orderId"
						component={CreditMoneyStatus}
					/>
					<Route
						exact
						path="/admin/login"
						component={userInfo ? SearchPartner : AdminLoginPage}
					/>
					{userInfo ? (
						<div>
							<Route
								exact
								path="/admin/partners"
								component={SearchPartner}
							/>
							<Route
								exact
								path="/admin/partner/:id"
								component={PartnerDetails}
							/>
							<Route
								exact
								path="/admin/qsb-report-detail/:id"
								component={QSBReportDetails}
							/>
							<Route
								exact
								path="/bank-details"
								component={BankDetails}
							/>{" "}
						</div>
					) : (
						<Redirect to={"/admin/login"} />
					)}
				</Switch>
			</Wrapper>
		</BrowserRouter>
	);
};

const Wrapper = styled.div``;

export default Mintpay;
