export const GET_QSB_REPORTS = "GET_QSB_REPORTS";
export const GET_QSB_REPORTS_SUCCESS = "GET_QSB_REPORTS_SUCCESS";
export const GET_QSB_REPORTS_ERROR = "GET_QSB_REPORTS_ERROR";

export const POST_QSB_INITIATE = "POST_QSB_INITIATE";
export const POST_QSB_INITIATE_SUCCESS = "POST_QSB_INITIATE_SUCCESS";
export const POST_QSB_INITIATE_REPEAT = "POST_QSB_INITIATE_REPEAT";
export const POST_QSB_INITIATE_UNAUTHORISED = "POST_QSB_INITIATE_UNAUTHORISED";
export const POST_QSB_INITIATE_ERROR = "POST_QSB_INITIATE_ERROR";

export const GET_WALLET_STATUS = "GET_WALLET_STATUS";
export const GET_WALLET_STATUS_SUCCESS = "GET_WALLET_STATUS_SUCCESS";
export const GET_WALLET_STATUS_ERROR = "GET_WALLET_STATUS_ERROR";

export const SET_WALLET_STATUS = "SET_WALLET_STATUS";
export const SET_WALLET_STATUS_SUCCESS = "SET_WALLET_STATUS_SUCCESS";
export const SET_WALLET_STATUS_UNAUTHORISED = "SET_WALLET_STATUS_UNAUTHORISED";
export const SET_WALLET_STATUS_ERROR = "SET_WALLET_STATUS_ERROR";