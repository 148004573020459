import * as React from "react";
import MintpayPinLock from "../../assets/images/mintpay-pin-lock.svg";
import {
	Icon
} from "@turtlemint/mint-ui";
import {
	List, Avatar, Divider
} from "antd";
import { useHistory } from 'react-router-dom';
import { triggerEvents } from "__utils/mixpanel";


interface TmPinSectionProps {
	data: any,
	showDivider?: boolean
}

const PinSection : React.FC<TmPinSectionProps>= (props : TmPinSectionProps) => {

	const history = useHistory();

	const handlePinRedirection = () => { 
		if(props.data.accountExist) {
			triggerEvents("MintPay_Wallet_ChangePin", {
				WalletDetail : props.data
			});
            history.push("/otp");
        } else {
			triggerEvents("MintPay_Wallet_SetUpPin", {
				WalletDetail : props.data
			});
            history.push({pathname : "/pin", state : { stage:"SET" }});
        }
	}
    return (
        <div onClick={handlePinRedirection}>
            <List.Item className="pin-change">
					<List.Item.Meta
						avatar={<Avatar src={MintpayPinLock} />}
						title={props.data.accountExist ? "Change Mintpay Pin" : "Set Mintpay Pin"}
						style = {{cursor: "pointer"}}
					/>
					<div className="arrow-right">
					<Icon style={{color:"black"}} name="keyboard_arrow_right"/>
					</div>
				</List.Item>
				{props.showDivider && <Divider style={{ marginBottom: "0.1rem ",  marginTop: "0.1rem", border: "1px solid #D2D5D6"}}/>}
        </div>
    )

}

export default React.memo(PinSection);