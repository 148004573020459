/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from "reselect";

const registerToken = (state: any) => state.getIn(["LoginReducer", "registerToken"]);
console.log("tokennnn", registerToken);

const LoginSelector = createSelector(
	[registerToken],
	(registerToken) => ({
		registerToken
	})
);

export default LoginSelector;
