/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from "reselect";
// selector
const getPartnerWalletCreateData = (state: any) =>
	state.getIn(["pinReducer", "partnerWalletCreateState"]);
const getPartnerWalletCreateError = (state: any) =>
	state.getIn(["pinReducer", "partnerWalletCreateStateFailure"]);


const getPinValidateData = (state: any) =>
	state.getIn(["pinReducer", "validatePinState"]);
const getPinValidateError = (state: any) =>
	state.getIn(["pinReducer", "validatePinStateFailure"]);


const getPinResetData = (state: any) =>
	state.getIn(["pinReducer", "resetPinState"]);
const getPinResetError = (state: any) =>
	state.getIn(["pinReducer", "resetPinStateFailure"]);


const getPinStage = (state: any) => {
    state.getIn(["pinReducer", "pinStage"]);
}

const pinSelector = createSelector(
	[
        getPartnerWalletCreateData, 
        getPartnerWalletCreateError,
        getPinValidateData,
        getPinValidateError,
        getPinResetData,
        getPinResetError,
        getPinStage
    ],
	(
        partnerWalletCreateState, 
        partnerWalletCreateStateFailure,
        validatePinState,
        validatePinStateFailure,
        resetPinState,
        resetPinStateFailure,
        pinStage

    ) => ({
		partnerWalletCreateState,
		partnerWalletCreateStateFailure,
        validatePinState,
        validatePinStateFailure,
        resetPinState,
        resetPinStateFailure,
        pinStage
	})
);

export default pinSelector;