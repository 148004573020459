/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from "reselect";

const getBrokerConfig = (state: any) => state.get("brokerConfig");

export const getBrokerConfigSelector = createSelector(
	[getBrokerConfig],
	brokerConf => ({
		brokerConf
	})
);
