import moment from "moment";

const DATE_PATTERN_DD_MM_YYYY = 'DD/MM/YYYY';

/**
 * @param timestamp epoch date and time
 * @returns Date only e.g 23
 */
export const getDate = (timestamp: any,) => {
    let formatdate = moment(timestamp).format('DD-MMM-YY').split('-');				
    return  formatdate[0];
    
}

/**
 * @param timestamp epoch date and time
 * @returns Date only e.g 23 Feb
 */
export const getDateMonth = (timestamp: any) => {
    let formatdate = moment(timestamp).format('DD-MMM-YY').split('-');				
    return `${formatdate[0]} ${formatdate[1]}`;
    
}

/**
 * @param timestamp epoch date and time
 * @returns Date only e.g Feb 22
 */
export const getMonthYear = (timestamp: any) => {
    let formatdate = moment(timestamp).format('DD-MMM-YY').split('-');				
    return `${formatdate[1]} ${formatdate[2]}`;
    
}


/**
 * @param timestamp epoch date and time
 * @param type what u need DATE or Time or whole date and time 
 * @returns Date only e.g DATE: 23 March 22  Time: 10.00 AM, Datetime only e.g 23 Feb 22 10.00 Am
 */
export const getDateTime = (timestamp: any, type?: string) => {
    let formatdate = moment(timestamp).format("DD MMM YYYY HH:mm:ss")
    switch (type) {
        case "DATE":
            return moment(timestamp).format('DD MMMM YYYY');

        case "TIME":
            return moment(timestamp).format('hh:mm A');		
    
        case "DATETIME":
            return moment(timestamp).format('DD MMM YY hh:mm A');	

        default:
            break;
    }
    return formatdate;
}
