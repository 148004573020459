import * as React from "react";
import styled, { css } from "styled-components";
import { Icon } from "antd";
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined'
import { COLORS } from "@turtlemint/mint-ui";
import TransactionDetail from "../transaction-detail"
import { 
	isMobileView,
    filterINR
} from "../../../../common/commonService";
import { propsToJS } from "__utils/immutable-to-js";
import {getDate, getMonthYear} from "__utils/date-format";
import { useDispatch, useSelector } from "react-redux";
import transactionsSelector from "../transactions.selector";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
// import ShareVia from "views/mintpay/share-via";
import shareImg from "images/share.svg";
import closeIcon from "images/close-icon.svg";
import { CloseOutlined } from "@ant-design/icons";
import ShareDrawer from "./../../share-drawer";


const isMobile = isMobileView();

const MintpayTransaction : React.FC = () => {
	const dispatch = useDispatch();
	const { transactionsByIdState } = propsToJS(useSelector(transactionsSelector));
	const [transactionDetails, setTransactionDetails] = useState<any>(null);
	const history = useHistory();

	const backToTransactionsHandler = () => {
		history.push("/transactions");
	}

	useEffect(() => {
		if(transactionsByIdState && transactionsByIdState.walletTransactionList[0]){
			setTransactionDetails(transactionsByIdState.walletTransactionList[0]);
		}
	},[transactionsByIdState]);

	
	const Transaction = styled.div<{ isMobile?: boolean }>`
		background-color: white;
		height: calc(${document.body.clientHeight + 'px'} - 0px);
        .transaction-amount {
            text-align: center;
            margin-top: ${isMobile ? '103px': '0px'};
            margin-bottom : 43px;
            .date {
                height: 80px;
                width: 80px;
                margin: auto;
                text-align: center;
                vertical-align: middle;
                border-radius: 50%;
                background: #00A465;
                font-size: 21px;
                color: #FFFFFF;
                font-weight: bold;
                padding: 4px;
            }
            .name {
                font-size: 18px;
                color: #212121;
                font-weight : bold;

            }
            .amount {
                font-size: 30px;
                color : #00A465
                font-weight : bold;
                .icon {
                    font-size: 30px;
                    color : ${COLORS.BLACK}
                }

            }
            
        }
		${({ isMobile }) =>
				isMobile ?
				css`
					margin-top : 60px;
					.colse-share-icon{
						display: flex;
						justify-content: space-between;
						padding: 25px 25px 0px 25px;
					}
				`
				:
				css`
					padding: 24px;
					.transaction-details{
						background: #FFFFFF;
						border: 1px solid #D2D5D6;
						padding: 30px;
						border-radius: 8px;
					}
				`};
	`;

	const Header = styled.div<{ isMobile?: boolean }>`
		margin: 16px;
		${({ isMobile }) =>
				isMobile &&
				css`
					position: relative;
					height: 56px;
					top: 0px;
					width: 100%;
					padding: 16px;
					background: #FFFFFF;
					box-shadow: 0px 1px 4px rgb(0 0 0 / 24%);
					margin: 0px
				`};
	`;
	const TransactionContent = styled.div<{ isMobile?: boolean }>`
		${({ isMobile }) =>
				!isMobile &&
				css`
				    background: #F5F5F5;
					border: 1px solid #E5E5E5;
					border-radius: 8px;
					padding-top: 18px;
					padding-bottom: 18px;
					padding-left: 50px;
					padding-right: 50px
					width: 100%
				`};
	`

	return (
		<div style={isMobile ? {} : {display: "flex", flexDirection:"row"}}>
		<Transaction isMobile={isMobile}>
			<TransactionContent isMobile={isMobile}>
				{isMobile && <Header isMobile={isMobile}>
					<div onClick={backToTransactionsHandler}>
						<ArrowLeftOutlined
						style={{
							fontSize: "20px",
							color: `${COLORS.BLACK}`,
							cursor: "pointer"
						}}></ArrowLeftOutlined>
					</div>
				</Header>}
				{isMobile && <div className="colse-share-icon" >
				<img onClick={backToTransactionsHandler} src={closeIcon}></img>
				<img src={shareImg}></img>
				</div>}
				{transactionDetails ? 
                (<div className = "transaction-details" ><div className = "transaction-amount">
                    <div className="date"> {getDate(transactionDetails.date)} <br/> {getMonthYear(transactionDetails.date)}</div>
                    <div className="name">{transactionDetails.description}</div>
                    <div className="amount"> <span className="icon">₹</span>{ filterINR(transactionDetails.transactionAmount)  }</div>
                </div>
                <TransactionDetail data={transactionDetails}></TransactionDetail> </div>)
				: null }
			</TransactionContent>
			{/* <ShareDrawer></ShareDrawer> */}
		</Transaction>
		{/* { !isMobile && <div style={{    padding: '24px'}}>
		<ShareVia></ShareVia>
		</div>} */}
      </div>
	);
};

export default MintpayTransaction;