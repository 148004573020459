import { merge, fromJS } from "immutable";
import { FluxStandardAction } from "__utils/type";
import { SHOW_HEADER, HIDE_HEADER } from "../../constants/actions";

const headerData = {
	show: true,
	partnerHeader: false,
	partnerData: {
		name: "",
		mobile: ""
	}
};

const initialState = fromJS(headerData);

export const headerReducer = (
	state = initialState,
	action: FluxStandardAction
) => {
	const { type, payload = {} } = action;

	switch (type) {
		case SHOW_HEADER:
			return merge(state, fromJS({ show: true, ...payload }));
		case HIDE_HEADER:
			return merge(state, fromJS({ show: false, ...payload }));
		default:
			return state;
	}
};
