import TmPin from "common/components/pin";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { 
	isMobileView,
	getCookie
} from "../../../common/commonService";
import "../wallet/wallet.css"
import styled, { css } from "styled-components";
import PINDescription from "./pin-description";
import {
	Button,
} from "@turtlemint/mint-ui";
import { PARTNER_WALLET_CREATE_WITH_PIN, PIN_STAGE, RESET_PIN, CLEAR_RESET_PIN_STATE } from "constants/actions";
import { useParams, useLocation } from "react-router-dom";
import pinSelector from "./pin.selector";
import mintPayOTPSelector from "./../otp/otp.selector"
import { propsToJS } from "__utils/immutable-to-js";
import {
	SHOW_HEADER
} from "constants/actions";
import { useHistory } from "react-router-dom";
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import { COLORS } from "@turtlemint/mint-ui";
import lodash from "lodash";
import { useDeepCompare } from "hooks/use-deep-memo";
import { triggerEvents } from "__utils/mixpanel";



const isMobile = isMobileView();

const PinScreen = styled.div<{ isMobile?: boolean }>`
		margin-top: ${isMobile ? '35px': '60px'};
		position: relative;
		background-color: white;
		height: calc(${document.body.clientHeight + 'px'} - ${isMobile ? '108px' : '60px'});
		padding-top:24px;
		.payment-description { 
			margin-top: 34px;
		}
		.pin-description {
			margin-bottom : 20px;
		}
	`;

	const PinScreenContent = styled.div<{ isMobile?: boolean }>`
	padding-top : 20px;
	.colse-share-icon{    
		display: flex;
		justify-content: space-between;
		padding-bottom: 15px;

	}
		${({ isMobile }) =>
		!isMobile &&
		css`
			border: 1px solid #E5E5E5;
			border-radius: 8px;
			padding: 35px;
			width: 30%
			margin:auto;
		`};
	`;

	const SubmitPin = styled.div<{ isMobile?: boolean }>`

		.submit-pin {
			width: 100%;
			margin-top: 24px;
		}
		${({ isMobile }) =>
		isMobile &&
		css`
		  .submit-pin {
			position: fixed;
			bottom: 0;
			width: 100%;
			border: none;
			border-radius: 0px;
		  }	
		`};
	`
	const Header = styled.div<{ isMobile?: boolean }>`
		${({ isMobile }) =>
				isMobile &&
				css`
					position: relative;
					height: 56px;
					top: 0px;
					width: 100%;
					padding: 16px;
					background: #FFFFFF;
					box-shadow: 0px 1px 4px rgb(0 0 0 / 24%);
					margin: 0px
				`};
	`;
const MintpayPIN : React.FC = () => {
	const dispatch = useDispatch();
	let [stage, setStage] = useState<string>("SET");
	const location: any = useLocation();
	const history = useHistory();
	const [pin, setPin] = useState<string>("");
	const { partnerWalletCreateState, resetPinState } = propsToJS(useSelector(pinSelector));
	const { userOtp, otpState, otpSessionId } = propsToJS(useSelector(mintPayOTPSelector));

	const [otp, setOtp] = useState<string>("");
	const [landingPageLoaded, setLandingPageLoaded] = useState(0);

	useEffect(()=>{
		dispatch({type : CLEAR_RESET_PIN_STATE })		
		if(lodash.has(location, "state")){
			stage = lodash.get(location, "state.stage");
			setStage(stage);
		}
	})
	useEffect(() => {
		triggerEvents("MintPay_Pin_PageLoad", {
			stage: stage
		});
		setLandingPageLoaded(1)
	}, [landingPageLoaded === 0]);
	
	useEffect(() => {
		dispatch({ type: SHOW_HEADER, payload: { show: true} });
	}, []);

	useEffect(() => {
		if (lodash.has(location, "state")) {
		  const otp = lodash.get(location, "state.otp");
		  setOtp(otp);
		}
	  }, [useDeepCompare(location)]);

	useEffect(() => {
		dispatch(
			{ 
				type: PIN_STAGE , 
				payload: {
					stage : stage 
				}
			})
	}, [])

	useEffect(() => {
		if (lodash.has(partnerWalletCreateState, "success") && partnerWalletCreateState.success) {
			history.push("/wallet");
		}
	}, [partnerWalletCreateState])

	useEffect(()=> {
		
		if(lodash.has(resetPinState, "success") && resetPinState.success){
			triggerEvents("MintPay_Pin_Success", {
				stage: stage
			});
			history.push("/status-page/pin/success");
		} 
	},[resetPinState])
	
	const pinStage : any = {
		SET : {
			isShowHide : true,
			isForgetPin : false,
			isPaymentDescription : false,
			pinTitle : 'Setup PIN',
			pinSubTitle : 'You will need this PIN to authorize all payments made with Mintpay. Don’t worry, you can change it later.'
		},
		UPDATE: {
			isShowHide : true,
			isForgetPin : false,
			isPaymentDescription : false,
			pinTitle : 'Create New PIN',
			pinSubTitle : 'Please enter a new 4 digit pin you wish to set for authorizing your Mintpay payments'
		}
	}
	const onPinChangeHandler = (value : any) => {
		triggerEvents("MintPay_Pin_OnChnage", {
			stage: stage
		});
		setPin(value);
	}

	const onPinSubmit = (pinValue : any, pinStage: string) => {
		triggerEvents("MintPay_Pin_OnSubmit", {
			stage: stage
		});
		switch(pinStage){
			case "SET" :
				if(pinValue && pinValue.length === 4 ) {
					console.log("SET pin index pin:",pin);
					dispatch(
						{ 
							type: PARTNER_WALLET_CREATE_WITH_PIN , 
							payload: {
								"externalId": getCookie('dealerUserName') || getCookie("pospUserName"),
								"source": "test",
								"broker": "turtlemint",
								"tenant": "turtlemint",
								"pin": pin
							}
						})
				}
				break;
			case "UPDATE" :	
				console.log("UPDATE pin index pin:", pin + "sessionId", otpSessionId + " " + "otp", userOtp);
				if (pinValue && pinValue.length === 4 && userOtp && otpSessionId) {
					dispatch(
						{
							type: RESET_PIN,
							payload: {
								"pin": pin,
								"sessionId": otpSessionId,
								"otp": userOtp,
								"externalId" : getCookie('dealerUserName') || getCookie("pospUserName")
							}
						})
				}
				break;
			default : 
				console.log("Invalid state");
				
		}	
	}

	const backToWalletHandler = () => {
		triggerEvents("MintPay_Pin_BackToWallet", {
			stage: stage
		});
		history.push("/wallet");
	}

	
	return <PinScreen isMobile={isMobile}>
		{isMobile && <Header isMobile={isMobile}>
					<div onClick={backToWalletHandler}>
						<ArrowLeftOutlined
						style={{
							fontSize: "20px",
							color: `${COLORS.BLACK}`,
							cursor: "pointer"
						}}></ArrowLeftOutlined>
					</div>
				</Header>}
			<PinScreenContent isMobile={isMobile}>
			{!isMobile && <div className="colse-share-icon" onClick={backToWalletHandler}>
				<ArrowLeftOutlined
					style={{
					fontSize: "20px",
					color: `${COLORS.BLACK}`,
					cursor: "pointer"
				}}>
				</ArrowLeftOutlined>
				</div>}
				<PINDescription
					title={pinStage[stage].pinTitle}
					subTitle={pinStage[stage].pinSubTitle}
				></PINDescription>

				<TmPin onComplete ={onPinChangeHandler} length = {4} isShowHide={pinStage[stage].isShowHide} isForgetPin={pinStage[stage].isForgetPin}></TmPin>
				
				<SubmitPin isMobile={isMobile}>
					<Button
						className = "submit-pin"
						title="Continue"
						btnStyle="primary"
						btnType ="solid"
						size="large"
						disabled={pin.length == 4?false:true}
						onClick={() => onPinSubmit(pin, stage)}
						style={{
										
						}}>
					</Button>
				</SubmitPin>
			</PinScreenContent>
	</PinScreen>
	};

export default MintpayPIN;