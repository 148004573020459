import { BehaviorSubject } from "rxjs";
import { createEpicMiddleware, ofType } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { mergeMap, takeUntil } from "rxjs/operators";
import { combineEpics } from "redux-observable";
import partnerWalletInfo from "./views/mintpay/wallet/wallet.epic"
import { createWalletForPartner, validatePin, resetPin }  from "./views/mintpay/pin/pin.epic"
import { getTransactions, getTransactionsByID }  from "./views/mintpay/transactions/transactions.epic"
import { sendMintPayOTP, validateMintPayOTP }  from "./views/mintpay/otp/otp.epic"
import { createCreditOrder, getCreditOrderStatus }  from "./views/mintpay/credit/credit.epic"
import { getQSBReportsEpic, getSearchDPByID, getWalletStatusEpic, initiateQSBEpic, setWalletStatusEpic } from "views/mintpay/search-partner/search-partner.epic";
import { getPartnerBankDetailsEpic, getPartnerDetails, getPartnerTransactions, initiateQSBForPartner } from "views/mintpay/partner-details/partner-details-epic";
import { getLoginAPIEpic } from "views/mintpay/admin-login/admin-login.epic";

export const epic$ = new BehaviorSubject(
	combineEpics(
		partnerWalletInfo,
		createWalletForPartner,
		validatePin,
		resetPin,
		getTransactions,
		getTransactionsByID,
		sendMintPayOTP,
		validateMintPayOTP,
		createCreditOrder,
		getCreditOrderStatus,
		getSearchDPByID,
		getQSBReportsEpic,
		getPartnerDetails,
		getPartnerTransactions,
		getPartnerBankDetailsEpic,
		initiateQSBEpic,
		initiateQSBForPartner,
		getLoginAPIEpic,
		getWalletStatusEpic,
		setWalletStatusEpic
));

export const dependencies = { getJSON: ajax.getJSON };

export const epicMiddleWare = createEpicMiddleware({ dependencies });

export const rootEpic = (action$, ...rest) =>
	epic$.pipe(
		mergeMap(epic =>
			epic(action$, ...rest).pipe(
				takeUntil(action$.pipe(ofType("EPIC_END")))
			)
		)
	);
