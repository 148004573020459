/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from "reselect";
// selector
const getMintPayOTPData = (state: any) =>
	state.getIn(["mintPayOTPReducer", "otpState"]);
const getMintPayOTPDataError = (state: any) =>
	state.getIn(["mintPayOTPReducer", "otpStateFailure"]);


const validateMintPayOTPData = (state: any) =>
	state.getIn(["mintPayOTPReducer", "validateOtpState"]);
const validateMintPayOTPDataError = (state: any) =>
	state.getIn(["mintPayOTPReducer", "validateOtpStateFailure"]);

const getUserOtp = (state: any) =>
    state.getIn(["mintPayOTPReducer", "userOtp"]);

const getOtpSessionId = (state: any) =>
    state.getIn(["mintPayOTPReducer", "otpSessionId"]);

const mintPayOTPSelector = createSelector(
	[
        getMintPayOTPData, 
        getMintPayOTPDataError,
        validateMintPayOTPData,
        validateMintPayOTPDataError,
        getUserOtp,
        getOtpSessionId
    ],
	(
        otpState, 
        otpStateFailure,
        validateOtpState,
        validateOtpStateFailure,
        userOtp,
        otpSessionId

    ) => ({
		otpState, 
        otpStateFailure,
        validateOtpState,
        validateOtpStateFailure,
        userOtp,
        otpSessionId
	})
);

export default mintPayOTPSelector;