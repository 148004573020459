import { fromJS, merge } from "immutable";
import { FluxStandardAction } from "__utils/type";
import * as Actions from "./action";

export interface transactionState {
	isFetching: boolean;
	allTransactionsState: any[];
	allTransactionsStateFailure: undefined | string;
	bankDetails: any;
	bankDetailsError: any;
	initiateQSBPartnerSuccess: boolean;
	initiateQSBPartnerRepeat: boolean;
	initiateQSBPartnerUnauthorised: boolean;
	initiateQSBPartnerError: boolean;
}
const rawState: transactionState = {
	isFetching: false,
	allTransactionsState: [],
	allTransactionsStateFailure: undefined,
	bankDetails: null,
	bankDetailsError: null,
	initiateQSBPartnerSuccess: false,
	initiateQSBPartnerRepeat: false,
	initiateQSBPartnerUnauthorised: false,
	initiateQSBPartnerError: false
};
const intialState = fromJS(rawState);
export const partnerDetailsReducer = (
	state = intialState,
	action: FluxStandardAction
) => {
	switch (action.type) {
		// case GET_PARTNER_TRANSACTIONS:
		// 	return merge(state, { isFetching: true });
		case Actions.GET_PARTNER_TRANSACTIONS_SUCCESS:
			return merge(
				state,
				{
					allTransactionsState:
						action.payload.allTransactionsList.walletTransactionList
				},
				{ isFetching: false }
			);
		case Actions.GET_PARTNER_TRANSACTIONS_ERROR:
			return merge(
				state,
				fromJS({ allTransactionsStateFailure: action.error }),
				{ isFetching: false }
			);
		case Actions.GET_BANK_ACCOUNT_DETAILS_SUCCESS:
			return merge(
				state,
				{ bankDetails: action.payload.data },
				{ isFetching: false }
			);
		case Actions.GET_BANK_ACCOUNT_DETAILS_ERROR:
			return merge(state, fromJS({ bankDetailsError: action.error }), {
				isFetching: false
			});
		case Actions.POST_QSB_INITIATE_PARTNER_SUCCESS:
			return merge(state, fromJS({ initiateQSBPartnerSuccess: true }), {
				isFetching: false
			});
		case Actions.POST_QSB_INITIATE_PARTNER_UNAUTHORISED:
			return merge(state, { initiateQSBPartnerUnauthorised: true });
		case Actions.POST_QSB_INITIATE_PARTNER_REPEAT:
			return merge(state, { initiateQSBPartnerRepeat: true });
		case Actions.POST_QSB_INITIATE_PARTNER_ERROR:
			return merge(state, { initiateQSBPartnerError: true });
		default:
			return state;
	}
};
