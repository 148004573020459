import {
    GET_ALL_MINTPAY_TRANSACTIONS_SUCCESS,
    GET_ALL_MINTPAY_TRANSACTIONS_FAILURE,
    GET_MINTPAY_TRANSACTIONS_BY_ID_SUCCESS,
    GET_MINTPAY_TRANSACTIONS_BY_ID_FAILURE,
    GET_ALL_MINTPAY_TRANSACTIONS,
    GET_MINTPAY_TRANSACTIONS_BY_ID
} from "../../../constants/actions";

import { fromJS, merge } from "immutable";
import { FluxStandardAction } from "__utils/type";

export interface transactionState {
    isFetching: boolean;
    allTransactionsState: unknown;
    allTransactionsStateFailure: undefined | string;
    transactionsByIdState : unknown;
    transactionsByIdStateFailure : undefined  | string;
  }
  const rawState: transactionState = {
    isFetching: false,
    allTransactionsState: null,
    allTransactionsStateFailure: undefined,
    transactionsByIdState : null,
    transactionsByIdStateFailure : undefined
  };
  const intialState = fromJS(rawState);
  export const transactiosnReducer = (
    state = intialState,
	  action: FluxStandardAction
  ) => {
    switch (action.type) {
      case GET_ALL_MINTPAY_TRANSACTIONS : 
        return merge(state, { isFetching: true });
      case GET_ALL_MINTPAY_TRANSACTIONS_SUCCESS:
        return merge(state, { allTransactionsState: action.payload.allTransactionsList }, { isFetching: false });
      case GET_ALL_MINTPAY_TRANSACTIONS_FAILURE:
        return merge(state, fromJS({ allTransactionsStateFailure: action.error }), { isFetching: false });
      case GET_MINTPAY_TRANSACTIONS_BY_ID : 
        return merge(state, { isFetching: true });
      case GET_MINTPAY_TRANSACTIONS_BY_ID_SUCCESS:
        return merge(state, { transactionsByIdState:  action.payload.singleTransaction }, { isFetching: false });
      case GET_MINTPAY_TRANSACTIONS_BY_ID_FAILURE:
        return merge(state, fromJS({ transactionsByIdStateFailure: action.error }), { isFetching: false });
      default:
        return state;
    }
  }
  