/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from "reselect";
// selector


const getTransactionsIsFetching = (state: any) =>
	state.getIn(["transactiosnReducer", "isFetching"]);

const getAllTransactionsData = (state: any) =>
	state.getIn(["transactiosnReducer", "allTransactionsState"]);
const getAllTransactionsError = (state: any) =>
	state.getIn(["transactiosnReducer", "allTransactionsStateFailure"]);


const getTransactionsByIdData = (state: any) =>
	state.getIn(["transactiosnReducer", "transactionsByIdState"]);
const getTransactionsByIdDataError = (state: any) =>
	state.getIn(["transactiosnReducer", "transactionsByIdStateFailure"]);


const transactionsSelector = createSelector(
	[
        getAllTransactionsData, 
        getAllTransactionsError,
        getTransactionsByIdData,
        getTransactionsByIdDataError,
        getTransactionsIsFetching
    ],
	(
        allTransactionsState, 
        allTransactionsStateFailure,
        transactionsByIdState,
        transactionsByIdStateFailure,
        isFetching

    ) => ({
		allTransactionsState, 
        allTransactionsStateFailure,
        transactionsByIdState,
        transactionsByIdStateFailure,
        isFetching
	})
);

export default transactionsSelector;