import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import {isMobileView} from "../../../common/commonService";
import closeIcon from "images/close-icon.svg";
import OperationStatus from "./status"
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
	SHOW_HEADER
} from "constants/actions";
import { triggerEvents } from "__utils/mixpanel";


const isMobile = isMobileView();

const SuccessScreen = styled.div<{ isMobile?: boolean }>`
        
		margin-top: ${isMobile ? '35px' : '60px'};
		position: relative;
		background-color: ${isMobile ? '#00A465' : 'white'};
		height: calc(${document.body.clientHeight + 'px'} - ${isMobile ? '108px' : '60px'});
		padding-top:24px;
		.payment-description { 
			margin-top: 34px;
		}
		.pin-description {
			margin-bottom : 20px;
		}
		${({ isMobile }) =>
		isMobile &&
		css`
			margin-top: 0px !important;
			height: 100vh;
		`};

	`;
const SuccessScreenContent = styled.div<{ isMobile?: boolean }>`
	
	.close-icon{    
		display: flex;
		justify-content: end;
		padding-bottom: 15px;
        cursor : pointer;
		

	}
		${({ isMobile }) =>
		!isMobile &&
		css`
			padding-top : 20px;
			border: 1px solid #E5E5E5;
			border-radius: 8px;
			padding: 35px;
			width: 364px;
            height: 353px;
			margin:auto;
		`};

		${({ isMobile }) =>
		isMobile &&
		css`
			.close-icon{    
				padding: 15px;
			}
		`};

		
	`;
const loader = document.getElementById("appLoader");

if (loader) {
	loader.style.display = "none";
}
const SuccessfulOperation: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	let { stage } = useParams<{ stage: string }>()
	let { status } = useParams<{ status: string }>()
	useEffect(() => {
		dispatch({ type: SHOW_HEADER, payload: { show: false} });
	}, []);
	const operationStage: any = {
		pin: {
			statusMessage: "PIN Successfully set!"
		},
		payment: {
			statusMessage: "Payment Successful"
		},
		topup:{
			statusMessage: "Mintpay Topup Successful"
		}
	}
	triggerEvents("MintPay_Status_page", {
        status : status,
        statusMessage : operationStage[stage].statusMessage,
		stage: stage
    });
	const backToWalletHandler = () => {
		history.push("/wallet");
	}

	return <SuccessScreen isMobile={isMobile}>
		<SuccessScreenContent isMobile={isMobile} >
			{<div className="close-icon" onClick={backToWalletHandler} >
				<img src={closeIcon}></img>
			</div>}
			<OperationStatus status={status}
				statusMessage={operationStage[stage].statusMessage}
			></OperationStatus>
		</SuccessScreenContent>
	</SuccessScreen>
};
export default SuccessfulOperation;
