import { Checkbox, Divider, message } from "antd";
import Loader from "components/loader";
import { TMLogo } from "icons/tm-logo";
import React, { useState, useEffect } from "react";
import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import * as Actions from "./action";
import LoginSelector from "./admin-selector";
import { propsToJS } from "__utils/immutable-to-js";
import { useHistory } from "react-router";
import { useDeepCompare } from "hooks/use-deep-memo";

const AdminLoginPage: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [loading, setLoading] = useState<boolean>(false);
	const { registerToken } = propsToJS(useSelector(LoginSelector));

	const getQueryParamsFromString = (search: string) => {
		const queryParams: any = {};
		if (search && search.length) {
			if (search.startsWith("?")) search = search.substring(1);
			search.split("&").forEach((searchParamStr: string) => {
				const searchParam: string[] = searchParamStr.split("=");
				if (searchParam.length === 2) {
					queryParams[searchParam[0]] = searchParam[1];
				}
			});
		}
		return queryParams;
	};
	const { redirect } = getQueryParamsFromString(window.location.search);

	const setSessionStorageItem = (key: string, value: string) => {
		window.sessionStorage.setItem(key, value);
	};

	useEffect(() => {
		return () => {
			dispatch({ type: "RESET_REGISTER_API" });
		};
	}, [dispatch]);

	useEffect(() => {
		if (registerToken && registerToken.token) {
			setLoading(false);
			console.log("loginResponse", registerToken);
			const storageSessionData: any = registerToken;
			setSessionStorageItem(
				"authCreds",
				JSON.stringify(storageSessionData)
			);
			if (redirect) {
				history.push({
					pathname: redirect
				});
			} else {
				history.push({
					pathname: "/admin/partners"
				});
			}
		} else {
			// message.error("Unable to Login");
		}
	}, [useDeepCompare(registerToken)]);

	const onLoginSuccess = (response: any) => {
		if (response && response.accessToken) {
			setLoading(false);
			dispatch({
				type: Actions.POST_LOGIN_USING_GOOGLE_ACCOUNT_API,
				payload: {
					accessToken: response.tokenId
				}
			});
			console.log("registerToken", registerToken);
		} else {
			message.error("Unable to Login");
		}
	};
	const onLoginFailure = (response: any) => {
		setLoading(false);
		console.log(response);
	};

	return (
		<div className="banner-bg" style={{ height: "50rem" }}>
			<div
				id="WelcomePage"
				className="flex-center"
				style={{
					alignItems: "center",
					height: "100%"
				}}
			>
				<div>
					<div className="flex-center">
						<TMLogo style={{ height: "25px", width: "197px" }} />
					</div>
					<br />
					<br />
					<div className="flex-center">
						<h2>Welcome to Admin Page For MintPay</h2>
					</div>
					<Divider />
					<br />
					<div className="flex-center">
						<GoogleLogin
							onRequest={() => setLoading(true)}
							clientId="122731477889-8cilu4vi0b2gmjd6i17cpmlen4324ojc.apps.googleusercontent.com"
							buttonText="Login with Google"
							onSuccess={response => onLoginSuccess(response)}
							onFailure={response => onLoginFailure(response)}
						/>
					</div>

					{loading && <Loader />}
				</div>
			</div>
		</div>
	);
};

export default AdminLoginPage;
