import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";

import {
	Input
} from "antd";
import {
	COLORS,
	Title,
	Button,
	Icon
} from "@turtlemint/mint-ui";
import PinInput from "react-pin-input";
import EyeFilled from '@ant-design/icons/EyeFilled'
import EyeInvisibleFilled from '@ant-design/icons/EyeInvisibleFilled'
import { margin } from "polished";
import { 
	isMobileView,
} from "../../../common/commonService";
import { propsToJS } from "__utils/immutable-to-js";
import pinSelector from "../../../views/mintpay/pin/pin.selector";
import lodash from "lodash";
const isMobile = isMobileView();

interface TmPinProps {
    onChange?: (value: string) => void;
    onComplete?: (value: string) => void;
    length: number;
    isShowHide?: boolean;
    isForgetPin?: boolean;
}


const Pin = styled.div<{ isMobile?: boolean }>`

    width: 267px;
    margin: auto;
    input {
        // border : ${false ? '1px solid red !important': '1px solid rgb(77, 85, 86) !important'};
        border-radius: 4px;
        width: 51px;
        height: 40px;
        margin-right: 11px;
        display: inline-block;
        text-align: center;
    };
        
`;

const PinOptions = styled.div`
    display: flex;
    flex-directionw: row;
    justify-content: space-between;
    padding: 5px;
`;

const ShowPin = styled.div`
    display: flex;
`;

const ForgotPin = styled.div`
`;

const TmPin: React.FC<TmPinProps> = (props: TmPinProps) => {
    const [showPin, setShowPin] = useState<boolean>(false);
    const { resetPinStateFailure, partnerWalletCreateStateFailure} = propsToJS(useSelector(pinSelector));
    const [errorDescription, setErrorDescription] = useState<any>(null);

    const onShowClick = () => {
        setShowPin(!showPin);
    }
    useEffect(()=>{
        console.log("reload page after reset pin hit");
        if(lodash.has(resetPinStateFailure, "data")){
            // console.log("===>",resetPinStateFailure.data.message);
            // setErrorDescription(resetPinStateFailure.data.message);
            console.log("resetPinStateFailure==>",resetPinStateFailure.data.responseData);
        }
        if(lodash.has(resetPinStateFailure, "data.responseData")){
            console.log("resetPinStateFailure==>",resetPinStateFailure.data.responseData);
            setErrorDescription(resetPinStateFailure.data.responseData.errorDescription);
        }else if(lodash.has(partnerWalletCreateStateFailure, "data.responseData")){
            console.log("partnerWalletCreateStateFailure==>",partnerWalletCreateStateFailure.data.responseData);
            setErrorDescription(partnerWalletCreateStateFailure.data.responseData.errorDescription);
        }
    })
    
	return (
        <Pin isMobile={isMobile}>
            <PinInput
                length={props.length}
                focus
                style={{"display" : "flex", "justifyContent" : "space-around", "fontSize": 30}}
                // disabled
                secret = {!showPin}
                type="numeric"
                onChange={props.onComplete}

            />
            <Title text = {errorDescription}
                        size={14}
                        style={{
                            textAlign: 'center',
                            marginBottom: "5px",
                            marginTop: "5px",
                            color: "red",      
                        }}/>
             <PinOptions>
                 {props.isShowHide && <ShowPin onClick={onShowClick}>
                    {!showPin ? <EyeFilled 
                    style={{
                        color: "#00A465",
                        marginRight: "2px"
                    }}/>  :   <EyeInvisibleFilled 
                    style={{
                        color: "#00A465",
                        marginRight: "2px"
                    }}/>}
                    <Title
                        text={!showPin ? "SHOW" : "HIDE"}
                        size={12}
                        weight="normal"
                        style={{
                            color: "#00A465"
                    }}
					/>
                 </ShowPin>}
                 {props.isForgetPin && <ForgotPin>
                    <Title
                        text="Forgot Pin"
                        size={12}
                        weight="normal"
                        style={{
                            color: "#00A465"
                    }}/>
                 </ForgotPin> }
            </PinOptions>
        </Pin>
	);
}

export default TmPin;

