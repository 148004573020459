
import { ofType, Epic } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { post } from "__utils/ajax-wrapper";
import { API_CONSTANTS } from "__utils/api-constants";
import { FluxStandardAction } from "__utils/type";
import { getCookie } from "common/commonService";

import {
	SEND_RESEND_MINTPAY_OTP,
    SEND_RESEND_MINTPAY_OTP_SUCCESS,
    SEND_RESEND_MINTPAY_OTP_FAILURE,
    VALIDATE_MINTPAY_OTP,
    VALIDATE_MINTPAY_OTP_SUCCESS,
    VALIDATE_MINTPAY_OTP_FAILURE
} from "../../../constants/actions";




export const sendMintPayOTP : Epic<FluxStandardAction> = action$ => {
	console.log("");
	
    return action$.pipe(
        ofType(SEND_RESEND_MINTPAY_OTP),
        mergeMap((action) => {
            const payload = action.payload;	
			const externalId = getCookie('dealerUserName') || getCookie("pospUserName");
            const APIURL = (process.env.NODE_ENV == "production" ? '' : 'https://pro.joker.planturtle.com');
			console.log("SEND_RESEND_MINTPAY_OTP APIURL==>",`${APIURL}${API_CONSTANTS.WALLET_API}/${externalId}${API_CONSTANTS.WALLET_OTP}`, payload);
			return post(
				`${APIURL}${API_CONSTANTS.WALLET_API}/${externalId}${API_CONSTANTS.WALLET_OTP}`,
				payload,
				null,
				{ showLoader: true }
			).pipe(
				map(response => {
					if (
						response.status === 200 &&
						response.response.success
					) {
						return {
							type: SEND_RESEND_MINTPAY_OTP_SUCCESS,
							payload: { data: response.response}
						};
					} else {
						return {
							type: SEND_RESEND_MINTPAY_OTP_FAILURE,
							error: { data: response.response}
						};
					}
				}),
				catchError(error =>
					of({ type: SEND_RESEND_MINTPAY_OTP_FAILURE, error : error })
				)
			);
        })

    )
}

export const validateMintPayOTP : Epic<FluxStandardAction> = action$ => {
    return action$.pipe(
        ofType(VALIDATE_MINTPAY_OTP),
        mergeMap((action) => {
            const payload = action.payload;	
            const APIURL = (process.env.NODE_ENV == "production" ? '' : 'https://pro.joker.planturtle.com');
			return post(
				`${APIURL}${API_CONSTANTS.WALLET_API}${API_CONSTANTS.WALLET_OTP}`,
				payload,
				null,
				{ showLoader: true }
			).pipe(
				map(response => {
					if (
						response.status === 200 &&
						response.response.status
					) {
						return {
							type: VALIDATE_MINTPAY_OTP_SUCCESS,
							payload: { data: response.response}
						};
					} else {
						return {
							type: VALIDATE_MINTPAY_OTP_FAILURE,
							error: { data: response.response}
						};
					}
				}),
				catchError(error =>
					of({ type: VALIDATE_MINTPAY_OTP_FAILURE, error : error })
				)
			);
        })

    )
}


//TODO : RESENT OTP


