export enum API_CONSTANTS {
	PEOPLE = "people",
	WALLET_API = "/payment/v1/wallet",
	WALLET_PIN = "/pin",
	WALLET_RESET_PIN = "/reset-pin",
	WALLET_TRANSACTIONS = "/transactions",
	WALLET_OTP = "/otp",
	WALLET_CREDIT = "/credit",
	WALLET_CREDIT_ORDER_STATUS = "/credit/order/status",
	WALLET_FETCH_CREDIT_ORDER = "/credit/order",
	BANK_DETAILS = "/payment/v1/admin/wallet",
	LOGIN_USING_GOOGLE_ACCOUNT_API = "/payment/v1/admin/login",
	LOGOUT_ADMIN_API = "/payment/v1/admin/logout",
	//FOR GET request, getAjax call in ajax-wrapper already appends '/api' to the provided url.
	//This does not happen in other request type. So conditionaly put '/api' in epic files.
	SEARCH_BY_DP_ID = "/payment/v1/admin/wallet/dp-details",
	PAYMENT_SERVICE_CONFIG = "/payment/v1/admin/config",
	INITIATE_QSB_PARTNER = "/payment/v1/admin/trigger-qsb-activity-partner",
	INITIATE_QSB = "/payment/v1/admin/wallet/settlement/initiate",
	GET_QSB_REPORT = "/payment/v1/admin/qsbreport"
}

export const BROKER_CONFIG: string = "/getBrokerConfig?source=fe";
