import {
	getCookie,
	setCookie,
	deleteCookie,
	isDealerFlow,
	fromMintPro,
	fromLifeResults,
	setReferrerInCookie
} from "common/commonService";
import DEALER_CONSTANTS, { GENERAL_CONFIG } from "constants/dealer";
import {
	getSessionStorage,
	clearSessionStorage,
	clearLocalStorage
} from "storage";
import MainStorage from "storage/storage";
import { jumpTo } from "__utils";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const $window: any = window;
const PARTNER: MainStorage = getSessionStorage("partner");
const BROKER_CONFIG: MainStorage = getSessionStorage("brokerConfig");
const domain = window.location.origin;
export const initDefaultThings = () => {
	// handle UTM params
	handleUTMParams();

	if (
		getCookie(DEALER_CONSTANTS.COOKIE_CATEGORY) ===
		DEALER_CONSTANTS.PARTNER_ROLE
	) {
		// If source is `Mint-pro`
		if (getCookie(DEALER_CONSTANTS.SOURCE) === DEALER_CONSTANTS.MINT_PRO) {
			const sessionStorageInstance: MainStorage = getSessionStorage("brokerConfig");
			const brokerConf = sessionStorageInstance.getItem("brokerConf") || {};
			const brokerConfig = {brokerConf : brokerConf};
			const partnerConfInstance: MainStorage = getSessionStorage("partner") || {};
			const partnerConf = partnerConfInstance.get();
			// Cleaning storages
			clearSessionStorage();
			clearLocalStorage();

			BROKER_CONFIG.set({
				...brokerConfig
			});

			PARTNER.set({
				...partnerConf
			})



			deleteCookie("view");
			deleteCookie(DEALER_CONSTANTS.SOURCE);
		} else if (PARTNER && PARTNER.getItem("enteredNormalFlow")) {
			setCookie(
				"dealerUserName",
				PARTNER.getItem("dealerUserName"),
				domain
			);
			setCookie("pospUserName", PARTNER.getItem("pospUserName"), domain);
			setCookie("tenant", PARTNER.getItem("tenant"), domain);
			setCookie("category", PARTNER.getItem("category"), domain);
		} else {
			const domain = window.location.hostname;

			if (domain.includes(".pro")) {
				deleteCookie("dealerUserName");
				deleteCookie("pospUserName");
				deleteCookie("tenant");
				deleteCookie("category");
				deleteCookie("dealerName");
			}
		}
	} else if (PARTNER && PARTNER.getItem("enteredNormalFlow")) {
		setCookie("dealerUserName", PARTNER.getItem("dealerUserName"), domain);
		setCookie("pospUserName", PARTNER.getItem("pospUserName"), domain);
		setCookie("tenant", PARTNER.getItem("tenant"), domain);
		setCookie("category", PARTNER.getItem("category"), domain);
	}

	// LIFE
	// Set referrel cookie
	if (isDealerFlow() && fromMintPro() && !fromLifeResults()) {
		setReferrerInCookie();
	}
};

const handleUTMParams = () => {
	const referrer: string = document.referrer;

	const utmParams = {
		medium: "referral",
		source: referrer
	};

	const mailerUtmParams: MainStorage = getSessionStorage("mailerUtmParams");

	mailerUtmParams.set(utmParams);
};

export const goToPartnerPage = (page: string) => {
	// Cleaning storages
	clearSessionStorage();
	/** handle back to button for react-native app */

	if (
		$window.mpIsApp === true &&
		$window.mpFeatures &&
		$window.mpFeatures.close === true
	) {
		const reactPayload = {
			type: "close",
			// payload: {
			// 	screenUrl: page
			// }
		};

		return $window.postMessage(JSON.stringify(reactPayload),"*");
	} else if (
		$window.__REACT_WEB_VIEW_BRIDGE &&
		typeof $window.__REACT_WEB_VIEW_BRIDGE.postMessage === "function"
	) {
		const reactPayload = {
			type: "close",
			payload: {
				screenUrl: page
			}
		};
		return $window.__REACT_WEB_VIEW_BRIDGE.postMessage(
			JSON.stringify(reactPayload)
		);
	} else {
		// if (referrer && !(referrer.contains('leads') || referrer.contains('vertical-redirect'))) {
		//   $window.location.href = referrer;
		//   return;
		// }
		let url = "";
		if (getCookie("tenant") != undefined && getCookie("tenant") != null) {
			let tenant_for_redirect = getCookie("tenant") as string;
			if (getCookie("tenant") == DEALER_CONSTANTS.TENANT_TURTLEMINT)
				tenant_for_redirect = DEALER_CONSTANTS.TENANT_APP;

			url = GENERAL_CONFIG.mintproURL.replace("$", tenant_for_redirect);
		} else {
			url = GENERAL_CONFIG.mintproURL.replace(
				"$",
				DEALER_CONSTANTS.TENANT_APP
			);
		}

		jumpTo(url + page);
	}
};
