import { merge, fromJS } from "immutable";
import { FluxStandardAction } from "__utils/type";
import { getCookie } from "common/commonService";
import {
	FETCH_BROKER_CONFIG_SUCCESS,
	FETCH_BROKER_CONFIG_ERROR
} from "../constants/actions";

const appBrokerConfigData = {};

const initialState = fromJS(appBrokerConfigData);

export const brokerConfigReducer = (
	state = initialState,
	action: FluxStandardAction
) => {
	const { type, payload = {}, error = {} } = action;
	const { data } = payload;
	const tenant = getCookie("tenant");

	switch (type) {
		case FETCH_BROKER_CONFIG_SUCCESS:
			return merge(state, fromJS({ ...data, tenant: tenant }));
		case FETCH_BROKER_CONFIG_ERROR:
			return merge(state, fromJS({ error }));
		default:
			return state;
	}
};
