import * as React from "react";
import styled, { css } from "styled-components";
import TransactionList from "./transaction-list";
import { Icon, Divider, Input} from "antd";
import {ArrowLeftOutlined, SearchOutlined, CloseOutlined}from '@ant-design/icons'
import { COLORS } from "@turtlemint/mint-ui";
import { 
	isMobileView,
} from "../../../common/commonService";
import { useEffect , useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { propsToJS } from "__utils/immutable-to-js";
import transactionsSelector from "./transactions.selector";
import { GET_ALL_MINTPAY_TRANSACTIONS, GET_MINTPAY_TRANSACTIONS_BY_ID} from "constants/actions";
import { useHistory } from "react-router-dom";
import {
	SHOW_HEADER
} from "constants/actions";
import MintpayTransaction from "./transaction";
import "./index.css";
import { triggerEvents } from "__utils/mixpanel";
import downloadButton from "images/download-button.svg";
const isMobile = isMobileView();

const Transactions = styled.div<{ isMobile?: boolean }>`
		background-color: white;
		height: calc(${document.body.clientHeight + 'px'} - 0px);
		${({ isMobile }) =>
				isMobile ?
				css`
					margin-top : 60px;
				`: css`
					margin-top : 30px;
					padding: 24px;
					padding-bottom: 0px;
					padding-top: 24px;
				`};
	`;

	const Header = styled.div<{ isMobile?: boolean }>`
		margin: 16px;
		${({ isMobile }) =>
				isMobile &&
				css`
					position: relative;
					height: 56px;
					top: 0px;
					width: 100%;
					padding: 16px;
					background: #FFFFFF;
					box-shadow: 0px 1px 4px rgb(0 0 0 / 24%);
					margin: 0px
				`};
	`;

	const TransactionsContent = styled.div<{ isMobile?: boolean }>`
		.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
			border-color: green;
			border-right-width: 1px !important;
		}
		.ant-input:focus{
			border-color: #00A465;
			box-shadow: 0 0 0 0.5px #00A465;
		}
		.ant-input{
			font-size: 15px;
			font-weight: bold;
			background: #F5F5F5;
		}
		${({ isMobile }) =>
				!isMobile &&
				css`
					// border: 1px solid #E5E5E5;
					// border-radius: 8px;
					padding: 15px;
					width: 1210px;
					margin:auto;
				`};
	`

const MintpayTransactions : React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [showSearchIcon, setShowSearchIcon] = useState<boolean>(true);
	const [allTransactionList, setAllTransactionList] = useState<any>([]);
	const { allTransactionsState } = propsToJS(useSelector(transactionsSelector));
	const [landingPageLoaded, setLandingPageLoaded] = useState(0);

	useEffect(() => {
		triggerEvents("MintPay_Transaction_PageLoad", {});
		setLandingPageLoaded(1)
	}, [landingPageLoaded === 0]);

	useEffect(() => {
		dispatch({ type: SHOW_HEADER, payload: { show: true} });
	});
	
	useEffect(() => {
		dispatch({
			type: GET_ALL_MINTPAY_TRANSACTIONS,
			payload: {
					limit : 30,
					offset: 0
			}
		})
	},[dispatch]);


	useEffect(() => {
		if(allTransactionsState && allTransactionsState.walletTransactionList.length > 0){
			setInitialTransactionHandler(allTransactionsState.walletTransactionList);
			setAllTransactionList(allTransactionsState.walletTransactionList)
		}
	},[allTransactionsState]);

	const backToWalletHandler = () => {
		triggerEvents("MintPay_Transaction_BackToWallet", {});
		history.push("/wallet");

	}

	const individualTransactionHandler = (transactionId : string) => {	
		triggerEvents("MintPay_Transaction_GetSpecificTransaction", {
			transactionId: transactionId
		});
			
		dispatch({
			type: GET_MINTPAY_TRANSACTIONS_BY_ID,
			payload: {
					transactionId : transactionId
			}
		})
		isMobile && history.push("/transaction");
	}

	const setInitialTransactionHandler = (allTransactionsList: any) => {
		if (allTransactionsList.length) {
			dispatch({
				type: GET_MINTPAY_TRANSACTIONS_BY_ID,
				payload: {
					transactionId: allTransactionsList[0].walletTransactionId
				}
			})
		}
	}

	const onSearchClick = (searchText: string) => {
		if (searchText.length > 0) {
			let fiterListofTransaction = getMatchTransaction(allTransactionsState, searchText);
			triggerEvents("MintPay_Transaction_Search", {
				searchText: searchText,
				transactionList : fiterListofTransaction,
				isTransactionPresent : fiterListofTransaction.length
			});
			if (fiterListofTransaction.length > 0) {
				// to set initial transaction
				setInitialTransactionHandler(fiterListofTransaction);
				// to set list of transction
				setAllTransactionList(fiterListofTransaction);
			} else {
				// should require to erase data
				setInitialTransactionHandler([]);
				setAllTransactionList([]);
			}
			console.log("filter list of transaction is ", fiterListofTransaction);
		} else {
			// should require to erase data
			setInitialTransactionHandler(allTransactionsState.walletTransactionList);
			setAllTransactionList(allTransactionsState.walletTransactionList);
		}
	  
	}

	const shouldShowSearch = (show: boolean) => {
		setShowSearchIcon(show);
	}

	const getMatchTransaction = (allTransactionsState: any, searchText: string) => {
		let listofTransaction = allTransactionsState.walletTransactionList;
		let fiterListofTransaction = [];
		if (listofTransaction && listofTransaction.length > 0) {
			fiterListofTransaction = listofTransaction.filter((transaction: any) => 
				transaction.description.toLowerCase().includes(searchText.toLowerCase())
			);
		}
		return fiterListofTransaction;
	}
	

	return (
		<div>
			{isMobile ?
				<Transactions isMobile={isMobile}>
					<TransactionsContent isMobile={isMobile}>
						<Header isMobile={isMobile}>
							<div onClick={backToWalletHandler}>
								<ArrowLeftOutlined
									style={{
										fontSize: "20px",
										color: `${COLORS.BLACK}`,
										cursor: "pointer"
									}}>
									</ArrowLeftOutlined>

							</div>
						</Header>
						<div className="transaction-header-wrap">
							<div> Transactions</div>
							<div>
								{/* <img src={downloadButton} alt="loading" style={{ marginRight: 20 }} /> */}
								{showSearchIcon ? <SearchOutlined onClick= { () => shouldShowSearch(false)} style={{ marginRight: 10, fontSize: 17}}  /> : <CloseOutlined onClick= { () => shouldShowSearch(true)} style={{ marginRight: 10, fontSize: 17 }} />}
							</div>
						</div>
					   { !showSearchIcon && <div className="flex-row-align-center">
					   <Input style={{ width: "90%", height: "40px" }} prefix={<SearchOutlined />} allowClear type="text" placeholder="Search by Transaction Description" onChange={(e: any) => onSearchClick(e.target.value)} />
					   </div>}
						
						{allTransactionsState ? <TransactionList showPagination={true} data={allTransactionList} onClick={individualTransactionHandler}></TransactionList> : null}
					</TransactionsContent>

				</Transactions>
				 :
				<Transactions isMobile={isMobile}>
					<TransactionsContent isMobile={isMobile}>
						<Header isMobile={isMobile}>
							<div className="transaction-desk-header-wrap">
								<div onClick={backToWalletHandler}>
									<ArrowLeftOutlined
										style={{
											fontSize: "20px",
											color: `${COLORS.BLACK}`,
											cursor: "pointer"
										}}></ArrowLeftOutlined>
									<span className="header-lable"> Transactions </span>
								</div>
								<div className="flex-row-align-center">
									{/* <img src={downloadButton} alt="loading" style={{ marginRight: 20 }} /> */}
									<div>
										<Input style={{ width: "400px", height:"40px" }} prefix={<SearchOutlined />}  allowClear type="text" placeholder="Search by Transaction description"  onChange={(e: any) => onSearchClick(e.target.value)} />
									</div>
								</div>

							</div>
						</Header>
						<Divider style={{ marginBottom: "0.1rem ", marginTop: "2rem" ,border: "1px solid #D2D5D6"}}/>
						<div className="transaction-view">
						{allTransactionsState ? <TransactionList showPagination={true} data={allTransactionList} onClick={individualTransactionHandler}></TransactionList> : null}
						{allTransactionList.length > 0 && <MintpayTransaction > </MintpayTransaction>}
						</div>

					</TransactionsContent>
				</Transactions>

			}
		</div>
	);
};

export default MintpayTransactions;