import { ajax } from "rxjs/ajax";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { getUserInformation } from "components/admin-login-info";
import { clearLoginSessionStorage } from "components/admin-login-info";
import { useHistory } from "react-router";
import { goToPartnerPage } from "./dealer";

const userInfo = getUserInformation();

const defaultHeaders: any = {
	"Content-Type": "application/json"
};

const getToken = () => {
	const userInfo = getUserInformation();
	return userInfo?.token
}


export const authHeader = () => {
	return {
		"Authorization": getToken()
	}
}
/* This can be externalized in environment file. */
export const baseUrl: string =
	process.env.NODE_ENV === "development"
		? `${process.env.REACT_APP_API_URL}` + "/api"
		: window.location.origin + "/api";


export const postLogout = (url: string, body: any, headers?: any, option?: any) =>
	postLogoutAjaxCall(url, body, headers, option);


const postLogoutAjaxCall = (
	url: string,
	body: any,
	headers: any = {},
	option?: any
) => {
	const loader = document.getElementById("appLoader");
	console.log("url", url);
	loader && option && option.showLoader && (loader.style.display = "block");
    headers = Object.assign({}, authHeader(), headers);

	return ajax({
		url: baseUrl + url,
		method: "POST",
		body,
		headers: {
			...defaultHeaders,
			...headers
		},
		responseType: "json"
	}).pipe(
		map((response: any) => {
			loader && (loader.style.display = "none");
			return response;
		}),
		catchError((error: any) => {
			loader && (loader.style.display = "none");
			console.log("error: ", error);
			// TODO :: redirect to 404 page if set in option.redirection404
            if(error.status === 401 || error.status === 403 || error.status === 500) {
                clearLoginSessionStorage();
                goToPartnerPage("/dashboard");
            } 
			return throwError(error);
		})
	);
};