/* eslint-disable @typescript-eslint/no-explicit-any */
interface IStorageTypes {
	LocalStorage: any;
	SessionStorage: any;
}

const STORAGES: IStorageTypes = {
	LocalStorage: window.localStorage,
	SessionStorage: window.sessionStorage
};

export interface ILocalStorageData {
	[key: string]: any;
}

export default class MainStorage {
	// Can be - LS/Session-Storage
	private _storageType: any;
	private _storage: ILocalStorageData = {};
	private _namespace: string = "";

	constructor(STORAGE_TYPE: keyof IStorageTypes, _namespace: string) {
		this._storageType = STORAGES[STORAGE_TYPE];
		this._namespace = _namespace;

		this.setStorage();
	}

	private setStorage() {
		if (!this._storageType.getItem(this._namespace)) {
			this._storageType.setItem(this._namespace, JSON.stringify({}));
		}

		this._storage = this.getFromStorage();
	}

	public getItem(key: string) {
		return this.getFromStorage()[key] || undefined;
	}

	public setItem(key: string, item: any) {
		this._storage = this.getFromStorage();
		this._storage[key] = item;

		// Update In Storage
		this.updateNameSpaceStorage();
	}

	public removeItem(key: string) {
		const item: any = this.getItem(key);

		if (item) {
			delete this._storage[key];
			this.updateNameSpaceStorage();
		}
	}

	public clear() {
		this._storage = {};
		this.updateNameSpaceStorage();
	}

	public set(data: any) {
		this._storage = data;
		this.updateNameSpaceStorage();
	}

	public get = () => this.getFromStorage();

	private getFromStorage() {
		return { ...JSON.parse(this._storageType.getItem(this._namespace)) };
	}

	private updateNameSpaceStorage() {
		// Update In Storage
		this._storageType.setItem(
			this._namespace,
			JSON.stringify({
				...this._storage
			})
		);
	}
}
