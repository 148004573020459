
import { ofType, Epic } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { get, post } from "__utils/ajax-wrapper";
import { API_CONSTANTS } from "__utils/api-constants";
import { FluxStandardAction } from "__utils/type";
import { getCookie } from "common/commonService";
import {
	GET_ALL_MINTPAY_TRANSACTIONS,
    GET_ALL_MINTPAY_TRANSACTIONS_SUCCESS,
    GET_ALL_MINTPAY_TRANSACTIONS_FAILURE,
    GET_MINTPAY_TRANSACTIONS_BY_ID,
    GET_MINTPAY_TRANSACTIONS_BY_ID_SUCCESS,
    GET_MINTPAY_TRANSACTIONS_BY_ID_FAILURE
} from "../../../constants/actions";


export const getTransactions : Epic<FluxStandardAction> = action$ => {
    return action$.pipe(
        ofType(GET_ALL_MINTPAY_TRANSACTIONS),
        mergeMap((action) => {
			const payload = action.payload;	
			const externalId = getCookie('dealerUserName') || getCookie("pospUserName");
            const APIURL = (process.env.NODE_ENV == "production" ? '' : 'https://pro.joker.planturtle.com/');
			return post(
				`${APIURL}${API_CONSTANTS.WALLET_API}/${externalId}${API_CONSTANTS.WALLET_TRANSACTIONS}`,
				payload,
				null,
				{ showLoader: true }
			).pipe(
				map(response => {
					if (
						response.status === 200 &&
						response.response.success
					) {
						return {
							type: GET_ALL_MINTPAY_TRANSACTIONS_SUCCESS,
							payload: { allTransactionsList: response.response.responseData}
						};
					} else {
						return {
							type: GET_ALL_MINTPAY_TRANSACTIONS_FAILURE,
							error: { data: response.response}
						};
					}
				}),
				catchError(error =>
					of({ type: GET_ALL_MINTPAY_TRANSACTIONS_FAILURE, error : error.response })
				)
			);
        })

    )
}

export const getTransactionsByID : Epic<FluxStandardAction> = action$ => {
    return action$.pipe(
        ofType(GET_MINTPAY_TRANSACTIONS_BY_ID),
        mergeMap((action) => {
			const payload = action.payload;	
			const externalId = getCookie('dealerUserName') || getCookie("pospUserName");
            const APIURL = (process.env.NODE_ENV == "production" ? '' : 'https://pro.joker.planturtle.com/');
			return get(
				`${APIURL}${API_CONSTANTS.WALLET_API}/${externalId}${API_CONSTANTS.WALLET_TRANSACTIONS}/${payload.transactionId}`,
				null,
				{ showLoader: true }
			).pipe(
				map(response => {
					if (
						response.status === 200 &&
						response.response.success
					) {
						return {
							type: GET_MINTPAY_TRANSACTIONS_BY_ID_SUCCESS,
							payload: { singleTransaction: response.response.responseData}
						};
					} else {
						return {
							type: GET_MINTPAY_TRANSACTIONS_BY_ID_FAILURE,
							error: { data: response.response}
						};
					}
				}),
				catchError(error =>
					of({ type: GET_MINTPAY_TRANSACTIONS_BY_ID_FAILURE, error : error.response })
				)
			);
        })

    )
}


