import { STORAGES_ALLOWED } from "constants/storage-keys";
import MainStorage from "./storage";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IDBInstance = { [storageName: string]: InstanceType<any> };
type IStorageInstances = {
	session: IDBInstance;
	local: IDBInstance;
};

const STORAGE_INSTANCES: IStorageInstances = {
	session: {},
	local: {}
};

const getStorage = (
	type: keyof IStorageInstances,
	namespace: STORAGES_ALLOWED | string
): MainStorage => {
	if (!STORAGE_INSTANCES[type][namespace]) {
		let instanceOf;
		switch (type) {
			case "session":
				instanceOf = new MainStorage("SessionStorage", namespace);
				break;

			case "local":
				instanceOf = new MainStorage("LocalStorage", namespace);
				break;
		}

		STORAGE_INSTANCES[type][namespace] = instanceOf;
	}

	return STORAGE_INSTANCES[type][namespace];
};

export const getSessionStorage = (
	sessionStorageName: STORAGES_ALLOWED | string
): MainStorage => {
	return getStorage("session", sessionStorageName);
};

export const clearSessionStorage = () => window.sessionStorage.clear();

export const getLocalStorage = (
	sessionStorageName: STORAGES_ALLOWED
): MainStorage => {
	return getStorage("local", sessionStorageName);
};
export const clearLocalStorage = () => window.localStorage.clear();
