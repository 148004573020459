import {
    SEND_RESEND_MINTPAY_OTP_SUCCESS,
    SEND_RESEND_MINTPAY_OTP_FAILURE,
    VALIDATE_MINTPAY_OTP_SUCCESS,
    VALIDATE_MINTPAY_OTP_FAILURE,
    STORE_MINTPAY_OTP,
    RESET_OTP_AND_SESSION_ID,
    RESET_VALIDATED_MINTPAY_OTP_SUCCESS,
} from "../../../constants/actions";

import { fromJS, merge } from "immutable";
import { FluxStandardAction } from "__utils/type";

export interface mintpayOTPState {
    isFetching: boolean;
    otpState: unknown;
    otpStateFailure: undefined | string;
    validateOtpState : unknown;
    validateOtpStateFailure : undefined  | string;
    userOtp : undefined | Number;
    otpSessionId : undefined | string;
  }
  const rawState: mintpayOTPState = {
    isFetching: false,
    otpState: null,
    otpStateFailure: undefined,
    validateOtpState : null,
    validateOtpStateFailure : undefined,
    userOtp : undefined,
    otpSessionId : undefined
  };
  const intialState = fromJS(rawState);

  export const mintPayOTPReducer = (
    state = intialState,
	action: FluxStandardAction
  ) => {
    switch (action.type) {
      case SEND_RESEND_MINTPAY_OTP_SUCCESS:
        return merge(state, { otpState: action.payload.data, otpSessionId: action.payload.data.responseData.sessionId});
      case SEND_RESEND_MINTPAY_OTP_FAILURE:
        return merge(state, fromJS({ otpStateFailure: action.error }));
      case VALIDATE_MINTPAY_OTP_SUCCESS:
        return merge(state, { validateOtpState: action.payload.data });
      case VALIDATE_MINTPAY_OTP_FAILURE:
        return merge(state, fromJS({ validateOtpStateFailure: action.error }));
      case STORE_MINTPAY_OTP:
        return merge(state, fromJS({ userOtp : action.payload.userOtp }));
      case RESET_OTP_AND_SESSION_ID:
        return merge(state, fromJS({ otpSessionId: null, userOtp : undefined }));
      case RESET_VALIDATED_MINTPAY_OTP_SUCCESS:
        return merge(state, fromJS({validateOtpState: null})); 
      default:
        return state;
    }
  }
  