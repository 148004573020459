/* eslint-disable @typescript-eslint/no-explicit-any */
const analyticsMiddleware = (analyticsInstance: any) => (store: any) => (
	next: any
) => (action: any) => {
	analyticsInstance.on({ action, store });

	const result = next(action);
	return result;
};

export default analyticsMiddleware;

class Analytics {
	constructor() {
		console.table("Analytics - initiated");
	}

	private _eventsBatch: any = [];

	public register = (events: { [action: string]: any }) => {
		this._eventsBatch.push(events);
	};

	public on = ({ action }: any) => {
		this._eventsBatch.forEach((events: any) => {
			if (events[action.type]) {
				const { payload } = action;
				events[action.type]({ payload });
			}
		});
	};
}

export const analyticsInstance = new Analytics();
