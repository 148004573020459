import "./index.css";
import { Card, Col, Descriptions, Row } from "antd";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import styled, { css } from "styled-components";
import { getDateTime } from "__utils/date-format";
import { isMobileView } from "common/commonService";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { COLORS } from "@turtlemint/mint-ui";

const QSBReportDetails: React.FC = (props: any) => {
	const history = useHistory();
	const isMobile = isMobileView();

	const Details = styled.div<{ isMobile?: boolean }>`
		background-color: white;
		height: calc(${document.body.clientHeight + "px"} - 0px);
		${({ isMobile }) =>
			isMobile
				? css`
						margin-top: 60px;
				  `
				: css`
						margin-top: 30px;
						padding: 24px;
						padding-bottom: 0px;
						padding-top: 24px;
						margin-right: 10rem;
						margin-left: 10rem;
				  `};
	`;

	const Header = styled.div<{ isMobile?: boolean }>`
		margin: 16px;
		${({ isMobile }) =>
			isMobile &&
			css`
				position: relative;
				height: 56px;
				top: 0px;
				width: 100%;
				padding: 16px;
				background: #ffffff;
				box-shadow: 0px 1px 4px rgb(0 0 0 / 24%);
				margin: 0px;
			`};
	`;

	return (
		<Details>
			<Header isMobile={isMobile}>
				<div className="transaction-desk-header-wrap">
					<div onClick={() => history.push({
						pathname: "/admin/partners",
						state: "backToAllReports"
					})}>
						<ArrowLeftOutlined
							style={{
								fontSize: "20px",
								color: `${COLORS.BLACK}`,
								cursor: "pointer"
							}}
						></ArrowLeftOutlined>
						<span className="header-lable"> Show All QSB Reports </span>
					</div>
				</div>
			</Header>
			<Card title="QSB Report" className="home" bordered={true}>
				<Descriptions bordered={true} column={1}>
					<Descriptions.Item label="Activity Start Date">{getDateTime(props.location.state?.activityStarted)}</Descriptions.Item>
					<Descriptions.Item label="Eligible Partners Count">{props.location.state?.eligiblePartners}</Descriptions.Item>
					<Descriptions.Item label="Amount outstanding in Mintpay at Initiation">{props.location.state?.walletAmountAtInitiation}</Descriptions.Item>
					<Descriptions.Item label="Accounts Settled">{props.location.state?.accountsSettled}</Descriptions.Item>
					<Descriptions.Item label="Amount Settled">{props.location.state?.amountSettled}</Descriptions.Item>
					<Descriptions.Item label="Transactions In Process">{props.location.state?.transactionsInProgress}</Descriptions.Item>
					<Descriptions.Item label="Amount Still in Process">{props.location.state?.amountInProgress}</Descriptions.Item>
					<Descriptions.Item label="Transactions Failed">{props.location.state?.transactionsFailed}</Descriptions.Item>
					<Descriptions.Item label="Amount Failed">{props.location.state?.amountFailed}</Descriptions.Item>
					<Descriptions.Item label="Outstanding Balance in Mintpay After QSB">{props.location.state?.walletAmountAfterQSB}</Descriptions.Item>
				</Descriptions>
			</Card>
		</Details>
	);
};
export default QSBReportDetails;
