const constantsList: { [key: string]: string } = {};

constantsList.TENANT_TURTLEMINT = "turtlemint";
constantsList.TENANT_APP = "app";
constantsList.TENANT = "tenant";
constantsList.PARTNER_ROLE = "partner";
constantsList.VIEW = "view";
constantsList.ADMIN = "admin";
constantsList.MINT_PRO = "mint-pro";
constantsList.SOURCE = "source";
constantsList.DATA = "data";
constantsList.DASHBOARD_CONSTANT = "dashboard";
constantsList.OPPORTUNITIES_CONSTANT = "leads";
constantsList.CUSTOMER_CONSTANT = "customers";
constantsList.QIS_CUSTOMER_MOBILE = "qisCustomerMobile";
constantsList.QIS_CUSTOMER_EMAIL = "qisCustomerEmail";
constantsList.WEIZMANN = "weizmann";

constantsList.SESSION_STORAGE_NAME_CONSTANT = "ng-dealer";
constantsList.SESSION_STORAGE_OF_LOGIN_PARTNER_CONSTANT = "login";
constantsList.IS_QIS_INITIATED = "isQisInitiated";

constantsList.PROFILE_NAVIGATION = "Setup";
constantsList.QUOTES_NAVIGATION = "Quotes";
constantsList.CHECKOUT_NAVIGATION = "Checkout";
constantsList.PAYMENT_NAVIGATION = "Payment";

constantsList.COOKIE_CATEGORY = "category";
constantsList.BACK_URL = "backUrl";

// Session constants
constantsList.SESSION_COOKIE_NAME = "PLAY_SESSION";
constantsList.SESSION_COOKIE_VALUE =
	"a2d9f51cd5fd4c63128e9f1ae69c2b92c47c4438-loginUser=id%23Uvesh%23%23role%23partner";
constantsList.SESSION_USER_NAME = "uvesh_bakali";
constantsList.SESSION_DEALER_HASH = "dealerHash";
constantsList.SESSION_DEALER_NAME = "dealerName";
constantsList.SESSION_DEALER_USERNAME = "dealerUserName";
constantsList.SESSION_DEALER_DATA =
	'{"userData":{"status":"SUCCESS","action":"authenticate","userName":"Uvesh","category":"partner","_id":"Uvesh","mobileNo":"9664022796"},"validationMsgs":{"userExists":false,"dob":false},"emailOrMobile":"garima.z@turtlemint.com","email":"garima.z@turtlemint.com","mobileNo":""}';

constantsList.TENANT_TURTLEMINT = "turtlemint";
constantsList.TENANT_APP = "app";

export default constantsList;

// Dealer stuff ----------
const brokers = ["turtlemint", "stage"]; //This list used only for testing ENV for broker
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const $window: any = window;
let domain_without_www_pro: string | string[] = $window.location.host;

if (
	$window.location.host.split(".")[0].indexOf("www") != -1 ||
	$window.location.host.split(".")[0].indexOf("pro") != -1
) {
	domain_without_www_pro = $window.location.host.split(".");
	(domain_without_www_pro as string[]).splice(0, 1);
	domain_without_www_pro = (domain_without_www_pro as string[]).join(".");
}
let feature = (domain_without_www_pro as string).split(".")[1];
if (brokers.includes(feature)) {
	feature = "broker";
}

const temp = (domain_without_www_pro as string).split(".");
if (temp.includes("stage")) {
	feature = temp[temp.indexOf("stage") - 1];
}

let mintproURL =
	window.location.protocol + "//$." + feature + ".stage.mintpro.in";

// For prod handling
if (
	$window.location.host.indexOf("planturtle") == -1 &&
	$window.location.host.indexOf("planmycash") == -1 &&
	!brokers.includes($window.location.host.split(".")[0])
) {
	mintproURL = $window.location.protocol + "//$.mintpro.in";
}

export const GENERAL_CONFIG = {
	mintproURL
};
