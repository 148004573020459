import * as React from "react";
import {
	COLORS,
	Title
} from "@turtlemint/mint-ui";
import {
	List, Avatar
} from "antd";
import styled,{ css }  from "styled-components";
import { 
	isMobileView,
	filterINR
} from "../../../../common/commonService";
import {getDateTime} from "__utils/date-format";
import { triggerEvents } from "__utils/mixpanel";
import { useEffect , useState} from "react";



interface TmTransactionDetailProps {
    data: any,
	onPlicyClick?: (index: number) => void
}

 const TransactionDetailBox = styled.div`
        border: 1px solid #A1A6A7;
        box-sizing: border-box;
        border-radius: 8px;
        margin-left: 33px;
        margin-right: 33px;
        padding: 12px;
	`;

    const OtherDeatials = styled.div`
        font-size : 14px;
        color : #121617;
        li {
            margin-bottom : 10px;
        }
    `;

const TransactionDetail : React.FC<TmTransactionDetailProps> = (props:TmTransactionDetailProps) => {
	const [landingPageLoaded, setLandingPageLoaded] = useState(0);
    useEffect(() => {
		triggerEvents("MintPay_Transaction_Detail_view", {
            transactionDetail : props.data
        });
		setLandingPageLoaded(1)
	}, [landingPageLoaded === 0]);
    return (
        <TransactionDetailBox className="transaction-detail">
				<Title
					text={props.data.title || ''}
					size={18}
					weight="bold"
                    color={COLORS.BLACK}
					style={{
                        marginBottom: "8px",
                        fontSize: "18px"
                    }}
					/>
                    {props.data.subTitle ? 
                    (<Title
                        text={props.data.subTitle || ''}
                        size={12}
                        weight="normal"
                        style={{
                            marginBottom: "24px",
                            color: "#A1A6A7"
                            
                        }}
					/>) : ''}
               <OtherDeatials>
                   <ul>
                       <li>Date : {getDateTime(props.data.date, "DATE")}</li>
                       <li>Time : {getDateTime(props.data.date, "TIME")}</li>
                       <li>TransactionId : {props.data.walletTransactionId}</li>
                       <li>Remark : <b>{props.data.userRemark ? props.data.userRemark['Description'] : "No Remark"}</b></li>
                   </ul>

               </OtherDeatials>
		</TransactionDetailBox>
    )

}

export default React.memo(TransactionDetail);