import {
    PARTNER_WALLET_CREATE_WITH_PIN_SUCCESS,
    PARTNER_WALLET_CREATE_WITH_PIN_FAILURE,
    VALIDATE_PIN_SUCCESS,
    VALIDATE_PIN_FAILURE,
    RESET_PIN_SUCCESS,
    RESET_PIN_FAILURE,
    PIN_STAGE,
    CLEAR_RESET_PIN_STATE
} from "../../../constants/actions";

import { fromJS, merge } from "immutable";
import { FluxStandardAction } from "__utils/type";

export interface pinState {
    isFetching: boolean;
    partnerWalletCreateState: unknown;
    partnerWalletCreateStateFailure: undefined | string;
    validatePinState : unknown;
    validatePinStateFailure : undefined  | string;
    resetPinState : unknown;
    resetPinStateFailure : undefined  | string;
    pinStage : string;
  }
  const rawState: pinState = {
    isFetching: false,
    partnerWalletCreateState: null,
    partnerWalletCreateStateFailure: undefined,
    validatePinState : null,
    validatePinStateFailure : undefined,
    resetPinState : null,
    resetPinStateFailure : undefined,
    pinStage : "SET"
  };
  const intialState = fromJS(rawState);

  export const pinReducer = (
    state = intialState,
	action: FluxStandardAction
  ) => {
    switch (action.type) {
      case PIN_STAGE : 
        return merge(state, { pinStage: action.payload.stage });
      case PARTNER_WALLET_CREATE_WITH_PIN_SUCCESS:
        return merge(state, { partnerWalletCreateState: action.payload.data });
      case PARTNER_WALLET_CREATE_WITH_PIN_FAILURE:
        return merge(state, fromJS({ partnerWalletCreateStateFailure: action.error }));
      case VALIDATE_PIN_SUCCESS:
        return merge(state, { validatePinState: action.payload.data });
      case VALIDATE_PIN_FAILURE:
        return merge(state, fromJS({ validatePinStateFailure: action.error }));
      case RESET_PIN_SUCCESS:
        return merge(state, { resetPinState: action.payload.data });
      case RESET_PIN_FAILURE:
        console.log("reducer RESET_PIN_FAILURE=>",action.error);
        return merge(state, fromJS({ resetPinStateFailure: action.error }));
      case CLEAR_RESET_PIN_STATE:
        return merge(state, fromJS({ resetPinState : null}));
      default:
        return state;
    }
  }
  