import React from "react";
import '../features.css'
import MintpayFeatureOne from "../../assets/images/MintPay-Intro1.svg"
import MintpayFeatureTwo from "../../assets/images/MintPay-Intro2.svg"
import MintpayFeatureThree from "../../assets/images/MintPay-Intro3.svg"

const MintpayFeatures : React.FC= () => {

    return (
        <div className="mintpay-features">
            <div className="feature-section">
                <img src={ MintpayFeatureOne} alt="feature1"/>
                <div className="feature-description">
                    <div className="title">Convenient</div>
                    <p>Issue policies rapidly by collecting money in any mode</p>
                </div>
            </div>
            <div className="feature-section">
                <img src={ MintpayFeatureTwo} alt="feature2"/>
                <div className="feature-description">
                    <div className="title">Reliable</div>
                    <p>Avoid payment failures with safe, secure and efficient Mintpay</p>
                </div>
            </div>
            <div className="feature-section-bottom">
                <img src={ MintpayFeatureThree} alt="feature3"/>
                <div className="feature-description">
                    <div className="title">Swift</div>
                    <p>Buy Policies with just one click</p>
                </div>
            </div>
        </div>

    )

};
export default React.memo(MintpayFeatures);