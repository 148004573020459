/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from "reselect";
// selector

const getTransactionsIsFetching = (state: any) =>
	state.getIn(["partnerDetailsReducer", "isFetching"]);

const bankDetails = (state: any) =>
	state.getIn(["partnerDetailsReducer", "bankDetails"]);

const bankDetailsError = (state: any) =>
	state.getIn(["partnerDetailsReducer", "bankDetailsError"]);

const getAllTransactionsData = (state: any) =>
	state.getIn(["partnerDetailsReducer", "allTransactionsState"]);
const getAllTransactionsError = (state: any) =>
	state.getIn(["partnerDetailsReducer", "allTransactionsStateFailure"]);

const initiateQSBPartnerSuccess = (state: any) =>
	state.getIn(["partnerDetailsReducer", "initiateQSBPartnerSuccess"]);
const initiateQSBPartnerRepeat = (state: any) =>
	state.getIn(["partnerDetailsReducer", "initiateQSBPartnerRepeat"]);
const initiateQSBPartnerUnauthorised = (state: any) =>
	state.getIn(["partnerDetailsReducer", "initiateQSBPartnerUnauthorised"]);
const initiateQSBPartnerError = (state: any) =>
	state.getIn(["partnerDetailsReducer", "initiateQSBPartnerError"]);

const partnerDetailsSelector = createSelector(
	[
		getAllTransactionsData,
		getAllTransactionsError,
		getTransactionsIsFetching,
		bankDetails,
		bankDetailsError,
		initiateQSBPartnerSuccess,
		initiateQSBPartnerRepeat,
		initiateQSBPartnerUnauthorised,
		initiateQSBPartnerError
	],
	(
		allTransactionsState,
		allTransactionsStateFailure,
		isFetching,
		bankDetails,
		bankDetailsError,
		initiateQSBPartnerSuccess,
		initiateQSBPartnerRepeat,
		initiateQSBPartnerUnauthorised,
		initiateQSBPartnerError
	) => ({
		allTransactionsState,
		allTransactionsStateFailure,
		isFetching,
		bankDetails,
		bankDetailsError,
		initiateQSBPartnerSuccess,
		initiateQSBPartnerRepeat,
		initiateQSBPartnerUnauthorised,
		initiateQSBPartnerError
	})
);

export default partnerDetailsSelector;
