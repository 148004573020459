import * as React from "react";
import {
	Title
} from "@turtlemint/mint-ui";
import styled from "styled-components";

interface TmMintPayCreditDescriptionProps {
    title: string,
    subTitle : string
}

const CreditMoneyDescription : React.FC<TmMintPayCreditDescriptionProps> = (props:TmMintPayCreditDescriptionProps) => {


    const AddMoneyDesc = styled.div`
        text-align: center;
    `;
    return (
        <AddMoneyDesc className="add-money-description">
            <Title
					text={props.title || ''}
					size={18}
					weight="bold"
					style={{
                        marginBottom: "8px"
                    }}
					/>
                    {props.subTitle ? 
                    (<Title
                        text={props.subTitle || ''}
                        size={14}
                        weight="normal"
                        style={{
                            marginBottom: "24px",
                            color: "#4D5556"
                            
                        }}
					/>) : ''}
           
        </AddMoneyDesc>
    )

}

export default React.memo(CreditMoneyDescription);