import * as React from "react";
import {
	COLORS,
	Title,
} from "@turtlemint/mint-ui";
import {
	Collapse,
} from "antd";
import { 
	isMobileView
} from "../../../../common/commonService";
import PlusOutlined from '@ant-design/icons/PlusOutlined'
import MinusOutlined from '@ant-design/icons/MinusOutlined'
import { useState } from "react";
import styled from "styled-components";
import { triggerEvents } from "__utils/mixpanel";



const isMobile = isMobileView();
const { Panel } = Collapse;


interface TmFaqProps {
    data: any
}

const FaqSection = styled.div<{ isMobile?: boolean }>`

		.see-more {
			font-size: 16px;
			color: #00A465;
			cursor: pointer;
			font-weight: 500;
			margin-top: 5px;
		}
		.ant-collapse {
			border-radius: 8px;
		}
	`
const FAQs : React.FC<TmFaqProps> = (props:TmFaqProps) => {
	const [showMore, setShowMore] = useState(false);


    const customExpandIcon = (props: any) =>{
		if (props.isActive) {
		return  <MinusOutlined/>
		} else {
		return (
			<PlusOutlined/>
		);
		}
	}

	const seeMoreContent = (showMore: boolean) => {
		let data = props.data;
		if(!showMore){
			data = props.data.slice(0,3)
		}
		return <Collapse 
		defaultActiveKey = {["0"]}
		expandIconPosition = "right"
		expandIcon = {(props: any) => customExpandIcon(props)}
		style={{
			background: "tranparent"
		}}
		>
		{data.map((data : any, index: number) =>  ( <Panel header={data.title} key={index} >
			<div>{data.text}</div> 
		</Panel>)
		)}
		</Collapse>

	}

	const showMoreFaqs = (showMore: boolean) => {
		triggerEvents("MintPay_Wallet_ShowMoreFaqs", {
			showMore : showMore
		});
		setShowMore(!showMore);
	}

	
    return (
        <FaqSection isMobile={isMobile}>
            
		 	<Title
					text="Frequently Asked Questions"
					size={isMobile ? 16 : 20}
					weight="bold"
					color={COLORS.BLACK}
					style={{
						marginBottom: "16px"
					}}
					/>
			{seeMoreContent(showMore)}
			<div className="see-more" onClick={() => showMoreFaqs(showMore)}>{!showMore ? 'See More' : 'See Less' }</div>
        </FaqSection>
    )

}

export default React.memo(FAQs);