import { Epic, ofType } from "redux-observable";
import { of } from "rxjs";
import { AjaxError, AjaxResponse } from "rxjs/ajax";
import { catchError, map, mergeMap } from "rxjs/operators";
import { post } from "__utils/ajax-wrapper";
import { API_CONSTANTS } from "__utils/api-constants";
import { FluxStandardAction } from "__utils/type";
import * as Actions from "./action";

export const getLoginAPIEpic: Epic<FluxStandardAction> = action$ => {
	// https://pay.spectre.turtle-feature.com
	return action$.pipe(
		ofType(Actions.POST_LOGIN_USING_GOOGLE_ACCOUNT_API),
		mergeMap(action => {
			console.log("process.env.NODE_ENV ", process.env.NODE_ENV);
			return post(
				`${API_CONSTANTS.LOGIN_USING_GOOGLE_ACCOUNT_API}`,
				{},
				{
					Authorization: action.payload.accessToken,
					"X-Auth-Token": action.payload.accessToken
				}
			).pipe(
				map(
					(
						response: AjaxResponse | AjaxError
					): FluxStandardAction => {
						if (response.status === 200) {
							return {
								type:
									Actions.POST_LOGIN_USING_GOOGLE_ACCOUNT_API_SUCCESS,
								payload: { data: response.response }
							};
						} else {
							return {
								type:
									Actions.POST_LOGIN_USING_GOOGLE_ACCOUNT_API_ERROR,
								error: { data: response.response }
							};
						}
					}
				),
				catchError(error =>
					of({
						type: Actions.POST_LOGIN_USING_GOOGLE_ACCOUNT_API_ERROR,
						error: error
					})
				)
			);
		})
	);
};
