import { Card } from "antd";
import React from "react";
import styled from "styled-components";

const BankDetails = ({bankDetails}: any) => {

	const Bank = styled.div`
		text-align: center;
	`;
	console.log("bank", bankDetails);
	

	return (
		<Card title="Bank Account Details" className="home">
			<Bank>
				<div>Account Name : {bankDetails?.name}</div>
				<div>Account Number : {bankDetails?.accountNo}</div>
				<div>Account Name : {bankDetails?.bankName}</div>
				<div>IFSC Code: {bankDetails?.ifsccode}</div>
			</Bank>
		</Card>
	);
};

export default BankDetails;
