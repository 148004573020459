import React, { useEffect, useState } from "react";
import "../wallet/wallet.css"
import './features.css'
import styled,{ css }  from "styled-components";
import MintpayDesktopBckShad from "../assets/images/mintpay-bck-shadow-desktop.svg";
import MintpayDesktopBck from "../assets/images/mintpay-bck-desktop.svg";
import MintpayExpertDesktop from "../assets/images/mintpay-ask-an-expert.svg";
import MintpayIntroBack from "../assets/images/mobile-intro-bck.svg";
import MintIntroImg from "../assets/images/mintpay-intro-img.svg";
import WalletIntro from "../wallet/howItWorks"
import MintpayFeatures from "./features"

import { 
	isMobileView,
	getCookie
} from "../../../common/commonService";
import { COLORS, Title, Button} from "@turtlemint/mint-ui";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import WalletSelector from "../wallet/wallet.selector";
import {
	SHOW_HEADER,
	PARTNER_WALLET_DETAIL
} from "constants/actions";
import { triggerEvents } from "__utils/mixpanel";
import { propsToJS } from "__utils/immutable-to-js";


const isMobile = isMobileView();

const MintpayIntro : React.FC= () => {
	const history = useHistory();

	const loader = document.getElementById("appLoader");
	const { partnerWalletState, isFetching, isOnBoarding } = propsToJS(useSelector(WalletSelector));

	const dispatch = useDispatch();

	const [landingPageLoaded, setLandingPageLoaded] = useState(0);
	

	useEffect(() => {
		triggerEvents("MintPay_Intro_PageLoad", {});
		setLandingPageLoaded(1)
	}, [landingPageLoaded === 0]);

	useEffect(() => {
		dispatch({
			type: PARTNER_WALLET_DETAIL,
			payload: {
					externalId : getCookie('dealerUserName') || getCookie("pospUserName")
			}
		})
	},[dispatch]);

	useEffect(() => {
		if(partnerWalletState && !partnerWalletState.mintpayEnabled){
			history.push("/maintenance");
		} else if(partnerWalletState && partnerWalletState.accountExist){
			history.push("/wallet");
		}
	}, [partnerWalletState])

	if(loader){
		loader.style.display = "none";
	}
	useEffect(() => {
		dispatch({ type: SHOW_HEADER, payload: { show: true} });
	}, []);
	const WalletPage = styled.div<{ isMobile?: boolean }>`
		margin-top: 60px;
		position: relative;
		background-color: white;
		height: 100%;
		.no-transaction-msg{
			font-size: 14px;
			.title {
				margin: 0px 16px;
				color: #121617;
			}
			.description {
				margin: 0px 16px;
				color: #A1A6A7
				margin-bottom : 32px;
			}
		}
		${({ isMobile }) =>
		isMobile &&
		css`
			height : 100%
		`};
	`
	const WalletDetail = styled.div`
        .wallet-balance-background-desktop {
            .background-shadow{
                width: 816px;
                z-index: 1;
                float: left;
                position: absolute;
            }
            .background{
                width: 783px;
                z-index: 2;
            }
        }
        .wallet-faq-desktop {
			float: right;
    		margin-top: -110px;
		}
    `;
	const WalletBalance = styled.div<{ isMobile?: boolean }>`
		position: absolute;
    	top: 0px;
        margin-top:22px;
        margin-bottom:18px;
        padding:16px;
        z-index:1;
        ${({ isMobile }) =>
		!isMobile &&
		css`
			margin-left: 129px;
            padding: 0px;
		`};
	`;
	const Container = styled.div<{ isMobile?: boolean }>`
		width: 1250px;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
		margin-top: 100px;
		${({ isMobile }) =>
		isMobile &&
		css`
			width: 100%;
			padding-right: 0px;
			padding-left: 0px;
			margin-top: 0px;
		`};
	`;
	const Section =  styled.div<{ isMobile?: boolean }>`
	   	display: flex;
		flex-direction:  ${({ isMobile }) => (isMobile ? `column` : `row`)};;
	`;
	
	
	const handleOnAccountSetup  = () => {
		triggerEvents("MintPay_Intro_AccountSetUp", {});
		history.push("/wallet");
	}
	let actionObj: any = {
		text: "Setup Mintpay for free",
		onClickHandler : handleOnAccountSetup
	}
	return (
	 <WalletPage isMobile={isMobile}>
		<WalletDetail>
            {
			 isMobile ? <img src={MintpayIntroBack} alt="Snow" /> :
			 (
			 <div className="wallet-balance-background-desktop">
				 <img className="background-shadow" src={MintpayDesktopBckShad} alt="Snow"/>
				 <img className="background" src={ MintpayDesktopBck} alt="Snow"/>
		     </div>
			 )
		   }
           {
                isMobile ? '' :
                (
                <div className="wallet-faq-desktop">
                    <img className="background-shadow" src={MintpayExpertDesktop} alt="Snow" />
                </div>
                )
			}
           <WalletBalance isMobile={isMobile} style={{ marginTop: isMobile ? "0px" : "22px" }}>
                <Title
                        text="Mintpay"
                        size={28}
                        weight="bold"
                        color={COLORS.WHITE}
                        />
                <Title
                        text={'Payments made easy!'}
                        size={16}
                        weight="bold"
                        color={COLORS.WHITE}
                        />
		 </WalletBalance>
		 {!isMobile ? <img  style={{ position: "absolute",
		 marginLeft: "300px",
		 top: "22px" 
		}} src={MintIntroImg} alt="Snow"/> : null}

        </WalletDetail>
		 <Container isMobile={isMobile}>
			<Section isMobile={isMobile}>
			{!isMobile ?<WalletIntro isShowAction={true} actionObj={actionObj}></WalletIntro> : ""}
				{!isMobile ? (<div className="mintpay-feature-desktop">
					<div className="title">Why Mintpay?</div>
					<div className="feature-box">
						<MintpayFeatures></MintpayFeatures>
					</div>
					</div>) : (<div className="mintpay-feature-Mobile">
					<span className="heading">
						<h2><span>Why Mintpay?</span></h2>
					</span>
					<MintpayFeatures></MintpayFeatures>
					</div>)}	
			</Section>
		 </Container>
		 {isMobile ? (
			 <div className="setup-mintpay">
				<Button
							title={"Setup Mintpay for free"}
							btnStyle="primary"
							btnType ="solid"
							size="small"
							onClick={handleOnAccountSetup}
							style={{
								cursor: "pointer",
								width: "100%",
								minHeight: "48px",
								padding: "15px",
								fontSize: "14px",
								background: "#00A465",
								borderRadius: "4px",
							}}
						/>	
			 </div>
		 ) : ""}
     </WalletPage>
	);
};

export default React.memo(MintpayIntro);