import * as React from "react";

import { isMobileView} from "../../../../common/commonService";
import {Title} from "@turtlemint/mint-ui";
import styled from "styled-components";
import desktopSuccessful from "images/desktop-successful.svg";
import mobileSuccessful from "images/mobile-successful.svg";
import failureIcon from "../../assets/images/mintpay-failure-icon.svg"
import mobileProcessing from "../../assets/images/mintpay-processing-icon-mobile.svg"
import desktopProcessing from "../../assets/images/mintpay-processing-icon-desktop.svg"
 
const isMobile = isMobileView();


interface TmOperationStatusProps {
    status: string
    statusMessage : string
}

const OperationStatus: React.FC<TmOperationStatusProps> = (props: TmOperationStatusProps) => {


    const PinDesc = styled.div`
        text-align: center;
    `;

    const getImage = (status: string) => {
        if(status === 'success') {

            if(!isMobile){

                return (<div style={{ paddingTop: "30px" }} >
                <img src={desktopSuccessful}></img>
            </div>)

            } else {
            return (<div style={{ paddingTop: "100px" }} >
                <img src={mobileSuccessful}></img>
            </div>)
            }

        } else if(status === 'failure') {
            return (<div>
                <img src={failureIcon} style={{
                    color:"red",
                    width: "40px",
                    height: "40px",
                    }}></img>
            </div>)

        } else if( status === 'processing') {
            if(!isMobile){

                return (<div style={{ paddingTop: "30px" }} >
                <img src={desktopProcessing}></img>
            </div>)

            } else {
            return (<div style={{ paddingTop: "100px" }} >
                <img src={mobileProcessing}></img>
            </div>)
            }
        }
    }
    return (
        <PinDesc>
            {getImage(props.status)}
            <Title
                text={props.statusMessage || ''}
                size={18}
                weight="bold"
                style={{
                    color: isMobile ? "#FFFFFF" : "#00A465",
                    marginTop: "15px"
                }}
            />
        </PinDesc>
    )

}

export default React.memo(OperationStatus);