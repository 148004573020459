
import { ofType, Epic } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { get } from "__utils/ajax-wrapper";
import { API_CONSTANTS } from "__utils/api-constants";
import { FluxStandardAction } from "__utils/type";
import {
	PARTNER_WALLET_DETAIL,
    PARTNER_WALLET_DETAIL_SUCCESS,
    PARTNER_WALLET_DETAIL_FAILURE
} from "../../../constants/actions";



export const partnerWalletInfo : Epic<FluxStandardAction> = action$ => {
    return action$.pipe(
        ofType(PARTNER_WALLET_DETAIL),
        mergeMap((action) => {
            const { externalId } = action.payload;	
            const APIURL = (process.env.NODE_ENV == "production" ? '' : 'https://pro.joker.planturtle.com');
			return get(
				`${APIURL}${API_CONSTANTS.WALLET_API}/${externalId}`,
				null,
				{ showLoader: true }
			).pipe(
				map(response => {
					if (
						response.status === 200 &&
						response.response.success
					) {
						return {
							type: PARTNER_WALLET_DETAIL_SUCCESS,
							payload: { data: response.response}
						};
					} else {
						return {
							type: PARTNER_WALLET_DETAIL_FAILURE,
							error: { data: response.response}
						};
					}
				}),
				catchError(error =>
					of({ type: PARTNER_WALLET_DETAIL_FAILURE, error : error })
				)
			);
        })

    )
}

export default partnerWalletInfo;