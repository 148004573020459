import React from "react";	
import "../maintenance.css"	

import DividerLine from "../../assets/images/divider-line.svg";	

const MaintenanceNotification: React.FC = () => {	
    return (	
        <div>	
            <div>	
                <h1 style={{ margin: "0px", fontWeight: 750, fontSize: "36px" }}>Hang On!</h1>	
                <h2 style={{ color: "#494949", fontWeight: "bold" }}>Mintpay is under maintenance</h2>	
            </div>	
            <div className="description">	
                As a part of regular maintenance activity, Mintpay is unavailable at the moment.	
                </div>	
            <div>	
                <img src={DividerLine} alt="" />	
            </div>	
            <div className="description">	
                <p><span className="consolation-note">Don't worry</span>, we will be back shortly!</p>	
            </div>	
        </div>	
    );	
};	
export default React.memo(MaintenanceNotification);