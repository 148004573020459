import {
    CREATE_CREDIT_ORDER_SUCCESS,
    CREATE_CREDIT_ORDER_FAILURE,
    RESET_CREATE_CREDIT_ORDER,
    GET_CREDIT_ORDER_STATUS_SUCCESS,
    GET_CREDIT_ORDER_STATUS_FAILURE,
} from "../../../constants/actions";

import { fromJS, merge } from "immutable";
import { FluxStandardAction } from "__utils/type";

export interface creditMintpayState {
    isFetching: boolean;
    creditState: unknown;
    creditStateFailure: undefined | string;
    fetchCreditOrderState : unknown;
    fetchCreditOrderStateFailure : undefined  | string;
    creditOrderStatus: unknown;
    creditOrderStatusFailure : undefined | string;
  }
  const rawState: creditMintpayState = {
    isFetching: false,
    creditState: null,
    creditStateFailure: undefined,
    fetchCreditOrderState : null,
    fetchCreditOrderStateFailure : undefined,
    creditOrderStatus: null,
    creditOrderStatusFailure: undefined,


  };
  const intialState = fromJS(rawState);

  export const creditMintpayReducer = (
    state = intialState,
	action: FluxStandardAction
  ) => {
    switch (action.type) {
      case CREATE_CREDIT_ORDER_SUCCESS:
        return merge(state, { creditState: action.payload.data.responseData });
      case CREATE_CREDIT_ORDER_FAILURE:
        return merge(state, fromJS({ creditStateFailure: action.error }));
      case RESET_CREATE_CREDIT_ORDER:
        return merge(state, fromJS({ creditStateFailure: undefined, creditState:undefined}));
      case GET_CREDIT_ORDER_STATUS_SUCCESS:
        return merge(state, { creditOrderStatus: action.payload.data.responseData });
      case GET_CREDIT_ORDER_STATUS_FAILURE:
        return merge(state, fromJS({ creditOrderStatusFailure: action.error }));
      default:
        return state;
    }
  }
  