import { CREATE_CREDIT_ORDER, RESET_CREATE_CREDIT_ORDER} from "constants/actions";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css }from "styled-components";
import CreditMoneyDescription from  "./credit-description"
import PaymentComponent from '@turtlemint/payment-gateway-v2-react'
import {
	Input
} from "antd";
import {
	Button,
	COLORS,
	Icon
} from "@turtlemint/mint-ui";
import { 
	isMobileView,
	getCookie
} from "../../../common/commonService";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { propsToJS } from "__utils/immutable-to-js";
import creditMintpaySelector from "./credit.selector";
import WalletSelector from "../wallet/wallet.selector";
import DEALER_CONSTANTS from "constants/dealer";
import { jumpTo } from "__utils";
import {
	PARTNER_WALLET_DETAIL
} from "./../../../constants/actions";
import { triggerEvents } from "__utils/mixpanel";


//const PaymentComponent = require('@turtlemint/payment-gateway');
const isMobile = isMobileView();
const MAX_AMOUNT_LIMIT = 1000000;
const AddMoney = styled.div<{ isMobile?: boolean }>`
margin-top: 60px;
position: relative;
padding-top:24px;
background-color: white;
height: calc(${document.body.clientHeight + 'px'} - ${isMobile ? '108px' : '60px'});
.add-money-description { 
	margin-top: 34px;
	margin-bottom: 35px;
}
.close-button {
	cursor: pointer;
	text-align: right;
}

${({ isMobile }) =>
isMobile &&
css`
	.close-button {
		margin: 16px;
		text-align: left;
	}	
`};
`;

const AddMoneyContent = styled.div<{ isMobile?: boolean }>`
${({ isMobile }) =>
	!isMobile &&
	css`
		border: 1px solid #E5E5E5;
		border-radius: 8px;
		padding: 30px;
		width: 30%
		margin:auto;
	`};
`;

const Amount = styled.div<{ isMobile?: boolean }>`
width: 50%;
margin: auto;
.ant-input {
	border: none;
	font-size: 36px;
	font-weight: bold;
}
.ant-input-prefix {
	font-size: 36px;
	color: ${COLORS.BLACK};
	font-weight: "bold";
}
.ant-input-affix-wrapper {
	text-align: center;
}
`;

const Remark = styled.div<{ isMobile?: boolean }>`
width: 70%;
margin: auto;
margin-top:24px;
.ant-input {
	border: none;
	font-size: 14px;
	background: #F5F5F5;
	border-radius: 4px;
	text-align: center;
}
`;

const SubmitPin = styled.div<{ isMobile?: boolean }>`
 width: 70%;
 margin: auto;
.credit-amount {
	width: 100%;
	margin-top: 24px;
}
${({ isMobile }) =>
isMobile &&
css`
width: 100%;
.credit-amount {
	position: fixed;
	bottom: 0;
	width: 100%;
	border: none;
	border-radius: 0px;
}	
`};
`;

const MintpayAddMoney : React.FC = () => {
	const dispatch = useDispatch();

	const [currentWalletBalance, setcurrentWalletBalance] = useState<string | undefined>("0");
	const [amount, setAmount] = useState<string | undefined>(undefined);
	const [remark, setRemark] = useState<string | undefined>(undefined);
	const [showPaymentGateway, setShowPaymentGateway] = useState(false);
	const [encryptedId, setEncryptedId] = useState(undefined);
	const history = useHistory();
	const getCreditOrderCreateData = useSelector(creditMintpaySelector);
	const { partnerWalletState} = propsToJS(useSelector(WalletSelector));

	const {creditState, creditStateFailure} = propsToJS(getCreditOrderCreateData);

	const [landingPageLoaded, setLandingPageLoaded] = useState(0);

	useEffect(() => {
		dispatch({type:RESET_CREATE_CREDIT_ORDER});
	}, []);

	useEffect(() => {
		triggerEvents("MintPay_Credit_PageLoad", {});
		setLandingPageLoaded(1);
	}, [landingPageLoaded === 0]);

	useEffect(() => {
		setShowPaymentGateway(false);
		dispatch({
			type: PARTNER_WALLET_DETAIL,
			payload: {
					externalId : getCookie('dealerUserName') || getCookie("pospUserName")
			}
		})
	},[dispatch]);

	useEffect(() => {
		if(partnerWalletState && partnerWalletState.walletBalance){
			setcurrentWalletBalance(partnerWalletState.walletBalance);
		}

	},[partnerWalletState]);

	const amountChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		triggerEvents("MintPay_Credit_OnAmountChange", {});
		const reg = /^[1-9]([0-9]+)?(\.\d*)?$/; 
		dispatch({type:RESET_CREATE_CREDIT_ORDER});
		setShowPaymentGateway(false);
		event.preventDefault();
		if(event.target.value && reg.test(event.target.value)){
			setAmount(event.target.value);
		}
		else{
			setAmount(undefined);
		}

	}

	const createOrderHandler = async () => {
		triggerEvents("MintPay_Credit_CreateOrder", {});
		dispatch({type:RESET_CREATE_CREDIT_ORDER})
		dispatch({
			type: CREATE_CREDIT_ORDER,
			payload: {
					description : "Credited to Mintpay",
					callbackUrl: `${window.location.protocol + "//" + window.location.host}/insurance/mintpay/credit-status`,
					totalAmount : amount,
					"user_notes": {
						"Description": remark,
						"mintpay_transaction": "Yes",
						"direction": "Inbound"
					}
				}
		})	
	}

	useEffect(() => {
		if(creditState){
			triggerEvents("MintPay_Credit_OrderCreated", {
				orderDetail : creditState
			});
			setEncryptedId(creditState.encryptedId)
			setShowPaymentGateway(true);
		}

		console.log(creditState);

	},[creditState]);

	const remarkChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		triggerEvents("MintPay_Credit_OnRemarkChange", {});
		console.log(event.target.value);
		if(event.target.value && event.target.value.length <= 200){
			setRemark(event.target.value);
		} else{
			setRemark(undefined);
		}	
	}

	const closeAddMoneyHandler = () => {
		triggerEvents("MintPay_Credit_Close", {});
		let url = getCookie(DEALER_CONSTANTS.BACK_URL);
		if(url) {
			jumpTo(url)
		} else {
			history.push("/wallet");
		}
	}
	const fields = {};
	const getPaymentStatus = (error: any) =>{
		console.log(error);
	}
	const onModalCloseHandler = () => {
		setShowPaymentGateway(false);
	}

	return <AddMoney isMobile={isMobile}>
		{showPaymentGateway ? <PaymentComponent status={true} encryptedId={encryptedId} fields={fields} getPaymentStatus={getPaymentStatus}  uri = {window.location.origin}  apiEndPoint="/api/payment/v1/wallet/credit/order" onModalClose={onModalCloseHandler}/>  : null}
				<AddMoneyContent isMobile={isMobile}>
					<div className = "close-button" onClick={closeAddMoneyHandler}>
						<Icon 
							name="close" 
							size={24}
							style={{
								fontWeight: "bold"
							}}
						></Icon>
					</div>
					<CreditMoneyDescription
						title="Add Money to Mintpay"
						subTitle={`Available Mintpay Balance: ₹ ${currentWalletBalance} /-`}/>	

					<Amount>
						<Input key={"Amount1"} value={amount} size="large" placeholder="0" onChange={amountChangeHandler} prefix={<span className="icon">₹</span>}></Input>
					</Amount>

					<Remark>
						<Input key={"Remark1"} value={remark} size="large" placeholder="Add Remark" onChange={(e) => remarkChangeHandler(e)}></Input>
					</Remark>

					<SubmitPin isMobile={isMobile}>
							<Button
								className = "credit-amount"
								title="Continue"
								btnStyle="primary"
								btnType ="solid"
								size="large"
								disabled={!amount || (parseInt(amount) <= 0 || parseFloat(amount) > MAX_AMOUNT_LIMIT)}
								onClick={createOrderHandler}
								style={{
												
								}}>
							</Button>
							{creditStateFailure && <div style={{color:"red",marginTop:"10px"}}> {creditStateFailure.responseData}</div>}
					</SubmitPin>
				</AddMoneyContent> 
		</AddMoney>;
};

export default MintpayAddMoney;