import { Epic, ofType } from "redux-observable";
import { of } from "rxjs";
import { AjaxError, AjaxResponse } from "rxjs/ajax";
import { catchError, map, mergeMap } from "rxjs/operators";
import { get, post } from "__utils/ajax-wrapper";
import { API_CONSTANTS } from "__utils/api-constants";
import { FluxStandardAction } from "__utils/type";
import * as Actions from "./action";

export const getPartnerDetails: Epic<FluxStandardAction> = action$ => {
	return action$.pipe(
		ofType(Actions.POST_WALLET_TRANSACTIONS),
		mergeMap(action => {
			const payload = action.payload;
			const externalId = action.payload;
			return post(
				`${API_CONSTANTS.WALLET_API}/${externalId}${API_CONSTANTS.WALLET_TRANSACTIONS}`,
				payload,
				null,
				{ showLoader: false }
			).pipe(
				map(
					(
						response: AjaxResponse | AjaxError
					): FluxStandardAction => {
						if (
							response.status === 200 &&
							response.response.success
						) {
							return {
								type: Actions.POST_WALLET_TRANSACTIONS_SUCCESS,
								payload: { data: response.response }
							};
						} else {
							return {
								type: Actions.POST_WALLET_TRANSACTIONS_ERROR,
								error: { data: response.response }
							};
						}
					}
				),
				catchError(error =>
					of({
						type: Actions.POST_WALLET_TRANSACTIONS_ERROR,
						error: error
					})
				)
			);
		})
	);
};

export const getPartnerTransactions: Epic<FluxStandardAction> = action$ => {
	return action$.pipe(
		ofType(Actions.GET_PARTNER_TRANSACTIONS),
		mergeMap(action => {
			const { data, externalId } = action.payload;
			return post(
				`${API_CONSTANTS.WALLET_API}/${externalId}${API_CONSTANTS.WALLET_TRANSACTIONS}`,
				data,
				null,
				{ showLoader: true }
			).pipe(
				map(response => {
					if (response.status === 200 && response.response.success) {
						return {
							type: Actions.GET_PARTNER_TRANSACTIONS_SUCCESS,
							payload: {
								allTransactionsList:
									response.response.responseData
							}
						};
					} else {
						return {
							type: Actions.GET_PARTNER_TRANSACTIONS_ERROR,
							error: { data: response.response }
						};
					}
				}),
				catchError(error =>
					of({
						type: Actions.GET_PARTNER_TRANSACTIONS_ERROR,
						error: error.response
					})
				)
			);
		})
	);
};

export const getPartnerBankDetailsEpic: Epic<FluxStandardAction> = action$ => {
	return action$.pipe(
		ofType(Actions.GET_BANK_ACCOUNT_DETAILS),
		mergeMap(action => {
			const externalID = action.payload.externalID;
			return get(
				`${API_CONSTANTS.BANK_DETAILS}/${externalID}/bank-details`,
				{},
				null
			).pipe(
				map(
					(
						response: AjaxResponse | AjaxError
					): FluxStandardAction => {
						if (response.status === 200) {
							return {
								type: Actions.GET_BANK_ACCOUNT_DETAILS_SUCCESS,
								payload: {
									data: response.response.responseData
								}
							};
						} else {
							return {
								type: Actions.GET_BANK_ACCOUNT_DETAILS_ERROR,
								error: { data: response.response }
							};
						}
					}
				),
				catchError(error =>
					of({
						type: Actions.GET_BANK_ACCOUNT_DETAILS_ERROR,
						error: error
					})
				)
			);
		})
	);
};

export const initiateQSBForPartner: Epic<FluxStandardAction> = action$ => {
	return action$.pipe(
		ofType(Actions.POST_QSB_INITIATE_PARTNER),
		mergeMap(action => {
			const partnerId = action.payload.partnerId;
			return post(
				`${API_CONSTANTS.INITIATE_QSB_PARTNER}`,
				{ partnerId: partnerId },
				null
			).pipe(
				map(
					(
						response: AjaxResponse | AjaxError
					): FluxStandardAction => {
						if (
							response.status === 200 &&
							response.response.success
						) {
							return {
								type: Actions.POST_QSB_INITIATE_PARTNER_SUCCESS,
								payload: { data: response.response }
							};
						} else if (
							response.status === 403 ||
							response.status === 401
						) {
							return {
								type:
									Actions.POST_QSB_INITIATE_PARTNER_UNAUTHORISED,
								payload: response.response
							};
						} else {
							return {
								type: Actions.POST_QSB_INITIATE_PARTNER_ERROR,
								error: { data: response.response }
							};
						}
					}
				),
				catchError(error => {
					if (error.status === 409) {
						return of({
							type: Actions.POST_QSB_INITIATE_PARTNER_REPEAT,
							error: error.response
						});
					} else if (error.status === 403 || error.status === 401) {
						return of({
							type:
								Actions.POST_QSB_INITIATE_PARTNER_UNAUTHORISED,
							error: error.response
						});
					} else {
						return of({
							type: Actions.POST_QSB_INITIATE_PARTNER_ERROR,
							error: error.response
						});
					}
				})
			);
		})
	);
};
