export const POST_WALLET_TRANSACTIONS = "POST_WALLET_TRANSACTIONS";
export const POST_WALLET_TRANSACTIONS_SUCCESS =
	"POST_WALLET_TRANSACTIONS_SUCCESS";
export const POST_WALLET_TRANSACTIONS_ERROR = "POST_WALLET_TRANSACTIONS_ERROR";

// export const GET_BANK_DETAILS = "GET_BANK_DETAILS";
// export const GET_BANK_DETAILS_SUCCESS = "GET_BANK_DETAILS_SUCCESS";
// export const GET_BANK_DETAILS_ERROR = "GET_BANK_DETAILS_ERROR";

export const GET_PARTNER_TRANSACTIONS = "GET_PARTNER_TRANSACTIONS";
export const GET_PARTNER_TRANSACTIONS_SUCCESS =
	"GET_PARTNER_TRANSACTIONS_SUCCESS";
export const GET_PARTNER_TRANSACTIONS_ERROR = "GET_PARTNER_TRANSACTIONS_ERROR";

export const GET_BANK_ACCOUNT_DETAILS = "GET_BANK_ACCOUNT_DETAILS";
export const GET_BANK_ACCOUNT_DETAILS_SUCCESS =
	"GET_BANK_ACCOUNT_DETAILS_SUCCESS";
export const GET_BANK_ACCOUNT_DETAILS_ERROR = "GET_BANK_ACCOUNT_DETAILS_ERROR";

export const POST_QSB_INITIATE_PARTNER = "POST_QSB_INITIATE_PARTNER";
export const POST_QSB_INITIATE_PARTNER_SUCCESS =
	"POST_QSB_INITIATE_PARTNER_SUCCESS";
export const POST_QSB_INITIATE_PARTNER_UNAUTHORISED =
	"POST_QSB_INITIATE_PARTNER_UNAUTHORISED";
export const POST_QSB_INITIATE_PARTNER_REPEAT =
	"POST_QSB_INITIATE_PARTNER_REPEAT";
export const POST_QSB_INITIATE_PARTNER_ERROR =
	"POST_QSB_INITIATE_PARTNER_ERROR";
