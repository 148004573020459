import { fromJS, merge } from "immutable";
import { FluxStandardAction } from "__utils/type";
import * as Actions from "./action";

export interface loginState {
	registerToken: any;
}
const rawState: loginState = {
	registerToken: undefined
};
const intialState = fromJS(rawState);

export default function LoginReducer(
	state = intialState,
	action: FluxStandardAction
) {
	switch (action.type) {
		case Actions.POST_LOGIN_USING_GOOGLE_ACCOUNT_API_SUCCESS:
			return merge(state, fromJS({ registerToken: action.payload.data }));
		case Actions.POST_LOGIN_USING_GOOGLE_ACCOUNT_API_ERROR:
			return state;
		case "RESET_REGISTER_API":
			return merge(state, fromJS({ registerToken: null }));
		default:
			return state;
	}
}
