import {
    PARTNER_WALLET_DETAIL_SUCCESS,
    PARTNER_WALLET_DETAIL_FAILURE,
    PARTNER_WALLET_DETAIL,
    SHOW_ON_BOARDING_MODAL,
    CLOSE_ON_BOARDING_MODAL
} from "../../../constants/actions";

import { fromJS, merge } from "immutable";
import { FluxStandardAction } from "__utils/type";

export interface walletState {
    isFetching: boolean;
    isOnBoarding: boolean
    partnerWalletState: unknown;
    partnerWalletStateFailure: undefined | string;
  }
  const rawState: walletState = {
    isFetching: false,
    isOnBoarding : true,
    partnerWalletState: null,
    partnerWalletStateFailure: undefined
  };
  const intialState = fromJS(rawState);

  export const walletReducer = (
    state = intialState,
	  action: FluxStandardAction
  ) => {
    switch (action.type) {
      case PARTNER_WALLET_DETAIL :
        return merge(state, { isFetching: true });
      case PARTNER_WALLET_DETAIL_SUCCESS:
        return merge(state, { partnerWalletState: action.payload.data.responseData }, { isFetching: false });
      case PARTNER_WALLET_DETAIL_FAILURE:
        return merge(state, fromJS({ partnerWalletStateFailure: action.error }),  { isFetching: false });
      case CLOSE_ON_BOARDING_MODAL:
        return merge(state, fromJS({ isOnBoarding: false }));
      case SHOW_ON_BOARDING_MODAL:
        return merge(state, fromJS({ isOnBoarding: false }));
      default:
        return state;
    }
  }
  