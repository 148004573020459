//Wallet

export const PARTNER_WALLET_DETAIL = "PARTNER_WALLET_DETAIL";
export const PARTNER_WALLET_DETAIL_SUCCESS = "PARTNER_WALLET_DETAIL_SUCCESS";
export const PARTNER_WALLET_DETAIL_FAILURE = "PARTNER_WALLET_DETAIL_FAILURE";
export const CLOSE_ON_BOARDING_MODAL = "CLOSE_ON_BOARDING_MODAL";
export const SHOW_ON_BOARDING_MODAL = "SHOW_ON_BOARDING_MODAL";

export const PARTNER_WALLET_CREATE_WITH_PIN = "PARTNER_WALLET_CREATE_WITH_PIN";
export const PARTNER_WALLET_CREATE_WITH_PIN_SUCCESS = "PARTNER_WALLET_CREATE_WITH_PIN_SUCCESS";
export const PARTNER_WALLET_CREATE_WITH_PIN_FAILURE = "PARTNER_WALLET_CREATE_WITH_PIN_FAILURE";

export const VALIDATE_PIN = "VALIDATE_PIN";
export const VALIDATE_PIN_SUCCESS = "VALIDATE_PIN_SUCCESS";
export const VALIDATE_PIN_FAILURE = "VALIDATE_PIN_FAILURE";

export const RESET_PIN = "RESET_PIN";
export const RESET_PIN_SUCCESS = "RESET_PIN_SUCCESS";
export const CLEAR_RESET_PIN_STATE  = "CLEAR_RESET_PIN_STATE";
export const RESET_PIN_FAILURE = "RESET_PIN_FAILURE";

export const PIN_STAGE = "PIN_STAGE";

export const GET_ALL_MINTPAY_TRANSACTIONS = "GET_ALL_MINTPAY_TRANSACTIONS";
export const GET_ALL_MINTPAY_TRANSACTIONS_SUCCESS = "GET_ALL_MINTPAY_TRANSACTIONS_SUCCESS";
export const GET_ALL_MINTPAY_TRANSACTIONS_FAILURE = "GET_ALL_MINTPAY_TRANSACTIONS_FAILURE";

export const GET_MINTPAY_TRANSACTIONS_BY_ID = "GET_MINTPAY_TRANSACTIONS_BY_ID";
export const GET_MINTPAY_TRANSACTIONS_BY_ID_SUCCESS = "GET_MINTPAY_TRANSACTIONS_BY_ID_SUCCESS";
export const GET_MINTPAY_TRANSACTIONS_BY_ID_FAILURE = "GET_MINTPAY_TRANSACTIONS_BY_ID_FAILURE";

export const SEND_RESEND_MINTPAY_OTP = "SEND_RESEND_MINTPAY_OTP";
export const SEND_RESEND_MINTPAY_OTP_SUCCESS = "SEND_RESEND_MINTPAY_OTP_SUCCESS";
export const SEND_RESEND_MINTPAY_OTP_FAILURE = "SEND_RESEND_MINTPAY_OTP_FAILURE";
export const STORE_MINTPAY_OTP = "STORE_MINTPAY_OTP";
export const STORE_OTP_SESSION_ID = "STORE_OTP_SESSION_ID";
export const RESET_OTP_AND_SESSION_ID = "RESET_OTP_AND_SESSION_ID";
export const RESET_VALIDATED_MINTPAY_OTP_SUCCESS = "RESET_VALIDATED_MINTPAY_OTP_SUCCESS";
export const VALIDATE_MINTPAY_OTP = "VALIDATE_MINTPAY_OTP";
export const VALIDATE_MINTPAY_OTP_SUCCESS = "VALIDATE_MINTPAY_OTP_SUCCESS";
export const VALIDATE_MINTPAY_OTP_FAILURE = "VALIDATE_MINTPAY_OTP_FAILURE";


export const CREATE_CREDIT_ORDER = "CREATE_CREDIT_ORDER";
export const CREATE_CREDIT_ORDER_SUCCESS = "CREATE_CREDIT_ORDER_SUCCESS";
export const CREATE_CREDIT_ORDER_FAILURE = "CREATE_CREDIT_ORDER_FAILURE";
export const RESET_CREATE_CREDIT_ORDER = "RESET_CREATE_CREDIT_ORDER";

export const GET_CREDIT_ORDER_STATUS = "GET_CREDIT_ORDER_STATUS";
export const GET_CREDIT_ORDER_STATUS_SUCCESS = "GET_CREDIT_ORDER_STATUS_SUCCESS";
export const GET_CREDIT_ORDER_STATUS_FAILURE = "GET_CREDIT_ORDER_STATUS_FAILURE";


//For header
export const SHOW_HEADER = "SHOW_HEADER";
export const HIDE_HEADER = "HIDE_HEADER";

//For fetch broker config
export const FETCH_BROKER_CONFIG = "FETCH_BROKER_CONFIG";
export const FETCH_BROKER_CONFIG_SUCCESS = "FETCH_BROKER_CONFIG_SUCCESS";
export const FETCH_BROKER_CONFIG_ERROR = "FETCH_BROKER_CONFIG_ERROR";

export const FETCH_SEARCH_DP_BY_ID = "FETCH_SEARCH_DP_BY_ID";
export const FETCH_SEARCH_DP_BY_ID_SUCCESS = "FETCH_SEARCH_DP_BY_ID_SUCCESS";
export const FETCH_SEARCH_DP_BY_ID_ERROR = "FETCH_SEARCH_DP_BY_ID_ERROR";
export const RESET_SEARCHED_LIST = "RESET_SEARCHED_LIST";
