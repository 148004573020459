
import { ofType, Epic } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { post, put } from "__utils/ajax-wrapper";
import { API_CONSTANTS } from "__utils/api-constants";
import { FluxStandardAction } from "__utils/type";
import {
	PARTNER_WALLET_CREATE_WITH_PIN,
    PARTNER_WALLET_CREATE_WITH_PIN_SUCCESS,
    PARTNER_WALLET_CREATE_WITH_PIN_FAILURE,
    VALIDATE_PIN,
    VALIDATE_PIN_SUCCESS,
    VALIDATE_PIN_FAILURE,
    RESET_PIN,
    RESET_PIN_SUCCESS,
    RESET_PIN_FAILURE
} from "../../../constants/actions";



export const createWalletForPartner : Epic<FluxStandardAction> = action$ => {
    return action$.pipe(
        ofType(PARTNER_WALLET_CREATE_WITH_PIN),
        mergeMap((action) => {
            const payload = action.payload;	
            const APIURL = (process.env.NODE_ENV == "production" ? '' : 'https://pro.joker.planturtle.com');
			return post(
				`${APIURL}${API_CONSTANTS.WALLET_API}`,
				payload,
				null,
				{ showLoader: true }
			).pipe(
				map(response => {
					if (
						response.status === 200 &&
						response.response.success
					) {
						return {
							type: PARTNER_WALLET_CREATE_WITH_PIN_SUCCESS,
							payload: { data: response.response}
						};
					} else {
						return {
							type: PARTNER_WALLET_CREATE_WITH_PIN_FAILURE,
							error: { data: response.response}
						};
					}
				}),
				catchError(error =>
					of({ type: PARTNER_WALLET_CREATE_WITH_PIN_FAILURE, error : error })
				)
			);
        })

    )
}

export const validatePin : Epic<FluxStandardAction> = action$ => {
    return action$.pipe(
        ofType(VALIDATE_PIN),
        mergeMap((action) => {
            const payload = action.payload;	
            const APIURL = (process.env.NODE_ENV == "production" ? '' : 'https://pro.joker.planturtle.com');
			return post(
				`${APIURL}${API_CONSTANTS.WALLET_API}/${payload.externalId}${API_CONSTANTS.WALLET_PIN}`,
				payload,
				null,
				{ showLoader: true }
			).pipe(
				map(response => {
					if (
						response.status === 200 &&
						response.response.status
					) {
						return {
							type: VALIDATE_PIN_SUCCESS,
							payload: { data: response.response}
						};
					} else {
						return {
							type: VALIDATE_PIN_FAILURE,
							error: { data: response.response}
						};
					}
				}),
				catchError(error =>
					of({ type: VALIDATE_PIN_FAILURE, error : error })
				)
			);
        })

    )
}

export const resetPin : Epic<FluxStandardAction> = action$ => {
    return action$.pipe(
        ofType(RESET_PIN),
        mergeMap((action) => {
            const payload = action.payload;	
            const APIURL = (process.env.NODE_ENV == "production" ? '' : 'https://pro.joker.planturtle.com');
			return post(
				`${APIURL}${API_CONSTANTS.WALLET_API}/${payload.externalId}${API_CONSTANTS.WALLET_RESET_PIN}`,
				payload,
				null,
				{ showLoader: true }
			).pipe(
				map(response => {
					if (
						response.status === 200 &&
						response.response.success
					) {
						return {
							type: RESET_PIN_SUCCESS,
							payload: { data: response.response}
						};
					} else {
						return {
							type: RESET_PIN_FAILURE,
							error: { data: response.response}
						};
					}
				}),
				catchError(error =>{
					console.log("catchError in RESET_PIN",error);
					return of({ type: RESET_PIN_FAILURE, error : {data : error.response} })
				}
				)
			);
        })

    )
}

