import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import { 
	isMobileView
} from "../../../common/commonService";
import "../wallet/wallet.css"
import styled, { css } from "styled-components";
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined'
import {
	Button,
	COLORS	
} from "@turtlemint/mint-ui";
import { Input } from "antd";
import { useEffect, useState } from "react";
import {
	SHOW_HEADER
} from "constants/actions";
import { propsToJS } from "__utils/immutable-to-js";

import { SEND_RESEND_MINTPAY_OTP, STORE_MINTPAY_OTP, RESET_OTP_AND_SESSION_ID, RESET_VALIDATED_MINTPAY_OTP_SUCCESS } from "constants/actions";
import { useHistory } from 'react-router-dom';
import mintPayOTPSelector from "./otp.selector";
import WalletSelector from "../wallet/wallet.selector";
import lodash from "lodash";
import { triggerEvents } from "__utils/mixpanel";

const isMobile = isMobileView();
const OtpScreen = styled.div<{ isMobile?: boolean }>`
		background-color: white;
		.otp-fields {
			margin-top: 24px;
			.otp-title {
				font-size: 20px;
				color: ${COLORS.BLACK}
				font-weight: bold;
			}
			.otp-subTitle {
				font-size: 14px;
				color : #212121

			}
			.otp-input {
				font-size: 14px;
				color: #4D5556;
				margin-top: 40px;
			}
			.no-otp {
				p{
					margin-bottom: 0px;
				}
				font-size: 14px;
				color: #757575;
				margin-top: 24px;
				.title {
					
				}
				.resend-otp {
					padding-top: 10px;
				}
				.resend-otp-button{
					cursor: pointer;
					padding-top: 10px;
    				color: #00A465;
    				font-weight: bold;
				}
			}
		}

		${({ isMobile }) =>
		isMobile &&
		css`
			.otp-fields {
				padding: 16px;
			}
		`};

		${({ isMobile }) =>
		!isMobile &&
		css`
			margin-top: 60px;
			padding: 24px;
		`};
		
	`;

	const OtpScreenContent = styled.div<{ isMobile?: boolean }>`
		${({ isMobile }) =>
		!isMobile &&
		css`
			border: 1px solid #E5E5E5;
			border-radius: 8px;
			padding: 40px;
			width: 30%
			margin:auto;
		`};
	`;
	const Header = styled.div<{ isMobile?: boolean }>`
		${({ isMobile }) =>
			isMobile &&
			css`
				position: relative;
				height: 56px;
				top: 0px;
				width: 100%;
				padding: 16px;
				background: #FFFFFF;
				box-shadow: 0px 1px 4px rgb(0 0 0 / 24%);
				margin: 0px
			`};
	`;
	const SubmitPin = styled.div<{ isMobile?: boolean }>`

		.submit-pin {
			width: 100%;
			margin-top: 24px;
		}
		${({ isMobile }) =>
		isMobile &&
		css`
		  .submit-pin {
			position: fixed;
			bottom: 0;
			width: 100%;
			border: none;
			border-radius: 0px;
		  }	
		`};
	`
const MintpayOTP : React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [otp, setOtp] = useState<string>("");
	const { validateOtpState, userOtp, otpSessionId } = propsToJS(useSelector(mintPayOTPSelector));
	const { partnerWalletState} = propsToJS(useSelector(WalletSelector));


	const [landingPageLoaded, setLandingPageLoaded] = useState(0);

	useEffect(() => {
		triggerEvents("MintPay_Otp_PageLoad", {});
		setLandingPageLoaded(1)
	}, [landingPageLoaded === 0]);
	useEffect(() => {
		dispatch({ type: RESET_OTP_AND_SESSION_ID });
		dispatch({ type: RESET_VALIDATED_MINTPAY_OTP_SUCCESS });
		dispatch({ type: SHOW_HEADER, payload: { show: true} });
		dispatch({ type : SEND_RESEND_MINTPAY_OTP, payload: {}})
	}, []);

	useEffect(() => {
		if(partnerWalletState && partnerWalletState.walletBalance){
			//To set masked Email and Mobile
			console.log(partnerWalletState)
		}

	},[partnerWalletState]);

	const backToWalletHandler = () => {
		triggerEvents("MintPay_Otp_BackToWallet", {});
		history.push("/wallet");
	}

	const onOTPSubmitHandler = (value : any) => {
		triggerEvents("MintPay_Otp_OnSubmit", {});

		console.log("STORE_OTP_SESSION_ID otp::",otpSessionId);
		
		dispatch({type : STORE_MINTPAY_OTP, payload :{ userOtp : otp}})
	}

	useEffect(() => {
		if (lodash.has(validateOtpState, "responseData") && validateOtpState.success && validateOtpState.responseData.isValid) {
			history.push({
				pathname :"/pin",
				state : {otp : otp, stage : "UPDATE"}
			});
		}
	}, [validateOtpState])
	
	useEffect(() => {			
		if (userOtp) {
			console.log("redirect pin::",userOtp);
			history.push({pathname : "/pin", state: { stage : "UPDATE"}});
		}
	}, [userOtp])

	var [seconds, setSecond] = useState(30);
	var timer: number;
	useEffect(()=>{
		while(seconds>0){
			timer = setInterval(()=>{
				setSecond(seconds - 1);
			 }, 1000)
			return () => clearInterval(timer);
		}
	});

	const resendOTPHandler = ()=>{
		triggerEvents("MintPay_Otp_ReSend", {});
		setSecond(30);
		dispatch(
			{
				type: SEND_RESEND_MINTPAY_OTP,
				payload: {
					"sessionId": otpSessionId
				}
			})
	}

	const otpChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		triggerEvents("MintPay_Otp_OnChange", {});
		const reg = /^-?\d*(\.\d*)?$/; 
		if(event.target.value && reg.test(event.target.value)){
			setOtp(event.target.value);
		}
		else{
			setOtp("");
		}
	}
	 
	return <OtpScreen isMobile={isMobile}>
		{isMobile && <Header isMobile={isMobile}>
					<div onClick={backToWalletHandler}>
						<ArrowLeftOutlined
						style={{
							fontSize: "20px",
							color: `${COLORS.BLACK}`,
							cursor: "pointer"
						}}></ArrowLeftOutlined>
					</div>
				</Header>}
		<OtpScreenContent isMobile={isMobile}>
			<Header isMobile={isMobile}>
						<div onClick={backToWalletHandler}>
							<ArrowLeftOutlined
							style={{
								fontSize: "20px",
								color: `${COLORS.BLACK}`,
								cursor: "pointer"
							}}></ArrowLeftOutlined>
						</div>
			</Header>
		<div className= "otp-fields">
			<div className="otp-title">Enter OTP</div>
			<div className="otp-subTitle">An OTP has been sent to registered mobile and email. Please enter the OTP to verify it's you.</div>

			<div className="otp-input">
				<span>OTP</span>
				<Input value={otp} size="large" maxLength={4} onChange={otpChangeHandler} style={{
					textAlign: "center",
					letterSpacing: 40,
					padding:'14px 14px 14px 48px',
					fontWeight: 700
				}}></Input>
			</div>

			<div className="no-otp">
				<p className="title">Didn’t receive OTP?</p>
				{seconds != 0 && <p className="resend-otp">Resend OTP in <span style={{color: `${COLORS.BLACK}`}}>{seconds} seconds</span></p>}
				{seconds == 0 && <p className = "resend-otp-button" onClick={resendOTPHandler}>Resend OTP</p>}
			</div>
		</div>
		
		<SubmitPin isMobile={isMobile}>
					<Button
						className = "submit-pin"
						title="Continue"
						btnStyle="primary"
						btnType ="solid"
						size="large"
						onClick={onOTPSubmitHandler}
						disabled={otp.length == 4?false:true}
						style={{
										
						}}>
					</Button>
				</SubmitPin>
		</OtpScreenContent>

	</OtpScreen>
	};

export default MintpayOTP;