import * as React from "react";
import {
	COLORS,
	Title
} from "@turtlemint/mint-ui";
import {
	List, Avatar
} from "antd";
import styled,{ css }  from "styled-components";
import { 
	isMobileView,
	filterINR
} from "../../../../common/commonService";
import { useHistory } from 'react-router-dom';


import MintpayCreditIcon from "../../assets/images/mintpay-credit.svg";
import timestamp from "images/timestamp.svg";
import {getDateMonth, getDateTime } from "__utils/date-format";
import { useState, useEffect } from "react";

const isMobile = isMobileView();


interface TmTransactionListProps {
    data: any,
	isShowSeeMore? : boolean,
	isShowTransactionTitle? : boolean,
	onClick?: (id: string) => void,
	style?: any,
	page?: string,
	showPagination?: boolean
}


const pageSpecificCss:any = {
	wallet:{
		borderWidth:"0px 0px 0.5px 0px !important",
		listLastChildBorder : css`.list-view:last-child{border-width:0px 0px 0px 0px !important}`
	},
	common:{
		borderWidth:"0px 0.5px 0.5px 0px !important",
		background:"rgba(211, 251, 235, 0.4)",
		cursor: "pointer"
	}
}

const Transaction = styled.div<{
	props :TmTransactionListProps;
}>`
	.transaction-list  {
		margin: ${ isMobile ? "10px 16px 16px 16px" :"0px 16px 16px 16px"};
		.ant-list-pagination{
			text-align: center;
			.ant-pagination-item-active {
				border-color: #00A465;
				a {
					color: #00A465;
				}
			}
			.ant-pagination-item {
				:hover {
					a {
						color: #00A465;
					}
					border-color: #00A465;
				}
			}
			.ant-pagination-next : hover, .ant-pagination-prev : hover {
				color: green;
				border-color: green;
			}
		}
		.ant-list-item {
			cursor: ${({props}) => (props.page  ? pageSpecificCss[props.page].cursor: pageSpecificCss["common"].cursor)}
		}
		.ant-list-item-meta-content {
			font-size: 14px;
			color: black;
		}
		.ant-list-item-meta-title {
			color: black;
			font-weight: bold;
			margin:0rem !important;
		}
		.ant-list-item-meta-description {
			color: #A1A6A7;
		}
		.ant-avatar-string {
			font-size: 14px;
			font-weight: 900;
		}
		.amount {
			position: relative;
			color: black;
			font-size: 14px;
			font-weight: bold;
		}
		.list-view {
			padding-right: 10px;
			border-width: ${ isMobile ? "0px 0px 0.5px 0px !important": ({props}) => (props.page ? pageSpecificCss[props.page].borderWidth : pageSpecificCss["common"].borderWidth)};				
			border-style: solid;
			border-color: #A1A6A7;
		}
		${ ({props})=> (props.page && pageSpecificCss[props.page]) && pageSpecificCss[props.page].listLastChildBorder}
		.display-date{
			background: #F5F5F5;
			color: black;
		}
		.active-item-dark-background {
			background : ${isMobile ? '' :({props}) => (props.page  ? pageSpecificCss[props.page].background: pageSpecificCss["common"].background)}
		}
	}
	.transaction-header{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin: 16px;
		font-size: 16px;
		font-weight: bold;
		.title {
			font-size:${ isMobile ? "16px" :"18px"};
			font-weight: bold;
		}

		.see-more-transactions {
			color: #00A465	
			cursor: pointer	 
			padding-right: 15px;	
		}
	}

	
	
`;

const TransactionList : React.FC<TmTransactionListProps> = (props:TmTransactionListProps) => {

	const history = useHistory();
	const [selectedTransactionId, setSelectedTransactionId] = useState<string>();

	useEffect(() => {
		if(props.data && props.data.length){			
			setSelectedTransactionId(props.data[0].walletTransactionId);
		}
	},[props.data]);

	const onListItemClickHandler = (transactionId : string) => {
		setSelectedTransactionId(transactionId);
		if(props.onClick){
			props.onClick(transactionId)
		}
	}

	const redirectToTransactionHandler = () => {
		history.push("/transactions");
	}

    return (
        <Transaction props= {props} style={isMobile ? {}: (props.style ? props.style : {width: "35%"})}>
			  { (props.isShowTransactionTitle || props.isShowSeeMore) &&  <div className="transaction-header">
				    {props.isShowTransactionTitle? <div className="title">Transactions</div> : null}
				   	{props.isShowSeeMore ? <div className="see-more-transactions" onClick={redirectToTransactionHandler}>See more</div> : null }
				</div>}
				
				
				<List
					className="transaction-list"
					itemLayout="horizontal"
					dataSource={props.data}
					pagination={ props.showPagination && {position:'bottom', pageSize: 7}}
					renderItem={(item : any) => (
						<div className= "list-view" onClick={() => onListItemClickHandler(item.walletTransactionId)}>
							<List.Item className={ item.walletTransactionId == selectedTransactionId ? "active-item-dark-background": ""}>
								<List.Item.Meta
									avatar={<Avatar size="large" className="display-date">{getDateMonth(item.date)} </Avatar>}
									title={item.description} // should be name here
									description={item.transactionType === "CREDIT" ? getDateTime(item.date, "DATETIME") : item.notes ? item.notes.entity_description : getDateTime(item.date, "DATETIME")} //// should be description here
								/>
								<div>
								<div className="amount">{["CREDIT", "REFUND"].includes(item.transactionType) ? "+" : "-"} {"₹" + filterINR(item.transactionAmount) + "/-" }</div>
								</div>
							</List.Item>
						</div>
					
					)}
				/>
		</Transaction>
    )

}

export default React.memo(TransactionList);