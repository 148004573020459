import { combineReducers } from "redux-immutable";

// Generic
import { brokerConfigReducer } from "common/broker-config.reducer";
import { headerReducer } from "./components/header/header.reducer";



//Mintpay
import { walletReducer } from "views/mintpay/wallet/wallet.reducer";
import { pinReducer } from "views/mintpay/pin/pin.reducer";
import { transactiosnReducer  } from "views/mintpay/transactions/transactions.reducer";
import { mintPayOTPReducer  } from "views/mintpay/otp/otp.reducer";
import { creditMintpayReducer  } from "views/mintpay/credit/credit.reducer";
import { searchDPReducer } from "views/mintpay/search-partner/search-partner.reducer";
import { partnerDetailsReducer } from "views/mintpay/partner-details/partner-details-reducer";
import LoginReducer from "views/mintpay/admin-login/admin-login.reducer";


// const fs = require('fs');
// const files = fs.readdir("./")
// // for (var file in files) {
// // 	console.log(file)	
// // }

const reducers = {
  headerReducer,
  brokerConfig:brokerConfigReducer,
  walletReducer,
  pinReducer,
  transactiosnReducer,
  mintPayOTPReducer,
  creditMintpayReducer,
  searchDPReducer,
  partnerDetailsReducer,
  LoginReducer
};
export default function createRootReducer(asyncReducers = {}) {
	const rootReducer = combineReducers({
		...reducers,
		...asyncReducers
	});

	return rootReducer;
}
