import React from "react";
import { 
	isMobileView,
	filterINR
} from "../../../../common/commonService";
import styled,{ css }  from "styled-components";
import {
	COLORS,
	Title,
	Button
} from "@turtlemint/mint-ui";
import MintpayBackground from "../../assets/images/mintpay-balance-background.svg";
import MintpayWalletActive from "../../assets/images/mintpay-wallet-active.svg";
import MintpayWalletInActive from "../../assets/images/mintpay-wallet-inactive.svg"
import MintpayInActiveBackground from "../../assets/images/mintpay-inactive-balance-background.png";
import MintpayDesktopBck from "../../assets/images/mintpay-bck-desktop.svg";
import MintpayInActiveDesktopBck from "../../assets/images/mintpay-inactive-bck-desktop.svg";
import MintpayExpertDesktop from "../../assets/images/mintpay-ask-an-expert.svg";
import InactiveMintpayExpertDesktop from "../../assets/images/inactive-mintpay-ask-an-expert.svg";
import warningLogo from "images/warning.svg";
import { useHistory } from 'react-router-dom';
import { Tag } from "antd";
import { triggerEvents } from "__utils/mixpanel";
interface TmWalletDetailProps {
    data: any,
}

const WalletDetail : React.FC<TmWalletDetailProps> = (props : TmWalletDetailProps) => {
    const isMobile = isMobileView();
    const history = useHistory();
    
    const WalletDetail = styled.div`
        .wallet-balance-background-desktop {
            .background-shadow{
                width: 816px;
                z-index: 1;
                float: left;
                position: absolute;
            }
            .background{
                width: 783px;
                z-index: 2;
            }
        }
        .wallet-faq-desktop {
			float: right;
    		margin-top: -110px;
		}
    `;

    const WalletBalance = styled.div<{ isMobile?: boolean }>`
		position: absolute;
    	top: 0px;
        margin-bottom:18px;
        padding:16px;
        z-index:1;
        ${({ isMobile }) =>
		!isMobile &&
        css`
            margin-top:22px;
			margin-left: 129px;
            padding: 0px;
		`};
	`;
	const AvailableBalance = styled.div`
        margin-top: 16px;
        .balance-grid{
            display:flex;
            flex-direction:row;

        }
        .balance-low-balance{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .balance-low-balance .ant-tag-magenta{
            height:25px;
            border:0px;
            margin-left:10px;
            border-radius: 25px;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: red;
        }
		.balance {
			font-size: 36px;
			font-weight: bold;
			color: ${COLORS.WHITE};
        }
        .low-balance-txt{
            padding: 5px;
            font-size: 14px;
        }
	`;

    const handleAddMoneyClick = () => {
        triggerEvents("MintPay_Wallet_OnAddMoneyClick", {
            WalletDetail : props.data
        });
        if(props.data.accountExist) {
            triggerEvents("MintPay_Wallet_RedirectToAddMoney", {
                WalletDetail : props.data
            });
            history.push("/add-money");
        } else {
            triggerEvents("MintPay_Wallet_SetUpAccount", {
                WalletDetail : props.data
            });
            history.push({pathname : "/pin", state : { stage:"SET" }});
        }
    }

    const checkIfLowBalance = (balance:string, accountExist : boolean)=>{
        let walletBalance = balance ? parseInt(balance.split(".")[0]): 0;

        if (accountExist && walletBalance < 500) {
            triggerEvents("MintPay_Wallet_ShowLowBalanceAlert", {
                WalletDetail : props.data
            });
            return true;
        }
        return false;
    }

    return (
        <WalletDetail>
            {
			 isMobile ? <img src={props.data.accountExist ? MintpayBackground : MintpayInActiveBackground} alt="Snow" style={{ width:"100%" }}/> :
			 (
			 <div className="wallet-balance-background-desktop">                
				 <img className="background-shadow" src={props.data.accountExist ? MintpayWalletActive : MintpayWalletInActive} alt="Snow"/>
				 <img className="background" src={props.data.accountExist ? MintpayDesktopBck : MintpayInActiveDesktopBck} alt="Snow"/>
		     </div>
			 )
		   }
           {
                isMobile ? '' :
                (
                <div className="wallet-faq-desktop">
                    <img className="background-shadow" src={props.data.accountExist ? MintpayExpertDesktop : InactiveMintpayExpertDesktop} alt="Snow" />
                </div>
                )
			}
           <WalletBalance isMobile={isMobile}>
                <Title
                        text="Mintpay"
                        size={20}
                        weight="bold"
                        color={COLORS.WHITE}
                        />
                <Title
                        text={props.data.name ? 'Hello ' + props.data.name : 'Payments made easy!'}
                        size={14}
                        weight="bold"
                        color={COLORS.WHITE}
                        />
                <AvailableBalance>
                <Title
                        text="Available Balance"
                        size={13}
                        weight="medium"
                        color={COLORS.WHITE}
                        />
    
                    <div className="balance-grid">
                        <div className="balance">
                            {"₹" + filterINR(props.data.walletBalance)}
                        </div>
                        {checkIfLowBalance(props.data.walletBalance, props.data.accountExist) &&
                            <div className="balance-low-balance">
                                <Tag color="magenta" > 	<img src={warningLogo} alt="loading" /> <span className="low-balance-txt">Low Balance</span></Tag>
                            </div>}
                    </div>	
                <Button
                                title={props.data.accountExist ? "+ Add Money" : "Setup Pin to Add Money"}
                                btnStyle="primary"
                                btnType ="solid"
                                size="small"
                                onClick={handleAddMoneyClick}
                                style={{
                                    minHeight: "28px",
                                    padding: "5px 15px",
                                    cursor: "pointer"
                                }}
                            />	
                </AvailableBalance>
		 </WalletBalance>
        </WalletDetail>
    )

}

export default React.memo(WalletDetail);