
import { getCookie, isMobileView, isDealerFlow, isAdminView } from "common/commonService";
import { pushToGTMDataLayer } from 'turtlefin-analytics-wrapper';


let env_check = process.env.NODE_ENV === 'production';
let IS_MOBILE = isMobileView();

export const gtmPixel = (gtmKey) => {
  console.log('gtmPixel', gtmKey);
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src =
      'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', gtmKey);
}

export const triggerEvents = (eventName, jsonObject = {}) => {
  jsonObject = {...jsonObject,eventName: eventName}
  const eventLabel = Object.keys(jsonObject)
    .map(key => {
      if (!!jsonObject[key] && (typeof jsonObject[key] === 'string' || typeof jsonObject[key] === 'boolean')) {
        return `${key} : ${jsonObject[key]}`;
      }
      return null;
    })
    .filter(ele => ele)
    .join(';');
  const eventValue = Object.keys(jsonObject)
    .map(key => {
      if (!!jsonObject[key] && typeof jsonObject[key] === 'number') {
        return `${key} : ${jsonObject[key]}`;
      }
      return null;
    })
    .filter(ele => ele)
    .join(';');
  try {
    jsonObject = {
      ...jsonObject,
      gaAction: jsonObject.eventName,
      gaLabel: eventLabel,
      gaValue: eventValue,
      gaCategory: jsonObject.vertical || 'home',
      event: "track_events",
      partner_id: getCookie('dealerUserName') || getCookie("pospUserName"),
      externalId : getCookie('dealerUserName') || getCookie("pospUserName"),
      deviceType: IS_MOBILE? 'mobile': 'desktop',
      userFlow: 'b2b',
      source: "mintpay"
    };
    // window.dataLayer.push(jsonObject);
    pushToGTMDataLayer(jsonObject)
  } catch (err) {
    // console.error(err.message);
  }
};


// export const Mixpanel = actions;