/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from "reselect";
// selector
const getPartnerWalletIsFetching = (state: any) =>
	state.getIn(["walletReducer", "isFetching"]);
const getPartnerWalletData = (state: any) =>
	state.getIn(["walletReducer", "partnerWalletState"]);
const getPartnerWalleError = (state: any) =>
	state.getIn(["walletReducer", "partnerWalletStateFailure"]);
const showOnBoardingModal = (state: any) =>
	state.getIn(["walletReducer", "isOnBoarding"]);

const WalletSelector = createSelector(
	[getPartnerWalletData, getPartnerWalleError, getPartnerWalletIsFetching, showOnBoardingModal],
	(partnerWalletState, partnerWalletStateFailure, isFetching, isOnBoarding) => ({
		partnerWalletState,
		partnerWalletStateFailure,
		isFetching,
		isOnBoarding
	})
);

export default WalletSelector;