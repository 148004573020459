import React, { useEffect } from "react";
import WalletDetail from "./wallet-detail"
import TransactionList from "../transactions/transaction-list"
import PinSection from "./pinSection";
import FAQs from "./faqs";
import WalletIntro from "./howItWorks"
import iciciLogo from "images/icici.svg";
import digitLogo from "images/digit.svg";
import cholaLogo from "images/chola.svg";
import magmaLogo from "images/MAGMA.png";
import fggiLogo from "images/FGGI.png";
import reliLogo from "images/RELI.png";
import downArrow from "images/green-down-arrow.svg";
import upArrow from "images/green-up-arrow.svg";

import "./wallet.css"
import { useSelector, useDispatch } from "react-redux";
import {
	CLOSE_ON_BOARDING_MODAL,
	GET_ALL_MINTPAY_TRANSACTIONS,
	PARTNER_WALLET_DETAIL,
	RESET_OTP_AND_SESSION_ID,
	RESET_PIN_FAILURE,
	PARTNER_WALLET_CREATE_WITH_PIN_FAILURE,
	RESET_CREATE_CREDIT_ORDER
} from "./../../../constants/actions";
import styled,{ css }  from "styled-components";
import { 
	getCookie,
	isMobileView
} from "../../../common/commonService";
import WalletSelector from "./wallet.selector";
import { propsToJS } from "__utils/immutable-to-js";
import transactionsSelector from "../transactions/transactions.selector";
import { COLORS, Title } from "@turtlemint/mint-ui";
import {
	Divider,
	Modal,
} from "antd";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import {
	SHOW_HEADER
} from "constants/actions";
import { triggerEvents } from "__utils/mixpanel";

const isMobile = isMobileView();

const MintpayWallet : React.FC= () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { partnerWalletState, isFetching, isOnBoarding } = propsToJS(useSelector(WalletSelector));
	const { allTransactionsState } = propsToJS(useSelector(transactionsSelector));
	const [showOnBoarding, setShowOnBoarding] = useState(true);
	const [landingPageLoaded, setLandingPageLoaded] = useState(0);
	const [showInsurers, setShowInsurers] = useState(false);
	const insurerList = [{
		img: iciciLogo,
		width:isMobile ? "105px" : "127px",
		hide: false
	},{
		img: digitLogo,
		width:isMobile ? "55px" : "65px",
		hide: false
	},
	{
		img: cholaLogo,
		width:isMobile ? "92px" : "111px",
		hide: false
	},{
		img: magmaLogo,
		width: isMobile ? "92px" : "111px",
		hide: false
		//hide: !isMobile ? false : !showInsurers for 7th and 8th insurer
	},
	{
		img: fggiLogo,
		width:isMobile ? "92px" : "111px",
		hide: false
	},
	{
		img: reliLogo,
		width:isMobile ? "92px" : "111px",
		hide: false
	}]

	const remainingInsurers = insurerList.length - (isMobile ? 6 : 8)

	useEffect(() => {
		triggerEvents("MintPay_Wallet_PageLoad", {});
		setLandingPageLoaded(1)
	}, [landingPageLoaded === 0]);
	useEffect(() => {
		dispatch({ type: SHOW_HEADER, payload: { show: true} });
		dispatch({type:RESET_CREATE_CREDIT_ORDER});
		dispatch({ type : RESET_OTP_AND_SESSION_ID});
		dispatch({ type: RESET_PIN_FAILURE, payload : {action: {error : null}}});
		dispatch({ type: PARTNER_WALLET_CREATE_WITH_PIN_FAILURE, payload : {action: {error:null}}})
	}, []);
	useEffect(() => {
		setShowOnBoarding(isOnBoarding)
	}, [isOnBoarding])
	useEffect(() => {
		dispatch({
			type: PARTNER_WALLET_DETAIL,
			payload: {
					externalId : getCookie('dealerUserName') || getCookie("pospUserName")
			}
		})
	},[dispatch]);

	useEffect(() => {
		if(partnerWalletState && partnerWalletState.accountExist){
			triggerEvents("MintPay_Wallet_PartnerWalletDetail", {
				WalletDetail : partnerWalletState,
			});
			dispatch({
				type: GET_ALL_MINTPAY_TRANSACTIONS,
				payload: {
						limit : 5,
						offset: 0
				}
			})
		}
	}, [partnerWalletState])

	const WalletPage = styled.div<{ isMobile?: boolean }>`
		margin-top: 60px;
		position: relative;
		background-color: white;
		height: 100%;
		.no-transaction-msg{
			font-size: 14px;
			.title {
				margin: 0px 16px;
				color: #121617;
			}
			.description {
				margin: 0px 16px;
				color: #A1A6A7
				margin-bottom : 12px;
			}
		}
		${({ isMobile }) =>
		isMobile &&
		css`
			height : 100%
		`};
	`
	const Container = styled.div<{ isMobile?: boolean }>`
		width: 1250px;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
		margin-top: 100px;
		${({ isMobile }) =>
		isMobile &&
		css`
			width: 100%;
			padding-right: 0px;
			padding-left: 0px;
			margin-top: 0px;
		`};
	`;
	const Faqs = styled.div<{ isMobile?: boolean }>`
		margin: 16px
		border: 1px solid white;
		.ant-collapse {
			background-color: white;
		}
		
		${({ isMobile }) =>
		!isMobile &&
		css`
			.ant-collapse {
				width: 332px;
			}
		`};
	`;
	const Pin = styled.div<{ isMobile?: boolean }>`
	   margin : 16px;
	   
	   .pin-change {
		 padding: 0px !important;
		 .ant-list-item-meta-title {
			 color: black;
			 margin: 0.5rem 0;
		 }
	   }
	   ${({ isMobile }) =>
		!isMobile &&
		css`
			box-shadow: 0px 1px 4px 1px rgb(0 0 0 / 25%);
			border-radius: 8px;
			padding: 16px;
		`};
	`
	const Section =  styled.div<{ isMobile?: boolean }>`
	   	display: flex;
		flex-direction:  ${({ isMobile }) => (isMobile ? `column` : `row`)};;
	`;
	const TransactionPinSection =  styled.div<{ isMobile?: boolean }>`
	 	display: flex;
		flex-direction: column;

		
		${({ isMobile }) =>
		!isMobile &&
		css`
			flex-grow: 6;
			margin-top: -50px;
			.transaction-list {
				box-shadow: 0px 1px 4px 1px rgb(0 0 0 / 25%);
				border-radius: 8px;
				padding: 16px;
			}
		`};
	`;
	const textList = [
		{
			key: 1,
			title: "What is Mintpay?",
			text: "Mintpay is a digital payment solution by Turtlemint. It’s like digital cash. You can add money to your Mintpay account and keep using"
		},
		{
			key: 2,
			title: "How can I change my Mintpay Pin?",
			text:"You can go to Profile > Mintpay > Change Mintpay Pin to change your Mintpay 4 digit Pin."
		},
		{
			key: 3,
			title: "How do I sign up for Mintpay?",
			text: "You do not require any documents to sign up for Mintpay. If you have a registered GI license with Turtlemint, you just need to set up a 4 digit Mintpay pin to sign up."
		},
		{
			key: 4,
			title: "I have added money to Mintpay, but the balance is not updated?",
			text: "Typically the money gets credited to your Mintpay account instantly. However, sometimes it may take up to 15 minutes. You can follow all your transactions from Profile > Mintpay > Transactions. If you still face any issue, you can contact our Customer Support at 1800-266-6468 or write to us at pospsupport@turtlemint.com"
		},
		{
			key: 5,
			title: "What does it cost me to use Mintpay?",
			text: "Mintpay is completely free of cost. There are no hidden charges involved while adding or transacting with Mintpay."
		},
		{
			key: 6,
			title: "Am I protected when I use Mintpay?",
			text: "Every Mintpay transaction requires your 4-digit pin authorization. However, if you notice any fraudulent transaction, you must notify us as quickly as possible. Unless you have acted fraudulently or with negligence, you will not be held responsible."
		},
		{
			key: 7,
			title: "How do I transfer my Mintpay balance back to my bank account?",
			text: "You cannot transfer back your balance from Mintpay to your Bank Account. Only if you are closing your account with Turtlemint, your remaining balance will be credited back to your bank account."
		},
		{
			key: 8,
			title: "How will I get a refund on transactions made through Mintpay?",
			text: "If you’ve transacted using Mintpay, your refunds will be processed back to your Mintpay account. This may take up to 3-5 business days."
		},
		{
			key: 9,
			title: "Where can I see my record of transactions with Mintpay?",
			text: "You can see all your Mintpay debits and credits under the Transactions tab on Mintpay."
		}

	];
	
	const hideModal = () => {
		triggerEvents("MintPay_Wallet_CloseOnBoardingModal", {
			externalId : getCookie('dealerUserName') || getCookie("pospUserName")
		});
		dispatch({
			type: CLOSE_ON_BOARDING_MODAL
		});
	}
	return (
	 <WalletPage isMobile={isMobile}>
		{ ( partnerWalletState && !partnerWalletState.accountExist) && <Modal
					title=""
					onCancel={hideModal}
					onOk={() => {history.push({pathname :"/pin", state: { stage: "SET" }})}}
					afterClose={hideModal}
					visible={showOnBoarding}
					cancelText={"I will do it later"}
					cancelButtonProps	={{
						type : "default",
						size: "large"
						
					}
					}
					okButtonProps = {{
						type : "primary",
						size: "large",
						style: {
							background: "#00A465",
							border: "#00A465"
						}
					}
					}
					okText = {"Setup Pin"}
					width={"700px"}
					
		>
			<WalletIntro></WalletIntro> 
		</Modal>}
		 {isFetching === false && partnerWalletState? <WalletDetail data={partnerWalletState}></WalletDetail> : null}
		 <Container isMobile={isMobile}>
			<Section isMobile={isMobile}>
					<div style={{width :"100%" , marginTop : isMobile ? "13px" : "-50px"}}>
						<div className="insurerListContainer">
							<Title
								text="Insurers accepting MintPay"
								size={18}
								color={COLORS.BLACK}
								style={{
									fontWeight:600,
									padding: isMobile ? "10px 14px" :"10px 16px"
								}}
							/>
							<div className="insurerList">
								{insurerList.map(insurer => !insurer.hide ? <div><img src={insurer.img} width={insurer.width} /></div> : null)}
							</div>

							{ (isMobile ? insurerList.length > 6 : insurerList.length > 8) && <div className="insurerListFooter" >
								<div onClick={() => setShowInsurers(!showInsurers)}>See { !showInsurers ? remainingInsurers + ' more' : 'less' } { remainingInsurers > 1 ? 'insurers' : 'insurer'}  <img src={showInsurers ? upArrow :downArrow}/></div>
							</div>}
						</div>
						{isFetching === false && partnerWalletState ? <TransactionPinSection isMobile={isMobile}>
							{partnerWalletState.accountExist ? null : <WalletIntro></WalletIntro>}
							{allTransactionsState ?
								<TransactionList page={'wallet'} style={{ width: "100%" }} isShowSeeMore={true} isShowTransactionTitle={true} data={allTransactionsState.walletTransactionList} ></TransactionList>
								: (<div className="no-transaction-msg">
									<Title
										text="Transactions"
										size={16}
										weight="bold"
										color={COLORS.BLACK}
										style={{
											margin: "16px 20px 3px 16px"
										}}
									/>
									<div className="no-transaction-msg">
										<p className = "title">You have no transactions as of now.</p>
										<p className = "description">Your recent transactions will be shown here.</p>
									</div>
								</div>)}
							<Pin isMobile={isMobile}>
						<PinSection showDivider={isMobile} data = {partnerWalletState}></PinSection>
					</Pin>
						</TransactionPinSection> : null }
					</div>
					<Faqs isMobile={isMobile}>
						<FAQs data={textList}></FAQs>
					</Faqs>
			</Section>
		 </Container>
     </WalletPage>
	);
};

export default React.memo(MintpayWallet);