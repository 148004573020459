import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainStorage from "storage/storage";
import { getSessionStorage } from "storage";
import styled from "styled-components";
import {
	COLORS,
	Icon,
	Title,
	Row,
	Col,
	Modal,
	Button
} from "@turtlemint/mint-ui";
import turtlemintpro from "./../../assets/images/turtlemint-pro.svg";
import { createSelector } from "reselect";
import { getCookie } from "common/commonService";
import { getBrokerConfigSelector } from "../../common/broker-config.selector";
import { propsToJS } from "__utils/immutable-to-js";
import { goToPartnerPage } from "__utils/dealer";
import { initDefaultThings } from "__utils/dealer";
import { ThemeProvider } from "styled-components";
import _ from "lodash";
import { jumpTo } from "__utils";
import { useHistory, useLocation } from 'react-router-dom';

import { triggerEvents } from "../../__utils/mixpanel";
import { isMobileView } from "./../../common/commonService";
import { Avatar, Popover } from "antd";
import { clearLoginSessionStorage, getUserInformation } from "components/admin-login-info";
import { AjaxResponse } from "rxjs/ajax";
import LoginSelector from "views/mintpay/admin-login/admin-selector";
import { API_CONSTANTS } from "__utils/api-constants";
import { postLogout } from "__utils/ajax-logout-call";
const BROKER_CONFIG: MainStorage = getSessionStorage("brokerConfig");

const Wrapper = styled.div`
	background: ${COLORS.WHITE};
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	width: 100%;
	position: fixed;
	top: 0;
	box-shadow: 0 2px 10px 0 rgba(102, 143, 211, 0.33);
	@media (max-width: 480px) {
		min-height: 48px;
	}
	height: 60px;
	z-index: 3;
`;

const LogoWrapper = styled.div`
	@media (max-width: 767px) {
		max-width: 120px;
	}
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const headerSelectorFunc = (state: any) => state.get("headerReducer");
export const headerStateSelector = createSelector(
	headerSelectorFunc,
	headerState => headerState
);
const AppHeader = () => {
	const isMobile = isMobileView();
	const brokerConfigState = useSelector(getBrokerConfigSelector);
	const brokerConfig = propsToJS(brokerConfigState);

	const headerState = useSelector(headerStateSelector).toJS();
	const dealerUserName = getCookie('dealerUserName') || getCookie("pospUserName");
	const [openConfirm, setOpenConfirm] = useState(false);
	let location = useLocation();
	const [brokerTheme, updateBrokerTheme] = React.useState({});
	const dispatch = useDispatch();

	const baseUrl: string =
	process.env.NODE_ENV === "development"
		? `${process.env.REACT_APP_API_URL}` + "/api"
		: window.location.origin + "/api";
	const logOutUrl = API_CONSTANTS.LOGOUT_ADMIN_API;
	const userInfo = getUserInformation();
	const history = useHistory();
	const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false);
	const { registerToken } = propsToJS(useSelector(LoginSelector));

	useEffect(() => {
		// Set `brokerConfig` in SESSION-STORAGE
		BROKER_CONFIG.set({
			...brokerConfig,
			tenant: brokerConfig.brokerConf.tenant
		});
		// Setting/clearout cookies and session - all such things takes place here
		initDefaultThings();
	}, [brokerConfig]);

	useEffect(() => {
		if (getUserInformation() && !isLoggedIn) {
			setIsLoggedIn(true);
		}
	}, [registerToken]);

	const backToHome = () => {
		// setOpenConfirm(true);
		const referrer = window.document.referrer;
		triggerEvents("Back-To-Home-clicked", {});
		goToPartnerPage("/dashboard");
	};

	const modalChildren = (
		<ThemeProvider theme={brokerTheme}>
			<div>
				<Row align="center" justify="center">
					<Title
						weight="bold"
						color={COLORS.INACTIVE_HEADING}
						size={18}
						style={{ paddingBottom: "20px" }}
						text="Do you want to go back to Home?"
					/>
				</Row>
				<Row align="center" justify="center">
					<Col>
						<Button
							btnType="solid"
							btnStyle="primary"
							title="Ok"
							onClick={backToHome}
							style={{
								marginRight: "18px",
								padding: "6px 12px",
								width: "84px",
								minHeight: "30px"
							}}
						/>
						<Button
							title="Cancel"
							onClick={() => setOpenConfirm(false)}
							style={{
								padding: "6px 12px",
								width: "84px",
								minHeight: "30px"
							}}
						/>
					</Col>
				</Row>
			</div>
		</ThemeProvider>
	);

	const logoutUser = () => {
		console.log("userrr", userInfo);
		postLogout(logOutUrl, {}, { "Authorization": userInfo.token }).subscribe((response: AjaxResponse) => {
			if (response.response.result) {
				clearLoginSessionStorage();
				setIsLoggedIn(false);
				dispatch({ type: "RESET_REGISTER_API" });
				history.push("/insurance/mintpay");
				console.log("history", response);
			}

		})
	};

	const getAvatarClickMenu = (userEmail: any) => {
		return (
			<div className="signout-popup">
				<Row>{userEmail}</Row>
				<br />
				<Button
					title="Sign out"
					btnStyle="primary"
					btnType="solid"
					onClick={() => {
						logoutUser();
						setIsLoggedIn(false);
					}}
					style={{
						cursor: "pointer",
						marginRight: "10px"
					}}
				/>
			</div>
		);
	};

	const goToSite = () => {
		const mintproURL =
			brokerConfig.brokerConf.hostUrl ||
			brokerConfig.brokerConf.mintproBaseUrl;
		jumpTo(mintproURL);
	};

	if (!headerState.show) {
		return null;
	}
	return (
		<Wrapper>
			<div className="container">
				<Row>
					<Col xs={8} sm={8} lg={8} md={8}>
						<LogoWrapper onClick={() => goToSite()}>
							{" "}
							<img
								src={turtlemintpro}
								alt={brokerConfig.brokerConf.broker}
								className="img-responsive"
							/>
						</LogoWrapper>
					</Col>
					<Col xs={2} sm={2} lg={2} md={2}>
						<div
							onClick={() => setOpenConfirm(true)}
							style={{ cursor: "pointer" }}
						>
							<Row justify="flex-end">
								<Col>
									<Icon
										name="return"
										color={COLORS.GREY1}
									/>
								</Col>
								{ !isMobile && <Col>
									<Title
										color={COLORS.GREY1}
										text="Back to Home"
										size={16}
										weight="bold"
										style={{ padding: "0px 8px" }}
									/>
								</Col>}
							</Row>
						</div>
					</Col>

					{ isLoggedIn && <Col xs={2} sm={2} lg={2} md={2}>
						<Popover
							placement="bottomRight"
							trigger="click"
							content={getAvatarClickMenu(userInfo?.emailId)}
							style={{ marginLeft: "500px" }}
						>
							<Avatar size={40} className="user-detail-avatar" style={{ marginLeft: "10px" }}>
								{userInfo?.emailId.substring(0,1).toUpperCase()}
							</Avatar>
						</Popover>
					</Col>}
				</Row>
			</div>

			<Modal
				title=""
				cancelText="Cancel"
				visible={openConfirm}
				children={modalChildren}
				header={false}
				iconColor={COLORS.WHITE}
				closable={true}
				footer={false}
			/>
		</Wrapper>
	);
};

export default AppHeader;
