
import { ofType, Epic } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { post, get } from "__utils/ajax-wrapper";
import { API_CONSTANTS } from "__utils/api-constants";
import { FluxStandardAction } from "__utils/type";
import { getCookie } from "common/commonService";
import {
	CREATE_CREDIT_ORDER,
    CREATE_CREDIT_ORDER_SUCCESS,
    CREATE_CREDIT_ORDER_FAILURE,
	GET_CREDIT_ORDER_STATUS,
	GET_CREDIT_ORDER_STATUS_SUCCESS,
	GET_CREDIT_ORDER_STATUS_FAILURE


} from "../../../constants/actions";
import { AjaxError, AjaxResponse } from "rxjs/ajax";


export const createCreditOrder : Epic<FluxStandardAction> = action$ => {
    return action$.pipe(
        ofType(CREATE_CREDIT_ORDER),
        mergeMap((action) => {
            const payload = action.payload;	
			const dealerUserName = getCookie('dealerUserName') || getCookie("pospUserName");
            const APIURL = (process.env.NODE_ENV == "production" ? '' : 'https://pro.joker.planturtle.com');
            return post(
				`${APIURL}${API_CONSTANTS.WALLET_API}/${dealerUserName}${API_CONSTANTS.WALLET_CREDIT}`,
				payload,
				null,
				{ showLoader: true }
			).pipe(
				map( (response: AjaxResponse | AjaxError): FluxStandardAction => {
					if (
						response.status === 200 &&
						response.response.success
					) {
						return {
							type: CREATE_CREDIT_ORDER_SUCCESS,
							payload: { data : response.response}
						};
					} else {
						return {
							type: CREATE_CREDIT_ORDER_FAILURE,
							error: { data: response.response}
						};
					}
				}),
				catchError(error =>
					of({ type: CREATE_CREDIT_ORDER_FAILURE, error : error })
				)
			);
        })

    )
}

export const getCreditOrderStatus : Epic<FluxStandardAction> = action$ => {
    return action$.pipe(
        ofType(GET_CREDIT_ORDER_STATUS),
        mergeMap((action) => {
            const payload = action.payload;	
            const APIURL = (process.env.NODE_ENV == "production" ? '' : 'https://pro.joker.planturtle.com');
            return get(
				`${APIURL}${API_CONSTANTS.WALLET_API}${API_CONSTANTS.WALLET_CREDIT_ORDER_STATUS}?orderId=${payload.orderId}`,
				null,
				{ showLoader: true }
			).pipe(
				map(response => {
					if (
						response.status === 200 &&
						response.response.success
					) {
						return {
							type: GET_CREDIT_ORDER_STATUS_SUCCESS,
							payload: { data : response.response}
						};
					} else {
						return {
							type: GET_CREDIT_ORDER_STATUS_FAILURE,
							error: { data: response.response}
						};
					}
				}),
				catchError(error =>
					of({ type: GET_CREDIT_ORDER_STATUS_FAILURE, error : error })
				)
			);
        })

    )
}






