import { Avatar, Button, message, Popover, Row } from "antd";
import React, { useEffect, useState } from "react";

export const getUserInformation = () => {
	const authCredsString: any =
		window.sessionStorage.getItem("authCreds");
	if (authCredsString) {
		const authCreds: any = JSON.parse(authCredsString);
		if (authCreds) return authCreds;
	} else return null;
};

export const clearLoginSessionStorage = () => {
	window.sessionStorage.removeItem("authCreds");
};

const LoginUserInfo: React.FC = () => {
	return <div></div>;
};

export default LoginUserInfo;
