import { createSelector } from "reselect";

const isFetching = (state: any) =>
	state.getIn(["searchDPReducer", "isFetching"]);

const partnerIDList = (state: any) =>
	state.getIn(["searchDPReducer", "partnerIDList"]);
const partnerIDListStateFailure = (state: any) =>
	state.getIn(["searchDPReducer", "partnerIDListStateFailure"]);

const qsbObjects = (state: any) => state.getIn(["searchDPReducer", "qsbObjects"]);
const qsbInitiationSuccess = (state: any) =>
	state.getIn(["searchDPReducer", "qsbInitiationSuccess"]);
const qsbUnauthorisedInitiation = (state: any) =>
	state.getIn(["searchDPReducer", "qsbUnauthorisedInitiation"]);
const qsbInitiationRepeated = (state: any) =>
	state.getIn(["searchDPReducer", "qsbInitiationRepeated"]);
const qsbInitiationError = (state: any) =>
	state.getIn(["searchDPReducer", "qsbInitiationError"]);
const walletDisableUnauthorised = (state: any) =>
	state.getIn(["searchDPReducer", "walletDisableUnauthorised"]);

const walletDisableStatus = (state: any) => state.getIn(["searchDPReducer", "walletDisableStatus"]);
const walletStatusChangeSuccess = (state: any) =>
	state.getIn(["searchDPReducer", "walletStatusChangeSuccess"]);
const walletStatusChangeError = (state: any) =>
	state.getIn(["searchDPReducer", "walletStatusChangeError"]);

const searchPartnerSelector = createSelector(
	[
		isFetching,
		partnerIDList,
		partnerIDListStateFailure,
		qsbInitiationSuccess,
		qsbUnauthorisedInitiation,
		qsbInitiationRepeated,
		qsbInitiationError,
		walletStatusChangeSuccess,
		walletDisableUnauthorised,
		walletStatusChangeError,
		qsbObjects,
		walletDisableStatus
	],
	(
		isFetching,
		partnerIDList,
		partnerIDListStateFailure,
		qsbInitiationSuccess,
		qsbUnauthorisedInitiation,
		qsbInitiationRepeated,
		qsbInitiationError,
		walletStatusChangeSuccess,
		walletDisableUnauthorised,
		walletStatusChangeError,
		qsbObjects,
		walletDisableStatus
	) => ({
		isFetching,
		partnerIDList,
		partnerIDListStateFailure,
		qsbInitiationSuccess,
		qsbUnauthorisedInitiation,
		qsbInitiationRepeated,
		qsbInitiationError,
		walletStatusChangeSuccess,
		walletDisableUnauthorised,
		walletStatusChangeError,
		qsbObjects,
		walletDisableStatus
	})
);

export default searchPartnerSelector;
