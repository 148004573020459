import React, { useEffect, useState } from "react";

import { 
	isMobileView,
	getCookie
} from "../../../../common/commonService";
import {
	Button
} from "@turtlemint/mint-ui";
import styled,{ css }  from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GET_CREDIT_ORDER_STATUS} from "constants/actions";
import creditMintpaySelector from "../credit.selector";
import { propsToJS } from "__utils/immutable-to-js";
import MintpayCreditStatusBck from "../../assets/images/credit-status-bck.svg";
import MintpayDesktopBck from "../../assets/images/mintpay-bck-desktop.svg";
import MintpayFailureIcon from "../../assets/images/mintpay-failure-icon.svg";
import MintPayCreditSuccess from "images/mobile-successful.svg";
import MintPayCreditInProcess from "../..//assets/images/mintpay-processing-icon-mobile.svg"
import { triggerEvents } from "__utils/mixpanel";
import DEALER_CONSTANTS from "constants/dealer";
import { jumpTo } from "__utils";





const isMobile = isMobileView();
const CreditStatus = styled.div<{ isMobile?: boolean }>`
		position: relative;
		img {
			padding-top: 60px;
		}
		.status-section {
			position: absolute;
			top: 0px;
			padding: 20px;
			margin-top: 60px;
			img {
				width : 35px;
				padding: 6px;
			}
			.status-message {
				display: flex;
				flex-direction: row;
				p {
					margin-bottom: 0;
					margin-top: 8px;
					font-weight: bold;
					font-size: 18px;
					line-height: 21px;
					color: #FFFFFF;
				}
			}
		}
		${({ isMobile }) =>
		isMobile &&
		css`
			img {
				padding-top: 60px;
				width: 100%;
			}
		`};
		${({ isMobile }) =>
		!isMobile &&
		css`
			.status-section {
				margin-left: 20px;
				.status-message {
					width : 100%;
				}
			}
		`};
	`

const CreditMoneyStatus : React.FC= () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [creditStatus, setCreditStatus] = useState<string>("");
	const [enablePollStatus, setEnablePollStatus] = useState<boolean>(true);
    const getCreditOrderCreateData = useSelector(creditMintpaySelector);
	const {creditOrderStatus} = propsToJS(getCreditOrderCreateData);

	let { orderId } = useParams<{ orderId: string }>();
	const [landingPageLoaded, setLandingPageLoaded] = useState(0);

	useEffect(() => {
		triggerEvents("MintPay_CreditStatus_PageLoad", {
			orderId : orderId
		});
		setLandingPageLoaded(1);
	}, [landingPageLoaded === 0]);

    useEffect(() => {
		dispatch({
			type: GET_CREDIT_ORDER_STATUS,
			payload: {
				orderId : orderId
				}
		})	

	},[]);

	const startCreditStatusPolling = () => {
		let statusPollStartTime = new Date().getTime();
		const getcreditStatusInterval = setInterval(() => {
			if (new Date().getTime() - statusPollStartTime > 900000) {
				clearInterval(getcreditStatusInterval);
				return;
			}
			dispatch({
				type: GET_CREDIT_ORDER_STATUS,
				payload: {
					orderId: orderId
				}
			})
		}, 30000);
	}
	
	useEffect(() => {
        if(creditStatus && creditStatus !== 'WALLET_CREDIT' && enablePollStatus){
			triggerEvents("MintPay_CreditStatus_Status_POLLER", {
				orderId : orderId,
				creditStatus : creditOrderStatus
			});
			setEnablePollStatus(false);
			startCreditStatusPolling();
        }
	},[creditStatus]);

    useEffect(() => {
        if(creditOrderStatus){
			triggerEvents("MintPay_CreditStatus_Status", {
				orderId : orderId,
				creditStatus : creditOrderStatus
			});
            setCreditStatus(creditOrderStatus.status);
        }
		

	},[creditOrderStatus]);

	const handleMintPayWallet = () => {
		let url  = getCookie(DEALER_CONSTANTS.BACK_URL);
		if(url) {
			triggerEvents("MintPay_CreditStatus_BackToProposal", {
				orderId : orderId,
			});
			jumpTo(url)
		} else {
			triggerEvents("MintPay_CreditStatus_BackToWallet", {
				orderId : orderId,
			});
			history.push("/wallet");
		}
	}

    return (
           <CreditStatus isMobile={isMobile}>
			   <img className="background-shadow" src={isMobile ? MintpayCreditStatusBck : MintpayDesktopBck} alt="Snow"/>
			   <div className="status-section">
				   {creditStatus && ['ORDER_CREATED', 'ORDER_REQUEST', 'ORDER_FAILED'].includes(creditStatus)? <div className="status-message">
						<img className="background-shadow" src={MintpayFailureIcon} alt="Snow"/>
						<p>Sorry. Your request couldn’t be fullfilled, please try again in sometime</p>
				   </div> : null }
				   {creditStatus === 'WALLET_CREDIT' ?
				   <div className="status-message">
						<img className="background-shadow" src={MintPayCreditSuccess} alt="Snow"/>
						<p>Mintpay Topup Successful</p>
				   </div> :  null }

				   {creditStatus && ['ORDER_PURCHASED', 'ORDER_ATTEMPTED', 'WALLET_CREDIT_FAILED'].includes(creditStatus) ?
				   <div className="status-message">
						<img className="background-shadow" src={MintPayCreditInProcess} alt="Snow"/>
						<p>Processing Payment</p>
				   </div> :  null }
				   

					<Button
                                title={getCookie(DEALER_CONSTANTS.BACK_URL) ? "Go to Proposal" : "Go to Mintpay Home page"}
                                btnStyle="primary"
                                btnType ="solid"
                                size="large"
                                onClick={handleMintPayWallet}
                                style={{
                                    minHeight: "28px",
                                    padding: "5px 15px",
                                    cursor: "pointer",
									marginTop: "20px",
    								marginLeft: "30px"
                                }}
                            />
			   </div>
			   
           </CreditStatus>
    )

}

export default CreditMoneyStatus;