import React from "react";	
import "./maintenance.css"	
import styled, { css } from "styled-components";	
import MintpayMaintenanceImg from "../assets/images/mintpay-maintenance.svg";	
import { isMobileView } from "../../../common/commonService";	
import MaintenanceNotification from "./maintenance-notification";	


const isMobile = isMobileView();	

const MintpayMaintenance: React.FC = () => {	
    const loader = document.getElementById("appLoader");	

    if (loader) {	
        loader.style.display = "none";	
    }	
    const MaintenancePage = styled.div<{ isMobile?: boolean }>`	
		margin-top: 150px;	
		position: relative;	
		background-color: white;	
		height: 100%;	
			
		${({ isMobile }) =>	
            isMobile &&	
            css`	
			height : 100%	
		`};	
	`	
    return (	
        <MaintenancePage >	
            {!isMobile ? (<div className="maintenance-desktop">	
                <div style={{	
                    display: "flex",	
                    padding: "11% 0% 0% 12%",	
                    width: "40%"	
                }}>	
                    <MaintenanceNotification></MaintenanceNotification>	
                </div>	
                <div style={{ width: "25%", margin: "5px", padding: "5px" }} >	
                    <img className="img-gif" src={MintpayMaintenanceImg} alt="Snow"></img>	
                </div>	
            </div>) : (<div className="maintenance-mobile">	
                <div className="mobile-maintenance-img">	
                    <img src={MintpayMaintenanceImg} alt="Snow"></img>	
                </div>	
                <div style={{ display: "flex", flexDirection: "column", paddingLeft: "8%" }}>	
                    <MaintenanceNotification></MaintenanceNotification>	
                </div>	
            </div>)}	
        </MaintenancePage>	
    );	
};	

export default React.memo(MintpayMaintenance);