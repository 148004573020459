import * as React from "react";
import styled,{ css }  from "styled-components";
import {
    Carousel
} from "antd";
import { Button} from "@turtlemint/mint-ui";

import { 
	isMobileView
} from "../../../../common/commonService";
import MintpayIntro from "../../assets/images/mintpay-intro.gif";


const isMobile = isMobileView();

type buttonDetail = {
    text : string,
    onClickHandler: (value: any) => void;
}
interface TmWalletIntroProps {
    isShowAction?: boolean;
    actionObj?: buttonDetail | undefined;
}

const WalletIntro : React.FC<TmWalletIntroProps> = (props:TmWalletIntroProps) => {

    const Content = styled.div<{ isMobile?: boolean }>`
        box-shadow: 0px 1px 4px 1px rgb(0 0 0 / 25%);
        border-radius: 8px;
        padding: 16px;
        margin : 16px;
        display: flex;
        flex-direction: row
        .description {
            flex-grow : 6;
            order: 2;
            font-size: 16px;
            font-weight: bold;
            margin-top: 47px;
            margin-right: 24px;
            margin-left: 24px;
        }
        .mintpay-img {
            order: 1;
            flex-grow : 4
            img {
                width: 360px;
                height: 350px;
            }
        }
        ${({ isMobile }) =>
        isMobile &&
        css`
             flex-direction: column
             .description {
                 order :1
                 font-size: 20px;
                 margin: unset;
                 padding:16px;

             }
             .mintpay-img {
                 order : 2
                 text-align: center;
             }
        `};

        ${({ isMobile }) =>
        !isMobile &&
        css`
           

        `};
    `

    return (
        <div>
		 	<h3 style={{
                     margin: "16px",
                     fontSize: "16px",
                     fontWeight: "bold"
             }}>How it works ?</h3>
             <Content isMobile={isMobile}>
                 <div className="description">
                    <p>Mintpay is like ready-to-use digital cash. Your money is completely secure and only be used as for mintpro transactions.</p>
                   {props.isShowAction ? <Button
							title={props.actionObj ? props.actionObj.text : ""}
							btnStyle="primary"
							btnType ="solid"
							size="small"
							onClick={props.actionObj ? props.actionObj.onClickHandler : null}
							style={{
								cursor: "pointer",
								padding: "13px",
								fontSize: "16px",
								background: "#00A465",
								borderRadius: "4px",
							}}
						/>	: null}
                 </div>
                 <div className = "mintpay-img">
                     <img className="img-gif" src={MintpayIntro} alt="Snow"></img>
                 </div>
             </Content>
            
        </div>
    )

}

export default React.memo(WalletIntro);