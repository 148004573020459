import React, { useEffect, useState } from "react";
import { Divider, Input, message, Popconfirm, Switch, Table, Tag } from "antd";
import {
	ArrowLeftOutlined,
	SearchOutlined,
	EyeFilled
} from "@ant-design/icons";
import styled, { css } from "styled-components";
import { isMobileView } from "common/commonService";
import { Button, COLORS } from "@turtlemint/mint-ui";
import { FETCH_SEARCH_DP_BY_ID, RESET_SEARCHED_LIST } from "constants/actions";
import { useDispatch, useSelector } from "react-redux";
import { propsToJS } from "__utils/immutable-to-js";
import searchPartnerSelector from "./search-partner.selector";
import { useHistory } from "react-router";
import * as Actions from "./action";
import "./index.css";
import { useDeepCompare } from "hooks/use-deep-memo";
import { getDateTime } from "__utils/date-format";
import { getUserInformation } from "components/admin-login-info";


const SearchPartner: React.FC = () => {
	const isMobile = isMobileView();
	const [dataSource, setdataSource] = useState<any[]>([]);
	const [searchedText, setSearchedText] = useState<string>("");
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		partnerIDList,
		qsbObjects,
		qsbInitiationSuccess,
		qsbInitiationRepeated,
		qsbUnauthorisedInitiation,
		qsbInitiationError,
		walletStatusChangeSuccess,
		walletDisableUnauthorised,
		walletDisableStatus,
		walletStatusChangeError
	} = propsToJS(useSelector(searchPartnerSelector));
	const userInfo = getUserInformation();

	const [showReport, setShowReport] = useState<boolean>(false);
	const [hasQSBInitiated, setHasQSBInitiated] = useState<boolean>(false);
	const [hasWalletStatusChanged, setHasWalletStatusChanged] = useState<boolean>(false);

	useEffect(() => {
		if(history.location.state === "backToAllReports") {
			setShowReport(true);
		}
	}, [history]);

	useEffect(() => {
		if (partnerIDList && partnerIDList.length > 0) {
			setdataSource(partnerIDList);
		}
	}, [partnerIDList]);

	useEffect(() => {
		dispatch({
			type: Actions.GET_QSB_REPORTS
		});
	}, []);

	useEffect(() => {
		dispatch({
			type: Actions.GET_WALLET_STATUS
		});
	}, []);
	useEffect(() => {
		if(hasQSBInitiated) {
			if(qsbInitiationSuccess) {
				message.success("QSB Process Initiated Successfully");
			} else if (qsbInitiationRepeated) {
				message.error("QSB Process is already initiated");
			} else if (qsbUnauthorisedInitiation) {
				message.warn("Unauthorised to initiate QSB Process");
			} else {
				message.error("Failed to initiate QSB Process");
			}
			setHasQSBInitiated(false);
		}	
	}, [qsbInitiationSuccess, qsbUnauthorisedInitiation, qsbInitiationRepeated, qsbInitiationError]);

	useEffect(() => {
		if(hasWalletStatusChanged) {
			if(walletDisableStatus && walletStatusChangeSuccess) {
				message.success("Mintpay Wallets has been disabled.");
			} else if (!walletDisableStatus && walletStatusChangeSuccess) {
				message.success("Mintpay Wallets has been enabled.");
			} else if (walletDisableUnauthorised && walletStatusChangeSuccess) {
				message.error("Unauthorised to Change the Wallet Status");
			} else {
				message.error("Failed to change the wallet status");
			}
			setHasWalletStatusChanged(false);
		}
	}, [walletDisableStatus, walletDisableUnauthorised, walletStatusChangeSuccess, walletStatusChangeError]);

	const onSearchClick = () => {
		setShowReport(false);
		if (searchedText) {
			dispatch({
				type: FETCH_SEARCH_DP_BY_ID,
				payload: {
					searchDpID: searchedText
				}
			});
		} else {
			dispatch({
				type: RESET_SEARCHED_LIST
			});
			setdataSource([]);
			setSearchedText("");
		}
	};

	const changeWalletStatus = () => {
		dispatch({
			type: Actions.SET_WALLET_STATUS,
			payload: {
				featureName: "mintpay",
				isActive: walletDisableStatus ? "true" : "false"
			}
		});
		setHasWalletStatusChanged(true);
	};

	const handleQSBInitiate = () => {
		setHasQSBInitiated(true);
		dispatch({
			type: Actions.POST_QSB_INITIATE,
			payload: {
				authToken: userInfo?.token
			}
		});
	};

	const generateReports = () => {
		setShowReport(!showReport);
	};

	const Transactions = styled.div<{ isMobile?: boolean }>`
		background-color: white;
		height: calc(${document.body.clientHeight + "px"} - 0px);
		${({ isMobile }) =>
			isMobile
				? css`
						margin-top: 60px;
				  `
				: css`
						margin-top: 30px;
						padding: 24px;
						padding-bottom: 0px;
						padding-top: 24px;
				  `};
	`;

	const Header = styled.div<{ isMobile?: boolean }>`
		margin: 16px;
		${({ isMobile }) =>
			isMobile &&
			css`
				position: relative;
				height: 56px;
				top: 0px;
				width: 100%;
				padding: 16px;
				background: #ffffff;
				box-shadow: 0px 1px 4px rgb(0 0 0 / 24%);
				margin: 0px;
			`};
	`;

	const TransactionsContent = styled.div<{ isMobile?: boolean }>`
		.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
			border-color: green;
			border-right-width: 1px !important;
		}
		.ant-input:focus {
			border-color: #00a465;
			box-shadow: 0 0 0 0.5px #00a465;
		}
		.ant-input {
			font-size: 15px;
			font-weight: bold;
			background: #f5f5f5;
		}
		${({ isMobile }) =>
			!isMobile &&
			css`
				// border: 1px solid #E5E5E5;
				// border-radius: 8px;
				padding: 15px;
				width: 1210px;
				margin: auto;
			`};
	`;

	const dpColumns = [
		{
			title: "ID",
			dataIndex: "partnerDetail.id",
			key: "id"
		},
		{
			title: "Mobile No.",
			dataIndex: "partnerDetail.mobileNo",
			key: "mobileNo"
		},
		{
			title: "Email ID",
			dataIndex: "partnerDetail.emailId",
			key: "emailId"
		},
		{
			title: "Name",
			dataIndex: "partnerDetail.name",
			key: "name"
		},
		{
			title: "Status",
			dataIndex: "partnerDetail.isActive",
			key: "isActive",
			render: (isActive: boolean) => (
				<Tag color={isActive ? "green" : "magenta"}>
					{isActive ? "Active" : "Inactive"}
				</Tag>
			)
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			render: (isActive: boolean, partnerDetail: any) => (
				<EyeFilled
					style={{ fontSize: "20px" }}
					onClick={() => {
						console.log("partner", partnerDetail);
						history.push({
							pathname:
								"partner/" + partnerDetail.partnerDetail?.id,
							state: partnerDetail
						})
					}}
				/>
			)
		}
	];

	const reportColumns = [
		{
			title: "Sr. No",
			key: "index",
			render: (text: any, record: any, index: number) => <span>{index + 1}</span>
		},
		{
			title: "Initiated By",
			dataIndex: "initiatedBy",
			key: "initiatedBy"
		},
		{
			title: "Initiated On",
			dataIndex: "activityStarted",
			key: "activityStarted",
			render: (activityStarted: number) => <span>{getDateTime(activityStarted)}</span>,
			sorter: (a: any, b: any) => a.activityStarted - b.activityStarted,
			defaultSortOrder: 'descend' as any
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			render: (_: any, qsbReport: any) => (
				<EyeFilled
					style={{ fontSize: "20px" }}
					onClick={() => {
						console.log("fsd", qsbReport);
						history.push({
							pathname: "qsb-report-detail/" + qsbReport?.id,
							state: qsbReport
						});
					}}
				/>
			)
		}
	];

	return (
		<div>
			{isMobile ? (
				<Transactions isMobile={isMobile}>
					<TransactionsContent isMobile={isMobile}>
						<Header isMobile={isMobile}>
							<div>
								<ArrowLeftOutlined
									style={{
										fontSize: "20px",
										color: `black`,
										cursor: "pointer"
									}}
								></ArrowLeftOutlined>
							</div>
						</Header>
						<div className="transaction-header-wrap">
							<div> Transactions</div>
							<div>here</div>
						</div>
						{false && (
							<div className="flex-row-align-center">
								<Input
									style={{
										width: "400px",
										height: "40px"
									}}
									prefix={<SearchOutlined />}
									allowClear
									type="Input.TextArea"
									placeholder="Search by DP ID"
									onChange={(e: any) => setSearchedText(e.target.value)}
									value={searchedText}
								/>
								<Button
									title="Search"
									btnStyle="primary"
									btnType="solid"
									onClick={() => {
										onSearchClick();
									}}
									style={{
										cursor: "pointer",
										marginRight: "10px"
									}}
								/>
							</div>
						)}
						here
					</TransactionsContent>
				</Transactions>
			) : (
				<Transactions isMobile={isMobile}>
					<TransactionsContent isMobile={isMobile}>
						<Header
							style={{ margin: "16px 0px" }}
							isMobile={isMobile}
						>
							<div className="transaction-desk-header-wrap">
								<div className="flex-row-align-center">
									{/* <img src={downloadButton} alt="loading" style={{ marginRight: 20 }} /> */}
									<div>
										<Input
											style={{
												marginRight: "5px",
												width: "400px",
												height: "40px"
											}}
											prefix={<SearchOutlined />}
											allowClear
											autoFocus
											type="text"
											placeholder="Search by DP ID"
											onPressEnter={onSearchClick}
											onChange={(e: any) => setSearchedText(e.target.value)}
											value={searchedText}
										/>
										<Button
											title="Search"
											btnStyle="primary"
											btnType="solid"
											onClick={() => {
												onSearchClick();
											}}
											style={{
												cursor: "pointer",
												marginRight: "10px"
											}}
										/>
									</div>
								</div>
								<div>
									<label>Wallet Status: </label>
									<b>
										{walletDisableStatus
											? "Disabled"
											: "Enabled"}
									</b>
									<Popconfirm
										placement="bottomLeft"
										title="Do you want to change the wallet status ?"
										onConfirm={() => changeWalletStatus()}
										okText="Yes"
										cancelText="No"
									>
										<Switch
											style={{
												marginLeft: "10px",
												marginRight: "10px"
											}}
											defaultChecked={
												!walletDisableStatus
											}
											checked={!walletDisableStatus}
											checkedChildren={
												walletDisableStatus
													? "Enable"
													: "Disable"
											}
											unCheckedChildren={
												walletDisableStatus
													? "Disable"
													: "Enable"
											}
											onClick={() =>
												console.log("confirmation")
											}
										/>
									</Popconfirm>
									<Popconfirm
										placement="bottomLeft"
										title="Do you want to initiate the QSB Process ?"
										onConfirm={() => handleQSBInitiate()}
										okText="Yes"
										cancelText="No"
									>
										<Button
											title="Initiate QSB"
											disabled={qsbInitiationSuccess}
											btnStyle="primary"
											btnType="solid"
											onClick={() => {
												console.log("Initiate QSB");
											}}
											style={{
												cursor: "pointer",
												marginRight: "10px"
											}}
										/>
									</Popconfirm>
									<Button
										title={
											showReport
												? "Hide QSB Report"
												: "Show QSB Report"
										}
										btnStyle="primary"
										btnType="solid"
										onClick={() => {
											generateReports();
										}}
									>
										Show QSB Report
									</Button>
								</div>
							</div>
						</Header>
						<Divider
							style={{
								marginBottom: "0.1rem ",
								marginTop: "2rem",
								border: "1px solid #D2D5D6"
							}}
						/>
						<div>
							{!showReport && (
								<Table
									dataSource={dataSource.filter(obj => Object.keys(obj).length != 0)}
									columns={dpColumns}
								/>
							)}
						</div>
						{showReport && (
							<div>
								<div>
									<Table
										columns={reportColumns}
										dataSource={qsbObjects}
										bordered={true}
									/>
								</div>
							</div>
						)}
					</TransactionsContent>
				</Transactions>
			)}
		</div>
	);
};

export default SearchPartner;
